import { useDevicesByHousehold } from '@hooks/useDevicesByHousehold';
import useTour from '@hooks/useTour';
import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useEffect, useLayoutEffect, useState } from 'react';

import { AnalyticsService } from '../../../services/AnalyticsService';
import { TimeService } from '../../../services/TimeService';
import useBoundStore from '../../../store/store';
import HistoricalDataDashboardSlide from '../../Tour/components/HistoricalDataDashboardSlide/HistoricalDataDashboardSlide';
import PetDataDashboardSlide from '../../Tour/components/PetDataDashboardSlide/PetDataDashboardSlide';
import TapForAdvancedDataDashboardSlide from '../../Tour/components/TapForAdvancedDataDashboardSlide/TapForAdvancedDataDashboardSlide';
import { PetStackParamList } from '../Pets';
import { Dashboard } from '../molecules/PetDashboardSummary/Dashboard';

export const PetDashboardSummaryWrapper = ({
  route,
}: StackScreenProps<PetStackParamList, 'PetDashboardSummary'>) => {
  const [dateRange, setDateRange] = useState<number[]>([0, TimeService.toLocal().toMillis()]);
  const [selectedDate, setSelectedDate] = useState<number>(TimeService.toLocal().toMillis());
  const petDashboardCount = useBoundStore((s) => s.tourStore.petDashboardCount);
  const pet = useBoundStore((state) => state.petStore.activePet);
  const {
    loadPetStatistics,
    getPetStatistics,
    setPetStatistics,
    petStatisticsLoading,
    setIgnorePetStatsRefresh,
  } = useBoundStore((state) => state.petStore);
  const isFocused = useIsFocused();
  const devices = useDevicesByHousehold();

  const petDashboardsTours = [
    {
      uniqueId: `v0-pet-dashboard`,
      countId: 1,
      components: [
        <PetDataDashboardSlide />,
        <HistoricalDataDashboardSlide />,
        <TapForAdvancedDataDashboardSlide />,
      ],
      devices: ['all'],
    },
  ];
  const initTour = useTour(petDashboardsTours[petDashboardCount - 1]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!petStatisticsLoading) setIsLoading(false);
  }, [petStatisticsLoading]);

  useEffect(() => {
    // if selected date does not equal today date we should not refresh stats under the hood
    setIgnorePetStatsRefresh(
      TimeService.parseDate(selectedDate).toLocal().endOf('day').toMillis() !==
        TimeService.toLocal().endOf('day').toMillis(),
    );

    return () => {
      // undo this when we are no longer rendering the page
      setIgnorePetStatsRefresh(false);
    };
  }, [selectedDate]);

  useLayoutEffect(() => {
    initTour();
  }, [isFocused]);

  useEffect(() => {
    if (pet && selectedDate) {
      AnalyticsService.logEvent('PetDashboardSummaryWrapper - useEffect - loadPetStatistics', {
        selectedDate,
      });
      setIsLoading(true);
      loadPetStatistics([pet], devices, new Date(selectedDate), false, 7).then(()=>console.log('loadingFinished'));
    }
  }, [selectedDate, pet?.id, devices]);

  useEffect(() => {
    const currPetStatistics = getPetStatistics(pet?.id);
    AnalyticsService.logEvent('PetDashboardSummaryWrapper - useEffect - getPetStatistics');
    return () => {
      AnalyticsService.logEvent('PetDashboardSummaryWrapper - useEffect - setPetStatistics');

      setPetStatistics(currPetStatistics);
    };
  }, [pet?.id]);

  if (!pet) {
    return null;
  }
  return (
    <Dashboard
      petStatistic={getPetStatistics(pet?.id)}
      petStatisticsLoading={isLoading}
      pet={pet}
      dateRange={dateRange}
      selectedDate={selectedDate}
      onChangeDate={(date) => {
        if (!date || date === selectedDate) return;
        setIsLoading(true);
        setSelectedDate(date);
      }}
    />
  );
};
