import { SpText } from '@atoms/SpText';
import SpTextButton from '@atoms/SpTextButton';
import { SpVStack } from '@atoms/SpVStack';
import { SpView } from '@atoms/SpView';
import AppEnv from '@constants/AppEnv';
import { AppImages } from '@constants/AppImages';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import { Env } from 'Env';
import * as Updates from 'expo-updates';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Linking, SafeAreaView, StyleSheet, View } from 'react-native';

const AboutNavigation = () => {
  const handleNavigation = (uri: string) => {
    return () => {
      Linking.openURL(uri);
    };
  };

  const { t } = useTranslation();

  return (
    <SafeAreaView style={styles.container}>
      <SpView style={styles.container}>
        <View style={styles.body}>
          <Image
            {...testProperties('logo about', 'photo')}
            source={AppImages.logoGrey}
            style={{ width: 128, height: 128 }}
            resizeMode="stretch"
          />
          <SpView marginBottom={38}>
            <SpText color={colors.greyText.color} fontFamily="Rubik_SemiBold">
              {t('version')}: {AppEnv.appVersion}{' '}
            </SpText>
          </SpView>

          <SpView marginBottom={30}>
            <SpText align="center" color={colors.greyText.color}>
              {t('about_page_description')}
            </SpText>
          </SpView>

          <SpVStack space={8}>
            <SpTextButton
              textStyle={styles.link}
              text={t('terms_of_use')}
              onPress={handleNavigation(t('end_user_licence_agreement_link'))}
            />
            <SpTextButton
              textStyle={styles.link}
              text={t('privacy_policy')}
              onPress={handleNavigation(t('privacy_policy_link'))}
            />
          </SpVStack>
          {/* {showCyberSec ? ( */}
          {/*   <> */}
          {/*     <SpView marginBottom={30} marginTop={30}> */}
          {/*       <SpText align="center" color={colors.greyText.color}> */}
          {/*         {t('cyber_security_description')} */}
          {/*       </SpText> */}
          {/*     </SpView> */}

          {/*     <SpVStack space={8}> */}
          {/*       <SpTextButton */}
          {/*         textStyle={styles.link} */}
          {/*         text={t('cyber_security')} */}
          {/*         onPress={handleNavigation(t('cyber_security_link'))} */}
          {/*       /> */}
          {/*     </SpVStack> */}
          {/*   </> */}
          {/* ) : null} */}
        </View>
        <SpView style={styles.footer}>
          <SpVStack space={12}>
            {Env.APP_API !== 'https://app-api.production.surehub.io' ? (
              <>
                <SpView style={styles.envContainer}>
                  <SpText style={styles.text} fontFamily="Rubik_SemiBold">
                    Environment:
                  </SpText>
                  <SpView marginTop={4}>
                    <SpText style={styles.text}>{Env.APP_NAME}</SpText>
                  </SpView>
                </SpView>
                <SpView>
                  <SpText style={styles.text} fontFamily="Rubik_SemiBold">
                    API URL:
                  </SpText>
                  <SpView marginTop={4}>
                    <SpText style={styles.text}>{Env.APP_API}</SpText>
                  </SpView>
                </SpView>
              </>
            ) : null}
            {Updates.updateId ? (
              <SpView>
                <SpText style={styles.text} fontFamily="Rubik_SemiBold">
                  Build ID:
                </SpText>
                <SpView marginTop={4}>
                  <SpText style={styles.text}>{Updates.updateId}</SpText>
                </SpView>
              </SpView>
            ) : null}
          </SpVStack>
        </SpView>
      </SpView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingHorizontal: 22,
    paddingVertical: 8,
  },
  envContainer: {
    paddingBottom: 15,
  },
  body: {
    flexGrow: 1,
    alignItems: 'center',
  },
  footer: {
    paddingTop: 20,
    alignItems: 'center',
  },
  text: {
    color: colors.greyText.color,
    fontSize: 14,
    lineHeight: 14,
    textAlign: 'center',
  },
  link: {
    color: colors.primary.color,
    textDecorationLine: 'underline',
    fontSize: 14,
    lineHeight: 14,
    textAlign: 'center',
  },
});

export default AboutNavigation;
