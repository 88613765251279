import ReportApi from '@api/ReportApi';
import { lens } from '@dhmk/zustand-lens';
import { MappedReportModel, ReportModel } from '@models/ReportModel';
import { mapReportData } from '@utils/report';
import { startOfDay, subDays } from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import { StateCreator } from 'zustand';

import { ReportService } from '../../services/ReportService';
import { TimeService } from '../../services/TimeService';
import { MergedInterfaces } from '../models';

export interface ReportSliceModel {
  reportStore: {
    loading: boolean;
    data: MappedReportModel;
    detailsReports: Record<string, { expires: number; data: MappedReportModel }>;
    loadDetailsReport: (
      householdId: number,
      petId: number,
      fromNumberDays?: number,
      fromDate?: Date,
    ) => Promise<MappedReportModel>;
    loadSummaryReports: (householdId: number, petId: number, from: number, to: number) => void;
    resetReports: () => void;
  };
}

const createReportSlice: StateCreator<
  MergedInterfaces,
  [['zustand/persist', unknown]],
  [],
  ReportSliceModel
> = (rootSet, rootGet) => {
  return {
    reportStore: lens((set, get, api) => ({
      data: null,
      detailsReports: {},
      loading: false,
      loadDetailsReport: async (
        householdId: number,
        petId: number,
        fromNumberDays = 7,
        fromDate = TimeService.toLocal().toJSDate(),
      ) => {
        const cacheKey = `${householdId}-${petId}`;
        const { detailsReports } = get();

        set({ loading: true });

        return await Promise.all(
          ReportService.getReportsTimezoneRequests(fromNumberDays, fromDate).map((range) =>
            ReportApi.getReports(householdId, petId, range[0], range[1]),
          ),
        )
          .then((data: ReportModel[]) => {
            const merged = data.reduce(
              (acc: ReportModel, item: ReportModel) => {
                acc.movement.datapoints = [...acc.movement.datapoints, ...item.movement.datapoints];
                acc.feeding.datapoints = [...acc.feeding.datapoints, ...item.feeding.datapoints];
                acc.drinking.datapoints = [...acc.drinking.datapoints, ...item.drinking.datapoints];
                return acc;
              },
              {
                movement: { datapoints: [] },
                feeding: { datapoints: [] },
                drinking: { datapoints: [] },
              },
            );
            const groupedData = mapReportData(
              merged,
              rootGet().deviceStore.getDevicesCollection(),
              rootGet().householdStore.activeHousehold.timezone.timezone,
            );
            set({
              loading: false,
              detailsReports: {
                ...detailsReports,
                [cacheKey]: {
                  data: groupedData,
                  expires: Date.now() + 60 * 1000,
                },
              },
            });
            return groupedData;
          })
          .catch((e) => {
            set({ loading: false });

            return null;
          });
      },

      loadSummaryReports: async (householdId: number, petId: number, from: number, to: number) => {
        set({ loading: true });
        try {
          const fromDate = new Date(from);
          const toDate = new Date(to);

          const data = await ReportApi.getReports(
            householdId,
            petId,
            formatInTimeZone(toDate, 'UTC', 'yyyy-MM-dd'),
            formatInTimeZone(toDate, 'UTC', 'yyyy-MM-dd'),
          );
          const groupedData = mapReportData(
            data,
            rootGet().deviceStore.getDevicesCollection(),
            rootGet().householdStore.activeHousehold.timezone.timezone,
          );
          set({ loading: false, data: groupedData });
        } catch (e) {
          set({ loading: false, data: null });
        }
      },
      resetReports: () => {
        set({
          loading: false,
          data: null,
          detailsReports: {},
        });
      },
    })),
  };
};

export default createReportSlice;
