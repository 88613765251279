import {
  GroupReportPointModel,
  MappedConsumptionReportDataModel,
  MappedMovementReportDataModel,
  MappedPoseidonReportDataModel,
} from '@models/ReportModel';

import { TimeService } from '../services/TimeService';

export const fillEmptyPoints = (
  data: GroupReportPointModel<
    MappedConsumptionReportDataModel | MappedMovementReportDataModel | MappedPoseidonReportDataModel
  >[],
  viewedDays: number,
  activeDate: number,
) => {
  const result = [];
  let initialDate = activeDate;

  for (let i = 0; i < viewedDays; i++) {
    const point = data.find(
      (item) =>
        TimeService.toLocal(item?.date).toISODate() ===
        TimeService.parseDate(initialDate).toISODate(),
    );

    if (point) {
      result.push(point);
    } else {
      const obj = {
        date: initialDate,
        points: [] as any,
      };
      result.push(obj);
    }
    initialDate = TimeService.toLocal(initialDate).minus({ days: 1 }).toMillis();
  }
  return result;
};
