import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import IndoorOnlySVG from '@atoms/SvgIcons/IndoorOnlySVG';
import { TagProfile } from '@constants/Tag';
import { useDeviceById } from '@hooks/useDeviceById';
import useDeviceStatusHook from '@hooks/useDeviceStatusHook';
import useDeviceStatusParse from '@hooks/useDeviceStatusParse';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { usePetsWithTag } from '@hooks/usePetsWithTag';
import { PetModel } from '@models/Pet';
import SpModal from '@molecules/SpModal';
import colors from '@styles/colors';
import text from '@styles/text';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ListRenderItem, StyleSheet } from 'react-native';
import { FlatList, TouchableOpacity } from 'react-native-gesture-handler';
import IndoorModePetItem from 'src/pages/Flows/Molecules/IndoorModePetItem';
import { closeModal, openModal } from 'src/services/ModalBox';
import useBoundStore from 'src/store/store';

import IndoorModeInfo from './IndoorModeInfo';

type IndoorWrapperProps = {
  toggleProfile: (pet: PetModel, profile: TagProfile) => void;
  deviceID: number;
};

const IndoorWrapper = ({ toggleProfile, deviceID }: IndoorWrapperProps) => {
  const { t } = useTranslation();
  const deviceStatus = useDeviceStatusHook(deviceID, true);
  useDeviceStatusParse(deviceStatus);
  const { loadingControl, updateDevice } = useBoundStore((s) => s.deviceStore);
  const device = useDeviceById(deviceID);
  const pets = usePetsByHousehold();
  const petsWithTag = usePetsWithTag(device, pets);
  const assignedPets = useMemo(() => {
    return petsWithTag.filter((item) => item.isAssigned).map((item) => item.pet);
  }, [petsWithTag, loadingControl, deviceStatus, device, pets]);

  useEffect(() => {
    console.log(
      'IndoorWrapper assignedPets',
      assignedPets.map((pet) => pet.profile),
    );
  }, [assignedPets]);
  const renderItem: ListRenderItem<PetModel> = ({ item }) => {
    return (
      <IndoorModePetItem
        imageUrl={item.photo?.location}
        name={item.name}
        id={item.tag_id}
        indoorModeEnabled={item.profile === TagProfile.Safe}
        isIndoorMode
        withAssignedIcon
        handlePress={(profile: TagProfile) => toggleProfile(item, profile)}
        device={device}
      />
    );
  };

  return (
    <SpView>
      <SpView style={styles.container}>
        <SpView style={styles.leftContainer}>
          <IndoorOnlySVG color={colors.greyText.color} width={34} height={34} viewBox="0 0 30 30" />
          <SpText style={styles.title}>{t('indoor_only')}</SpText>
        </SpView>

        <TouchableOpacity
          style={styles.primaryButton}
          onPress={() => openModal('IndoorModeInfoModal')}>
          <SpText style={[text.defaultText, styles.color]}>{t('info')}</SpText>
        </TouchableOpacity>
      </SpView>
      <FlatList
        data={assignedPets}
        renderItem={renderItem}
        style={styles.petList}
        keyExtractor={(item, index) => `${item.name}${index}`}
      />

      <SpModal backdropColor="white" bottomAreaView={false} modalName="IndoorModeInfoModal">
        <IndoorModeInfo continueButtonAction={() => closeModal('IndoorModeInfoModal')} />
      </SpModal>
    </SpView>
  );
};

export default IndoorWrapper;

const styles = StyleSheet.create({
  petList: {
    paddingBottom: 50,
  },
  primaryButton: {
    borderRadius: 10,
    backgroundColor: colors.greyText.color,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
  },
  primaryButtonText: {
    fontFamily: 'Rubik_Medium',
    color: colors.white.color,
    fontSize: 16,
  },
  container: {
    paddingBottom: 32,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '70%',
  },
  title: {
    fontFamily: 'Rubik_Medium',
    fontSize: 20,
    color: colors.greyText.color,
    marginLeft: 10,
  },
  disabled: {
    opacity: 0.5,
  },
  color: {
    color: colors.white.color,
    fontWeight: '900',
  },
});
