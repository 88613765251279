import { RemoteConfigModel } from '@models/RemoteConfigModel';

export enum RemoteConfigKeys {
  testKeyBoolean = 'test_key_boolean',
  testKeyJson = 'test_key_json',
  testKeyNumber = 'test_key_number',
  testKeyString = 'test_key_string',
  newTermsDate = 'newTermsDate',
  reviewActive = 'reviewActive',
  cyberSecurityVisible = 'cyberSecurityVisible',
}

export const RemoteConfigDefault: RemoteConfigModel = {
  [RemoteConfigKeys.newTermsDate]: 'newTermsDate',
  [RemoteConfigKeys.testKeyBoolean]: 'true',
  [RemoteConfigKeys.testKeyJson]: '{ "1": ["Cash"], "2": ["Cash"], "3": ["Cash"] }',
  [RemoteConfigKeys.testKeyNumber]: '1',
  [RemoteConfigKeys.testKeyString]: 'test_key_string',
  [RemoteConfigKeys.reviewActive]: 'false',
  [RemoteConfigKeys.cyberSecurityVisible]: 'false',
};
