import { SpView } from '@atoms/SpView';
import i18n from '@utils/i18n';
import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import GradientButton from '@atoms/GradientButton';
import { HubQuickActions } from '@constants/QuickActions';
import LightSVG from '@atoms/SvgIcons/LightSVG';
import colors from '@styles/colors';

interface FooterActionsProps {
  onAction: (data: HubQuickActions) => void;
  updatingHubLedMode: boolean;
}

export const HubFooterActions = ({
  onAction,
  updatingHubLedMode,
}: FooterActionsProps) => {
  const button = useMemo(() => {
    return {
      icon: (
        <LightSVG
          color={colors.greyText.color}
          width={45}
          height={45}
          viewBox="0 0 28 28"
        />
      ),
      text: i18n.t('lights'),
      action: () => onAction(HubQuickActions.lights),
      loadingKey: 'led_mode.value',
    };
  }, [onAction]);

  return (
    <SpView style={styles.row}>
      <SpView style={styles.column}>
        <GradientButton
          isLoading={updatingHubLedMode}
          disabled={updatingHubLedMode}
          colorsGradient={['rgba(0, 189, 136, 0.4)', 'rgba(45, 181, 142, 0.4)']}
          borderRadius={18}
          text={button.text}
          icon={button.icon}
          action={button.action}
        />
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    marginHorizontal: -10,
  },
  column: {
    paddingHorizontal: 10,
    alignItems: 'center',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '50%',
  },
});
