import { SpText } from '@atoms/SpText';
import {
  ConsumptionReportDataModel,
  MovementReportDataModel,
  ReportStatsActivityModel,
} from '@models/ReportModel';
import colors from '@styles/colors';
import React, { ReactNode, useCallback, useState } from 'react';
import { Pressable, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Carousel } from 'react-native-snap-carousel';
import { TimeService } from 'src/services/TimeService';

import { ITEM_WIDTH, SLIDER_WIDTH } from '../../pages/Dashboard/molecules/PetCarousel/PetCard';

const minPointWidth = 1;
const minPointOffset = 0.8;
const fullDaySeconds = 86400;
const fullDayRangeSeconds = 86399;

export interface ConvertedPoints<T = string> {
  duration?: number;
  durationCount?: number;
  isEmptySpace?: boolean;
  length?: number;
  index?: number;
  width?: T;
  ml?: T;
  leftLedge?: boolean;
  rightLedge?: boolean;
}

export interface LineChartProps {
  withLabels?: boolean;
  withWarning?: boolean;
  viewButton?: boolean;
  isActive?: boolean;
  points: ReportStatsActivityModel[] | MovementReportDataModel[] | ConsumptionReportDataModel[];
  rightTitle?: string;
  leftTitle?: string;
  pointDetailsRenderItem?: (data: { index: number; dataIndex: number; item: any }) => ReactNode;
  setActive?: () => void;
  lowState?: boolean;
}

export const DurationLineChart = ({
  withLabels = false,
  viewButton = false,
  isActive = false,
  setActive,
  points,
  pointDetailsRenderItem,
  rightTitle,
  leftTitle,
  lowState,
}: LineChartProps) => {
  const isCarousel = React.useRef(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const isRightLedgeCalc = (data) => {
    if (data.active) return true;
    return false;
  };

  const convertPoints = useCallback(() => {
    const converted: ConvertedPoints[] = [];
    points.forEach((item, index) => {
      const convertedFrom = TimeService.toLocal(item.from);
      const convertedTo = TimeService.toLocal(item.to);
      const convertedNextFrom = TimeService.toLocal(points[index + 1]?.from);

      if (index === 0) {
          const startOfDay = TimeService.startOfDay(convertedFrom);
          const offset = convertedFrom.diff(startOfDay, 'seconds').toObject().seconds;
        if (offset > 0) {
          converted.push({ duration: offset, isEmptySpace: true });
          converted.push({
            duration: item.duration,
            index,
            rightLedge: offset + item.duration === fullDayRangeSeconds || isRightLedgeCalc(item),
          });
        } else {
          converted.push({
            duration: item.duration,
            index,
            leftLedge: offset === 0 || offset === fullDayRangeSeconds,
            rightLedge: isRightLedgeCalc(item),
          });
        }
      } else {
        converted.push({
          duration: item.duration,
          index,
          rightLedge: isRightLedgeCalc(item),
        });
      }
      if (index !== points.length - 1) {
        const offset = convertedNextFrom.diff(convertedTo, 'seconds').toObject().seconds;
        converted.push({
          duration: offset > 0 ? offset : 0,
          isEmptySpace: true,
        });
      } else {
        const startOfDay = TimeService.startOfDay(convertedFrom);
        const offset = convertedFrom.diff(startOfDay, 'seconds').toObject().seconds;
        if (offset) {
          converted.push({
            duration: fullDaySeconds - offset,
            isEmptySpace: true,
          });
        }
      }
    }, []);

    return converted;
  }, [points]);

  const handleMarker = useCallback((index: number) => {
    isCarousel?.current?.snapToItem(index);
  }, []);

  const dotsArr = () => {
    const toPercent = (sec) => {
      return (sec * 100) / fullDaySeconds;
    };
    const sum = convertPoints().reduce((acc, item) => {
      if (item.isEmptySpace && toPercent(item.duration) < minPointOffset) {
        item.duration = 691.2;
      }
      if (!item.isEmptySpace && toPercent(item.duration) < minPointWidth) {
        item.duration = 3283.2;
      }
      acc += item.duration;
      return acc;
    }, 0);

    const outputArr = convertPoints().map((item) => {
      const active = isActive && item.index === activeIndex;
      const color = () => {
        const itemColor = isActive ? colors.pastelGreen.color : colors.toxicGreen.color;
        return item.isEmptySpace ? 'transparent' : itemColor;
      };

      return {
        isEmptySpace: item.isEmptySpace,
        index: item.index,
        width: Number(((item.duration * 100) / fullDaySeconds).toFixed(4)),
        color: color(),
        active,
        leftLedge: item.leftLedge,
        rightLedge: item.rightLedge,
      };
    });

    return outputArr;
  };

  const dotComponent = () => {
    const calcWidth = (item, isEmpty) => {
      if (isEmpty) {
        return item.width > minPointOffset ? `${item.width}%` : `${item.width}%`;
      }
      return item.width > minPointWidth ? `${item.width}%` : `${minPointWidth}%`;
    };
    return dotsArr().map((item, index) => {
      return (
        <TouchableOpacity
          key={`${item.index}${Math.random()}`}
          disabled={!isActive || item.isEmptySpace}
          onPress={() => {
            setActiveIndex(item.index);
            handleMarker(item.index);
          }}
          style={{
            height: lowState && !isActive ? 16 : 24,
            width: calcWidth(item, item.isEmptySpace),
            backgroundColor: item.active ? colors.toxicGreen.color : item.color,
            borderRadius: 4,
            borderBottomRightRadius: item.rightLedge ? 0 : null,
            borderTopRightRadius: item.rightLedge ? 0 : null,
            borderBottomLeftRadius: item.leftLedge ? 0 : null,
            borderTopLeftRadius: item.leftLedge ? 0 : null,
          }}
        />
      );
    });
  };

  return (
    <View
      style={{
        marginTop: 12,
        paddingHorizontal: 24,
      }}>
      <View style={[styles.labelWrapper, (leftTitle || isActive) && { marginTop: 12 }]}>
        <View>{(leftTitle || isActive) && <SpText size="sm">{leftTitle}</SpText>}</View>
        <View>
          {rightTitle && (!lowState || isActive) && <SpText size="sm">{rightTitle}</SpText>}
        </View>
      </View>
      <Pressable
        onPress={() => {
          if (setActive) setActive();
        }}
        style={[
          styles.line,
          {
            backgroundColor: isActive ? colors.greyText.color : colors.pastelGreen.color,
          },
        ]}>
        <View style={[styles.inner, { height: lowState && !isActive ? 24 : 36 }]}>
          {dotComponent()}
          <Pressable style={{ width: '100%' }} />
        </View>
      </Pressable>
      {isActive && (
        <View style={[styles.mt, styles.center, { overflow: 'visible' }]}>
          <Carousel
            layout="default"
            vertical={false}
            onSnapToItem={setActiveIndex}
            ref={isCarousel}
            firstItem={0}
            data={points as any}
            renderItem={pointDetailsRenderItem}
            sliderWidth={SLIDER_WIDTH}
            itemWidth={ITEM_WIDTH}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  renderItemWrapper: {
    flexDirection: 'row',
    width: '100%',
    paddingHorizontal: 24,
    justifyContent: 'space-between',
    paddingVertical: 15,
    borderRadius: 10,
    backgroundColor: colors.placeholderGrey.color,
  },
  fs: {
    fontSize: 18,
  },
  flexStart: {
    alignItems: 'flex-start',
  },
  center: {
    alignItems: 'center',
  },
  flexEnd: {
    alignItems: 'flex-end',
  },
  thumbnailTitle: {
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold',
  },
  thumbnailValue: {
    color: 'white',
    fontSize: 19,
  },
  mt: {
    marginTop: 16,
  },
  line: {
    paddingHorizontal: 16,
    borderRadius: 18,
    zIndex: -1,
  },
  inner: {
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
  },
  warningWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderWidth: 2,
    borderRadius: 10,
    borderColor: colors.placeholderGrey.color,
    marginHorizontal: -10,
    marginBottom: 15,
  },
  labelWrapper: {
    marginBottom: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  viewButton: {
    flexDirection: 'row',
    paddingHorizontal: 40,
    paddingVertical: 10,
    borderWidth: 1,
    borderRadius: 10,
    alignItems: 'center',
  },
  warningText: { marginLeft: 20, color: colors.placeholderGrey.color },
  thumbnailWrapper: { borderWidth: 2, borderRadius: 15 },
});
