import LoadingSpinner from '@atoms/Loader';
import { SpText } from '@atoms/SpText';
import SpToggleButton from '@atoms/SpToggleButton';
import { SpView } from '@atoms/SpView';
import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { DeviceModel } from '@models/Device';
import { PetModel } from '@models/Pet';
import { MappedReportModel } from '@models/ReportModel';
import colors from '@styles/colors';
import { differenceInDays } from 'date-fns';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Platform, StyleSheet } from 'react-native';

import AvgSatatisticsContainer from './AvgSatatisticsContainer';
import DrinkingAndFeedingStatistics from './DrinkingAndFeedingStatistics';
import { DrinkingGraph } from './DrinkingGraph';
import { FeedingGraph } from './FeedingGraph';
import { MovementGraph } from './MovementGraph';
import StatsViewedDaysModal from './StatsViewedDaysModal';
import { openModal } from '../../../../services/ModalBox';
import { TimeService } from '../../../../services/TimeService';
import useBoundStore from '../../../../store/store';
import {
  ReportRangeLastName,
  ReportRangeType,
  ReportRangeTypeName,
} from '../../constants/ReportRangeType';
import { ReportType } from '../../constants/ReportType';
import { useGroupedRangeReportStats } from '../../hooks/useGroupedRangeReportStats';
import DateNavigation from '../DateNavigation';

interface SummaryTabProps {
  stats: MappedReportModel;
  pet: PetModel;
  type: ReportType;
  devices: DeviceModel[];
  intervals: [string, string];
  dataType: string;
  rangeType: ReportRangeType;
  setRangeType: (value: ReportRangeType) => void;
  currentDate: number;
  setCurrentDate: (value: number) => void;
}

export const SummaryTab = ({
  stats,
  pet,
  type,
  devices,
  intervals,
  dataType,
  rangeType,
  setRangeType,
  currentDate,
  setCurrentDate,
}: SummaryTabProps) => {
  const screenHeight = Dimensions.get('window').height;
  const { t } = useTranslation();
  const ref = useRef(null);
  const { loading } = useBoundStore((s) => s.reportStore);
  const [rangeButtonActive, setRangeButtonActive] = useState(false);
  const [compareMode, setCompareMode] = useState(false);
  const [topOffset, setTopOffset] = useState(0);
  const [average, setAverage] = useState(null);
  const rangeDates = useGroupedRangeReportStats(currentDate, rangeType);
  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { convertWithUnits } = useWeightConversion(userWeightUnits, userCountryCode);
  const dateNavigationData = {
    name: t(ReportRangeLastName[rangeType], {
      value: rangeType === 180 ? 6 : rangeType,
    }),
    viewedDays: differenceInDays(rangeDates.finish, rangeDates.start),
  };

  const chartsRender = useMemo(() => {
    if (compareMode) {
      return <SpText>TODO</SpText>;
    }

    if (loading) {
      return (
        <SpView style={styles.spinner}>
          <LoadingSpinner />
        </SpView>
      );
    }

    if (type === ReportType.Drinking) {
      return (
        <DrinkingGraph
          rangeType={rangeType}
          stats={stats}
          pet={pet}
          currentDate={currentDate}
          devices={devices}
          onSetAverage={(val) => setAverage(val)}
        />
      );
    }

    if (type === ReportType.Feeding) {
      return (
        <FeedingGraph
          rangeType={rangeType}
          stats={stats}
          pet={pet}
          currentDate={currentDate}
          devices={devices}
          onSetAverage={(val) => setAverage(val)}
        />
      );
    }

    if (type === ReportType.Movement) {
      return (
        <MovementGraph
          rangeType={rangeType}
          stats={stats}
          currentDate={currentDate}
          onSetAverage={(val) => setAverage(val)}
        />
      );
    }

    return null;
  }, [rangeType, stats, currentDate, devices, compareMode, type, loading]);

  const averageValue = useMemo((): string => {
    if (!average) {
      return '';
    }
    if (type !== ReportType.Movement) {
      return `${average}`;
    }

    const days = Math.floor((average * 1000) / (1000 * 60 * 60 * 24));
    const hours = Math.floor((average % (3600 * 24)) / 3600);
    const minutes = Math.floor((average % 3600) / 60);

    return `${days ? days + t('day') : ''} ${
      hours ? hours + t('hours_unit_1') : ''
    } ${minutes ? minutes + t('minutes_unit_2') : ''} ${
      !days && !hours && !minutes ? average + t('seconds_unit_1') : ''
    }`.trim();
  }, [type, average]);

  return (
    <SpView style={styles.container}>
      <StatsViewedDaysModal
        topOffset={topOffset}
        type={rangeType}
        onChange={(value) => {
          setRangeType(value);
          setRangeButtonActive(false);
        }}
        onClose={() => setRangeButtonActive(false)}
      />
      <SpView paddingHorizontal={19} marginTop={screenHeight < 810 ? 6 : 16}>
        <DateNavigation
          title={dateNavigationData.name}
          currentDate={TimeService.toLocal(currentDate).toJSDate()}
          setDate={setCurrentDate}
          viewedDays={dateNavigationData.viewedDays}
          range={rangeType}
        />
      </SpView>
      <SpView style={styles.alertWrap}>
        {type === ReportType.Movement && !loading && (
          <AvgSatatisticsContainer type="single" data={averageValue} />
        )}
        {type === ReportType.Drinking && !loading && (
          <DrinkingAndFeedingStatistics
            type="single"
            data={convertWithUnits(Number(averageValue || 0))}
            dataType={dataType}
          />
        )}
        {type === ReportType.Feeding && !loading && (
          <DrinkingAndFeedingStatistics
            type="single"
            data={convertWithUnits(Number(averageValue || 0), true)}
            dataType={dataType}
          />
        )}
      </SpView>

      <SpView style={styles.chartWrap}>{chartsRender}</SpView>

      <SpView style={styles.footerButtonWrap}>
        <SpView width={135} paddingBottom={Platform.OS === 'android' ? 27 : 0}>
          <SpToggleButton
            ref={ref}
            onPress={() => {
              // @ts-ignore
              ref.current.measure((x, y, width, height, pageX, pageY) => {
                setTopOffset(Platform.OS === 'android' ? y + pageY + 36 : y + pageY - 12);
                openModal('StatsViewedDaysModal');
                setRangeButtonActive(true);
              });
            }}
            text={t(ReportRangeTypeName[rangeType], {
              value: rangeType === 180 ? 6 : rangeType === 365 ? 1 : rangeType,
            })}
            width="100%"
            withIcon
            isActive={rangeButtonActive}
          />
        </SpView>
        {/* // Disabled until available */}
        {/* <SpToggleButton */}
        {/*   onPress={() => setCompareMode(!compareMode)} */}
        {/*   text={t('compare')} */}
        {/*   width="45%" */}
        {/*   isActive={compareMode} */}
        {/* /> */}
      </SpView>
      {/* // Disabled until available */}

      {/* <AmountHorizontalGraphs */}
      {/*   intervals={intervals} */}
      {/*   amounts={amounts} */}
      {/*   dataType={dataType} */}
      {/* /> */}
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  content: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  chartWrap: {
    flex: 1,
  },
  footerButtonWrap: {
    flexDirection: 'row',
    marginHorizontal: 33,
    marginTop: 24,
    justifyContent: 'center',
  },
  alertWrap: {
    zIndex: 1000,
    marginBottom: -64,
    marginTop: 4,
  },
  spinner: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%',
  },
});
