import CustomBottomSheetModal from '@atoms/CustomBottomSheetModal';
import LoadingSpinner from '@atoms/Loader';
import { SpView } from '@atoms/SpView';
import { DeviceType, PetDoorLockingMode } from '@constants/Device';
import { LockDoorActions } from '@constants/LockUnlockDoorActions';
import { CatFlapQuickActions } from '@constants/QuickActions';
import { TagProfile } from '@constants/Tag';
import { BottomSheetBackdrop } from '@gorhom/bottom-sheet';
import { useDeviceById } from '@hooks/useDeviceById';
import useToastEffect from '@hooks/useToastEffect';
import { DeviceCatFlapCurfew, DeviceModel, DevicePetDoorCurfew } from '@models/Device';
import { PetModel } from '@models/Pet';
import { SpLockUnlockActions } from '@molecules/SpLockUnlockActions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet } from 'react-native';
import useBoundStore from 'src/store/store';

import { CatFlapFooterActions } from './CatFlapFooterActions';
import { CurfewCards } from './CurfewCards';
import IndoorWrapper from './IndoorWrapper';
import LockInfo from './LockInfo';
import { PetDoorFooterActions } from './PetDoorFooterActions';
import UnlockAction from './UnlockAction';
import { usePetDoorCatFlapCard } from '../../hooks/usePetDoorCatFlapCard';
import { DeviceBottomSheetContainer } from '../DeviceBottomSheetContainer';
import { DeviceSettingMenuItem } from '../DeviceSettingMenuItem';
import { DeviceStatusHeader } from '../DeviceStatusHeader';

interface MainCardProps {
  data: DeviceModel;
  error: string;
  userHasWriteAccess: boolean;
  onChangeLockUnlock: (mode: PetDoorLockingMode, status: boolean) => void;
  onChangeCurfew: (data: DevicePetDoorCurfew | DeviceCatFlapCurfew[]) => void;
}

export const PetDoorCatFlapCard = ({
  data,
  error,
  userHasWriteAccess,
  onChangeLockUnlock,
  onChangeCurfew,
}: MainCardProps) => {
  const {
    changeCurfewAction,
    control,
    dismissAction,
    lockUnlockAction,
    modal,
    snapPoints,
    setModal,
  } = usePetDoorCatFlapCard(data, onChangeLockUnlock, onChangeCurfew);
  const {
    updateTagProfile,
    davidsUpdateTagProfile,
    updateTagProfileResult,
    updateProperty,
    updateLockUnlockLoading,
  } = useBoundStore((state) => state.deviceStore);
  const online = data?.status.online;
  const productId = data.product_id;
  const deviceID = data?.id;
  const device = useDeviceById(deviceID);
  const typedControl = data.control as unknown as { locking: PetDoorLockingMode } | undefined;

  const { t } = useTranslation();
  useToastEffect({
    resetter: () => updateProperty({ updateTagProfileResult: 'none' }),
    successCondition: updateTagProfileResult === 'success',
    errorCondition: updateTagProfileResult === 'error',
    successToastMessage: 'success_update_indoor_mode',
    errorToastMessage: 'error_update_indoor_mode',
    translate: true,
  });
  return (
    <>
      <SpView flex={1}>
        <DeviceStatusHeader data={data} />
        <SpView>
          {userHasWriteAccess && online && (
            <SpView marginTop={2}>
              <DeviceSettingMenuItem data={data?.id} />
            </SpView>
          )}
        </SpView>
      </SpView>

      {/* Show the carousel of quick actions to appropriate users. */}
      {userHasWriteAccess && online && (
        <SpView style={styles.footer}>
          <SpView style={styles.shadow} />
          <LockInfo data={control} />
          <SpView style={styles.divider} />
          {productId === DeviceType.CatFlapConnect && <CatFlapFooterActions onAction={setModal} />}
          {productId === DeviceType.PetDoorConnect && <PetDoorFooterActions onAction={setModal} />}
          <SpView height={Platform.select({ android: 20, ios: 0 })} />
        </SpView>
      )}

      <CustomBottomSheetModal
        opened={!!modal}
        backdropComponent={BottomSheetBackdrop}
        index={0}
        snapPoints={snapPoints}
        onDismiss={dismissAction}
        inScrollView>
        <DeviceBottomSheetContainer paddingBottom={60}>
          {modal === CatFlapQuickActions.lock && (
            <SpView>
              {updateLockUnlockLoading && (
                <SpView paddingBottom={19} alignItems="center">
                  <LoadingSpinner />
                </SpView>
              )}

              {typedControl.locking ? (
                <UnlockAction
                  isDoor={productId === DeviceType.PetDoorConnect}
                  deviceId={data.id}
                  lockMode={typedControl.locking}
                  disabled={updateLockUnlockLoading}
                  dismissAction={dismissAction}
                />
              ) : (
                <SpLockUnlockActions
                  buttons={LockDoorActions}
                  disabled={updateLockUnlockLoading}
                  onSelect={(event: PetDoorLockingMode) => lockUnlockAction(event, true)}
                />
              )}
            </SpView>
          )}
          {modal === CatFlapQuickActions.curfew && (
            <CurfewCards data={data} error={error} onChangeCurfew={changeCurfewAction} />
          )}
          {modal === CatFlapQuickActions.indoor_mode && (
            <IndoorWrapper
              toggleProfile={async (item: PetModel, profile: TagProfile) =>
                await davidsUpdateTagProfile(device.id, item, profile)
              }
              deviceID={deviceID}
            />
          )}
        </DeviceBottomSheetContainer>
      </CustomBottomSheetModal>
    </>
  );
};

const styles = StyleSheet.create({
  footer: {
    marginTop: 'auto',
    paddingTop: 44,
    backgroundColor: 'white',
    marginBottom: 10,
  },
  shadow: {
    elevation: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: -6 },
    shadowOpacity: 0.1,
    shadowRadius: 40,
    position: 'absolute',
    top: -15,
    height: 30,
    width: '100%',
  },
  divider: {
    height: 1,
    backgroundColor: 'rgba(38, 58, 67, 0.15)',
    marginTop: 48,
    marginBottom: 24,
  },
});
