import DeviceApi from '@api/DeviceApi';
import { DeviceModel, DevicePendingStatusModel } from '@models/Device';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import useBoundStore from '../store/store';
import { DeviceStatusIDs } from '@constants/Device';

const useDeviceStatusHook = (deviceId: DeviceModel['id'], active = false) => {
  const [data, setData] = useState<DevicePendingStatusModel[]>([]);
  const { setAsyncRequestState } = useBoundStore((state) => state.deviceStore);
  const pendingTagsAmount = useRef(0);
  const {updateDevice, getDeviceById} = useBoundStore(store => store.deviceStore);

  const fetchDeviceStatus = async () => {
    if (!deviceId) return;
    try {
      const response = await DeviceApi.getDeviceStatus(deviceId);
      if (!response) {
        setData([]);
      } else {
        setData(response);
        setAsyncRequestState(
          deviceId,
          response.sort(
            (a, b) => new Date(a.requested_at).getTime() - new Date(b.requested_at).getTime(),
          ),
        );

      }
    } catch (e) {
      console.log('Device status ERROR =>', e);
    }
  };
  useEffect(() => {
    if (!active || !deviceId) return () => console.log('No active');
    fetchDeviceStatus();
    const interval = setInterval(() => {
      fetchDeviceStatus();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const refreshDeviceTags = useCallback(() => {
      DeviceApi.getDeviceTags(deviceId).then((tags) => updateDevice({ ...getDeviceById(deviceId), tags }));
  },[DeviceApi, deviceId])
  //refresh tags data for device if amount of active pending tag_profiles items decreased
  useLayoutEffect(() => {
    if (!data.length && pendingTagsAmount.current === 0) return;
    if (!data.length && pendingTagsAmount.current) {
      pendingTagsAmount.current = 0;
      refreshDeviceTags()
    };
    const pendingTags = data
        ?.flatMap?.((item) => {
          if ((item?.status && item?.status === DeviceStatusIDs.Pending)&&(item?.state && item.state.tag_profiles?.length)) {
            return (item?.state?.tag_profiles ?? []);
          }
          return [];
        })
    if(pendingTags?.length < pendingTagsAmount.current) {
      refreshDeviceTags()
    }
    pendingTagsAmount.current = pendingTags.length;
  },[data])

  return data;
};

export default useDeviceStatusHook;
