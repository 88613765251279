/* eslint-disable react/jsx-no-useless-fragment */
import LoadingSpinner from '@atoms/Loader';
import { SpVStack } from '@atoms/SpVStack';
import { SpView } from '@atoms/SpView';
import { DeviceFeederBowlType, DeviceFeederTareType, DeviceStatusIDs } from '@constants/Device';
import { getZeroScalesActions } from '@constants/ZeroScalesActions';
import { DeviceFeederTypeUpdated } from '@models/Device';
import { SpZeroScalesActions, ZeroScalesActionModel } from '@molecules/SpZeroScalesActions';
import { SpZeroScalesUpdateStatusMessage } from '@molecules/SpZeroScalesUpdateStatusMessage';
import colors from '@styles/colors';
import { getSingleDuration } from '@utils/date';
import React, { useEffect, useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { MergedInterfaces } from '../../../../store/models';
import useBoundStore from '../../../../store/store';
import useLastDate from '../../hooks/useLastDate';

interface ZeroScalesProps {
  deviceId: number;
  type: DeviceFeederBowlType;
  events: DeviceFeederTypeUpdated;
}

export const ZeroScales = ({ deviceId, type, events }: ZeroScalesProps) => {
  const {
    asyncRequestState,
    setUpdateZeroScalesLoading,
    setUpdateZeroScalesError,
    setUpdateZeroScalesSuccess,
    updateZeroScalesLoading,
    updateZeroScalesError,
    updateZeroScalesSuccess,
    updateZeroScalesAsync,
  } = useBoundStore(({ deviceStore }: MergedInterfaces) => {
    return {
      asyncRequestState: deviceStore.asyncRequestState,
      updateZeroScalesError: deviceStore.updateZeroScalesError,
      updateZeroScalesLoading: deviceStore.updateZeroScalesLoading,
      updateZeroScalesSuccess: deviceStore.updateZeroScalesSuccess,
      setUpdateZeroScalesLoading: deviceStore.setUpdateZeroScalesLoading,
      setUpdateZeroScalesError: deviceStore.setUpdateZeroScalesError,
      setUpdateZeroScalesSuccess: deviceStore.setUpdateZeroScalesSuccess,
      updateZeroScalesAsync: deviceStore.updateZeroScalesAsync,
    };
  });

  useEffect(() => {
    if (
      !asyncRequestState[deviceId] ||
      !Array.isArray(asyncRequestState[deviceId]) ||
      asyncRequestState[deviceId].length === 0
    ) {
      console.log('No asyncRequestState for device', deviceId);
      return;
    }

    const filteredStates = asyncRequestState[deviceId].filter((item) => {
      if (item.state && Object.keys(item?.state).includes('tare')) {
        return item;
      }
    });
    const lastStatus = filteredStates[filteredStates.length - 1];

    if (!lastStatus) return;
    console.log(lastStatus, lastStatus.requested_at);
    if (lastStatus.status === DeviceStatusIDs.Pending) {
      setUpdateZeroScalesLoading(true);
    } else {
      setUpdateZeroScalesLoading(false);
    }
    if (lastStatus.status === DeviceStatusIDs.Success) {
      setUpdateZeroScalesSuccess(true);
    } else {
      setUpdateZeroScalesSuccess(false);
    }
    // if status is an error and requested_at is within the last hour
    const fiveMinsInMS = 300000;
    if (
      (lastStatus.status === DeviceStatusIDs.DeviceError ||
        lastStatus.status === DeviceStatusIDs.ServerError ||
        lastStatus.status === DeviceStatusIDs.NoChange) &&
      new Date(lastStatus.requested_at).getTime() > new Date().getTime() - fiveMinsInMS
    ) {
      console.log('last request was an error', lastStatus.requested_at, lastStatus.status);
      setUpdateZeroScalesError(lastStatus.status);
    } else {
      setUpdateZeroScalesError(null);
    }
  }, [asyncRequestState[deviceId]]);

  const lastZeroed = useLastDate(events, 'last_zeroed_at');

  const actions: ZeroScalesActionModel[] = useMemo(() => {
    return getZeroScalesActions(type);
  }, [type]);

  const onSelect = (bowlType: DeviceFeederTareType) => {
    if (!updateZeroScalesLoading) {
      updateZeroScalesAsync(deviceId, bowlType);
    }
    setUpdateZeroScalesLoading(true);
    setUpdateZeroScalesError(null);
    setUpdateZeroScalesSuccess(null);
  };

  return (
    <SpVStack space={8} style={styles.wrapper}>
      {updateZeroScalesLoading && (
        <SpView alignItems="center">
          <LoadingSpinner />
        </SpView>
      )}

      <SpZeroScalesUpdateStatusMessage
        loading={updateZeroScalesLoading}
        success={!!updateZeroScalesSuccess}
        error={updateZeroScalesError?.error}
        lastZeroed={
          typeof lastZeroed === 'string'
            ? lastZeroed
            : getSingleDuration(new Date(lastZeroed).toISOString())
        }
      />

      <SpZeroScalesActions buttons={actions} onSelect={onSelect} />
    </SpVStack>
  );
};

const styles = StyleSheet.create({
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    backgroundColor: colors.secondary4.color,
    borderRadius: 16,
  },
  bowlImage: {
    height: 30,
    width: 30,
    resizeMode: 'contain',
  },
  images: {
    flexDirection: 'row',
    marginLeft: 14,
  },
  description: {
    marginTop: 16,
    marginBottom: 30,
  },
  wrapper: {
    position: 'relative',
  },
  rowSuccessContainer: {
    flexDirection: 'row',
    width: '100%',
    gap: 20,
  },
  successMsg: {
    fontSize: 20,
    color: colors.greyText.color,
    fontFamily: 'Rubik_Medium',
  },
  successDesc: {
    marginTop: 4,
  },
});
