/* eslint-disable jsx-a11y/anchor-is-valid */
import RadioCheckboxWithText from '@atoms/RadioCheckboxWithText';
import { SpView } from '@atoms/SpView';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import SpSelect from '@molecules/SpSelect';
import { SpTermsCheckboxes, SpTermsCheckboxState } from '@molecules/SpTermsCheckboxes';
import { useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import textStyles from '@styles/text';
import { testProperties } from '@utils/testProperties';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import useBoundStore from '../../../store/store';
import { ReactSetState } from '../../UpdateTerms/molecules/WhatIsChanging';

interface ITermsAndConditionsProps {
  language: number;
  confirmSendSelect: any;
  isScrolledToBottom: boolean;
  setLanguage: any;
  setConfirmSendSelect: any;
  setIsScrolledToBottom: any;
  isCloseToBottom: any;
  buttonDisabled?: boolean;
  terms: SpTermsCheckboxState;
  setTerms: ReactSetState<SpTermsCheckboxState>;
  onPress?: () => void;
}

const TermsAndConditionsForm = ({
  language,
  confirmSendSelect,
  isScrolledToBottom,
  setLanguage,
  setConfirmSendSelect,
  setIsScrolledToBottom,
  isCloseToBottom,
  buttonDisabled = false,
  terms,
  setTerms,
  onPress,
}: ITermsAndConditionsProps) => {
  const { t, i18n } = useTranslation();
  const termsTextRef = useRef(null);
  const navigation = useNavigation();
  const languages = useBoundStore((s) => s.languageStore.languages);

  const sortedLanguages = useMemo(
    () => languages.sort((a, b) => a?.name?.localeCompare(b?.name)),
    [languages],
  );
  const agreeOptions = useMemo(() => {
    return [
      { id: 0, name: t('yes_please') },
      { id: 1, name: t('no_thanks') },
    ].map((item) => {
      return (
        <RadioCheckboxWithText
          key={item.name + String(item.id)}
          active={confirmSendSelect === item.id}
          handler={() => setConfirmSendSelect(item.id)}
          text={item.name}
          containerStyle={styles.smallRadio}
          componentNameIcon="Check"
        />
      );
    });
  }, [confirmSendSelect, t]);
  const currentLanguage = useMemo(() => {
    return sortedLanguages.find((item) => item.id === language);
  }, [language]);
  const termsDisabled = useMemo(() => {
    return {
      endUserLicenceAgreement: Platform.OS !== 'web' && !isScrolledToBottom,
      privacyPolicy: false,
    };
  }, [isScrolledToBottom]);

  return (
    <View {...testProperties(`TermsAndCondition`, 'Wrapper', '', true)}>
      <SpSelect
        label={t('select_language')}
        selectedValue={language}
        onSelect={setLanguage}
        data={sortedLanguages}
        forceSet={(data) => {
          const { code } = sortedLanguages.find((lang) => +lang.id === +data);
          i18n.changeLanguage(code);
        }}
      />
      <Text style={[textStyles.title, styles.termsText]}>{t('end_user_agreement')}</Text>
      <View style={styles.scrollContainer}>
        <ScrollView
          {...testProperties('large terms', 'Scroll')}
          nestedScrollEnabled
          ref={termsTextRef}
          style={styles.termsScrollView}
          onScroll={({ nativeEvent }) => {
            if (isCloseToBottom(nativeEvent)) {
              if (!isScrolledToBottom) setIsScrolledToBottom(true);
            }
          }}
          scrollEventThrottle={0}>

          <Pressable>
            <Text
              {...testProperties(`large terms`, 'Text')}
              style={[textStyles.defaultText, styles.licenceText]}>
              {t('large_terms')}
            </Text>
          </Pressable>
        </ScrollView>
        {!isScrolledToBottom && (
          <TouchableOpacity
            onPress={() => {
              termsTextRef?.current.scrollToEnd({ animated: true });
              setIsScrolledToBottom(true);
            }}
            style={styles.scrollToBottomButton}>
            <FontAwesomeIcon color="#ffffff" size={24} icon={faChevronDown} />
          </TouchableOpacity>
        )}
      </View>
      <View style={styles.scrollBottomWrapper}>
        <Text
          {...testProperties(`scroll  condition`, 'Text')}
          style={[textStyles.loadingText, styles.scrollBottomText]}>
          {t('scroll_condition')}
        </Text>
      </View>
      <Text
        {...testProperties(`change types of content`, 'Text')}
        style={[textStyles.defaultText, styles.termsTitle]}>
        {t('change_types_of_content')}
      </Text>
      <SpView style={styles.mainTerms}>
        <SpTermsCheckboxes
          languageCode={currentLanguage?.code}
          active={terms}
          disabled={termsDisabled}
          onChange={setTerms}
        />
      </SpView>

      <Text
        {...testProperties(`marketing_preferences_message`, 'Text')}
        style={[textStyles.defaultText, styles.termsTitle]}>
        {t('marketing_preferences_message')}
      </Text>
      <View style={styles.confirmSendWrapper}>{agreeOptions}</View>
      <View style={{ height: 150 }} />
    </View>
  );
};

const styles = StyleSheet.create({
  termsText: {
    marginVertical: 24,
  },
  termsScrollView: {
    height: 252,
  },
  scrollContainer: {
    borderWidth: 1,
    borderColor: colors.placeholderGrey.color,
    borderRadius: 15,
    paddingVertical: 15,
    paddingRight: 5,
  },
  termsTitle: { marginTop: 10, lineHeight: 24 },
  scrollBottomWrapper: {
    marginTop: 25,
    borderRadius: 16,
    alignItems: 'center',
    backgroundColor: colors.pastelGreen.color,
  },
  scrollBottomText: {
    color: colors.lightBlack.color,
    textAlign: 'left',
    fontSize: 14,
    paddingVertical: 8,
    marginTop: undefined,
    lineHeight: 20,
  },
  flexGrow: { flexGrow: 1 },
  confirmSendWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 26,
  },
  licenceText: {
    paddingHorizontal: 16,
    paddingLeft: 20,
    paddingRight: 20,
  },
  smallRadio: {
    flex: 1,
    width: undefined,
    alignItems: 'center',
  },
  mainTerms: {
    paddingVertical: 26,
    gap: 19,
  },
  container: {
    marginTop: 46,
    marginBottom: 26,
  },
  declineText: {
    marginBottom: 16,
    textAlign: 'center',
    color: colors.greyText.color,
  },
  lineHeight: {
    lineHeight: 23,
    maxWidth: '95%',
  },
  scrollToBottomButton: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 5,
    right: 5,
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: colors.greySmallTitle.color,
    opacity: 0.3,
  },
});

export default TermsAndConditionsForm;
