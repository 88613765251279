import { SpText } from '@atoms/SpText';
import { useClickOutside } from '@hooks/useClickOutside';
import useTour from '@hooks/useTour';
import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { DeviceModel } from '@models/Device';
import { PetModel } from '@models/Pet';
import { MappedReportModel } from '@models/ReportModel';
import { useIsFocused } from '@react-navigation/native';
import colors from '@styles/colors';
import * as d3 from 'd3';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Dimensions, StyleSheet, TouchableOpacity } from 'react-native';
import { G, Rect, Svg, Text as SvgText } from 'react-native-svg';

import { GraphTooltip } from './GraphTooltip';
import AmountDrunkGraphsSlide from '../../../Tour/components/AmountDrunkGraphsSlide/AmountDrunkGraphsSlide';
import { ReportRangeType } from '../../constants/ReportRangeType';
import { ReportType } from '../../constants/ReportType';
import { useGroupedConsumptionReportStats } from '../../hooks/useGroupedConsumptionReportStats';
import { useSummaryReportGraphData } from '../../hooks/useSummaryReportGraphData';
import useTickFormatting from '../../hooks/useTickFormatting';

const { width } = Dimensions.get('window');

interface DrinkingGraphProps {
  rangeType: ReportRangeType;
  currentDate: number;
  stats: MappedReportModel;
  pet: PetModel;
  devices: DeviceModel[];
  onSetAverage: (val: number) => void;
}

export const DrinkingGraph = ({
  rangeType,
  currentDate,
  stats,
  pet,
  devices,
  onSetAverage,
}: DrinkingGraphProps) => {
  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { convertWithUnits } = useWeightConversion(userWeightUnits, userCountryCode);
  const [activeBar, setActiveBar] = useState<number>(null);
  const clickOutsideRef = useClickOutside(() => setActiveBar(null));
  const { calculateBarOpacity } = useTickFormatting(rangeType);

  const { consumptionAverage, consumptionData } = useGroupedConsumptionReportStats(
    stats?.drinking.map((item) => ({
      ...item,
      points: item.points.filter((point) => point.weights[0].multi !== true),
    })) || [],
    pet,
    devices,
    currentDate,
    rangeType,
    ReportType.Drinking,
  );

  const isFocused = useIsFocused();

  useEffect(() => {
    onSetAverage(consumptionAverage);
  }, [consumptionAverage]);

  const initTour = useTour({
    uniqueId: 'v0-graph-drinking',
    components: [<AmountDrunkGraphsSlide />],
    devices: ['all'],
  });
  useLayoutEffect(() => {
    initTour();
  }, [isFocused]);

  const yValues = useMemo(() => consumptionData.map((item) => item.totalWeight), [consumptionData]);
  const xValues = useMemo(() => consumptionData.map((item) => item.date), [consumptionData]);
  const {
    xAxisLabels,
    yAxisLabels,
    barWidth,
    barGap,
    chartHeight,
    margin,
    height,
    xAxisLabelStyle,
    yAxisLabelStyle,
    barRadius,
    chartWidth,
  } = useSummaryReportGraphData(width, rangeType, xValues, yValues, 0.45);

  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max(yValues)])
    .range([chartHeight, 0]);

  const tooltipInfo = useMemo(() => {
    if (activeBar !== null) {
      const eventByIndex = consumptionData[activeBar];

      return {
        y: yScale(eventByIndex.totalWeight) + 70,
        text: convertWithUnits(eventByIndex.totalWeight || 0),
      };
    }

    return null;
  }, [activeBar, rangeType]);

  return (
    <TouchableOpacity
      style={styles.graphContainer}
      activeOpacity={1}
      ref={clickOutsideRef}
      onPress={() => setActiveBar(null)}>
      {tooltipInfo ? (
        <GraphTooltip
          top={tooltipInfo.y}
          calcLeftProps={{
            index: activeBar,
            barWidth,
            barGap,
            marginLeft: margin.left,
            marginRight: margin.right,
            chartWidth,
            rangeType,
          }}>
          <SpText align="center" size="sm" color={colors.white.color}>
            {tooltipInfo.text}
          </SpText>
        </GraphTooltip>
      ) : null}
      <Svg width={width} height={height}>
        <G transform={`translate(${margin.left},${margin.top})`}>
          {/* Draw bars */}
          {consumptionData.map((item, index) => (
            <Rect
              key={item.date + index}
              rx={barRadius}
              x={index * (barWidth + barGap)}
              y={yScale(item.totalWeight)}
              width={barWidth}
              height={item.totalWeight ? chartHeight - yScale(item.totalWeight) + barRadius : 0}
              fill={activeBar === index ? colors.lightBlack.color : colors.green.color}
              opacity={calculateBarOpacity(index)}
              onPress={() => setActiveBar(index)}
            />
          ))}
          <Rect
            x={-margin.left}
            y={chartHeight}
            width={width}
            height={margin.bottom}
            fill="white"
          />

          {/* Draw x-axis labels */}
          {(xAxisLabels || []).map((item, index) => {
            return (
              <SvgText
                x={index * (barWidth + barGap) + barWidth / 2}
                y={chartHeight + xAxisLabelStyle.offsetTop}
                fontSize={xAxisLabelStyle.fontSize}
                textAnchor="middle"
                fill={xAxisLabelStyle.color}>
                {item}
              </SvgText>
            );
          })}

          {/* Draw y-axis labels */}
          {yAxisLabels.map((tick, index) => (
            <React.Fragment key={tick + index}>
              <Rect
                x={yAxisLabelStyle.offsetLeft - 5}
                y={yScale(tick) - yAxisLabelStyle.fontSize / 2}
                width={60}
                height={yAxisLabelStyle.fontSize + 6}
                fill="white"
                opacity={0.5}
                rx={6}
                ry={6}
              />
              <SvgText
                x={yAxisLabelStyle.offsetLeft}
                y={yScale(tick)}
                fontSize={yAxisLabelStyle.fontSize}
                fill={yAxisLabelStyle.color}
                textAnchor="start"
                alignmentBaseline="center">
                {convertWithUnits(tick, false)}
              </SvgText>
            </React.Fragment>
          ))}
        </G>
      </Svg>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  graphContainer: {
    position: 'relative',
    backgroundColor: 'rgba(45, 181, 142, 0.1)',
  },
});
