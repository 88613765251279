import LoadingSpinner from '@atoms/Loader';
import { DeviceModel } from '@models/Device';
import React, { useCallback, useEffect } from 'react';

import { MergedInterfaces } from '../../../store/models';
import useBoundStore from '../../../store/store';
import { CerberusCard } from '../molecules/CerberusDevice/CerberusCard';

interface CerberusWrapperProps {
  data: DeviceModel;
  deviceId: number;
}

export const CerberusWrapper = ({ data, deviceId }: CerberusWrapperProps) => {
  const { loadingDevice, updateProperty } = useBoundStore((state: MergedInterfaces) => {
    const { deviceStore } = state;
    return {
      loadingDevice: deviceStore.loadingDevice,
      updateProperty: deviceStore.updateProperty,
    };
  });

  const clearStoreProperties = useCallback(() => {
    updateProperty({
      loadingDevice: false,
    });
  }, [updateProperty]);

  useEffect(() => {
    clearStoreProperties();
  }, []);

  useEffect(() => {
    return () => clearStoreProperties();
  }, []);

  if (loadingDevice) {
    return <LoadingSpinner />;
  }

  return <CerberusCard data={data} />;
};
