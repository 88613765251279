import {
  CloseDelays,
  DeviceControlResponseStatus,
  DeviceDualScanLearnMode,
  DeviceDualScanLockingMode,
  DeviceFeederBowlType,
  DeviceFeederTareType,
  DeviceHubLedMode,
  DeviceHubPairingMode,
  DeviceType,
  FeederFoodType,
  PetBowlFoodType,
  PetDoorLockingMode,
  SubstanceType,
} from '@constants/Device';
import { PetPosition } from '@constants/Pet';
import { TagProfile } from '@constants/Tag';

import { DeviceTagModel } from './DeviceTag';
import { HouseholdModel } from './Household';
import { ProductModel } from './Product';

export interface DeviceVersionModel {
  hardware: number;
  firmware: number;
}

export interface DeviceVersionCollectionModel {
  device: DeviceVersionModel;
  lcd?: DeviceVersionModel;
  rf?: DeviceVersionModel;
}

export interface DeviceStatusModel {
  version: DeviceVersionCollectionModel;
  online: boolean;
  battery: number;
  signal: DeviceSignalModel;
}

export interface DeviceSignalModel {
  device_rssi: number;
  hub_rssi: number;
}

export class DeviceDoorCurfewModel {
  enabled = false;

  lock_time = '';

  unlock_time = '';

  locked = false;

  closePopup?: boolean;
}

export class DevicePetDoorCurfew extends DeviceDoorCurfewModel {
  permission: number;

  delay_time: number;
}

export interface DeviceLockingModel<T, V extends DeviceDoorCurfewModel> {
  mode: T;
  curfew: V;
}

export class DeviceCatFlapCurfew extends DeviceDoorCurfewModel {
  profile?: TagProfile;

  constructor({ lock_time, unlock_time }: Partial<DeviceCatFlapCurfew>) {
    super();

    this.lock_time = lock_time || '';
    this.unlock_time = unlock_time || '';
  }
}

export interface DeviceHubStatusModel extends DeviceStatusModel {
  led_mode: DeviceHubLedMode;
  pairing_mode: DeviceHubPairingMode;
}

export interface DevicePetDoorStatusModel extends DeviceStatusModel {
  battery: number;
  locking: DeviceLockingModel<PetDoorLockingMode, DevicePetDoorCurfew>;
  learn_mode: boolean;
}

export interface DeviceCatFlapStatusModel extends DeviceStatusModel {
  battery: number;
  locking: DeviceLockingModel<DeviceDualScanLockingMode, DeviceCatFlapCurfew>;
  learn_mode: DeviceDualScanLearnMode;
}

export interface DeviceFeederTypeUpdated extends DeviceStatusModel {
  battery: number;
  bowl_status: {
    current_weight: number;
    fill_weight: number;
    food_type: number;
    fill_percent: number;
    index: number;
    substance_type: number;
    last_filled_at: string;
    last_fill_weight: number;
    last_zeroed_at?: string;
  }[];
  learn_mode: boolean;
  online: boolean;
  version: any;
}

export interface DeviceHubControlModel {
  led_mode: DeviceHubLedMode;
  pairing_mode: DeviceHubPairingMode;
}

export interface DevicePetDoorControlModel {
  fast_polling?: boolean;
  curfew?: DevicePetDoorCurfew[];
  locking?: PetDoorLockingMode;
}

export interface DeviceCatFlapControlModel {
  curfew?: DeviceCatFlapCurfew[];
  // locking?: DeviceDualScanLockingMode; // TODO Check this type
  locking?: PetDoorLockingMode;
}

export interface DeviceLearnModel {
  learn_mode?: boolean;
}

export interface DeviceFeederLidModel {
  close_delay?: CloseDelays;
  mode?: number;
}

export interface DeviceFeederBowlSettingModel {
  food_type: FeederFoodType;
  target: number;
}

export class DeviceFeederBowlModel {
  // TODO Add type
  settings?: DeviceFeederBowlSettingModel[];

  type?: DeviceFeederBowlType;

  // TODO Add type
  constructor(type: DeviceFeederBowlType, fbs: DeviceFeederBowlSettingModel[]) {
    this.type = type;
    this.settings = fbs;
  }
}

export interface DeviceFeederControlModel {
  bowls?: DeviceFeederBowlModel;
  lid?: DeviceFeederLidModel;
  tare?: DeviceFeederTareType;
  training_mode?: number;
}

//Example DeviceLoadingControl
//{
//   bowls: {...DeviceFeederBowlModel, justSanded: false; request_id: '2fd40975-4757-465e-ac47-926cf9658638' };
//   lid: {...DeviceFeederLidModel, justSanded: true; request_id: 'lid_012923949595' };
//   tare: { justSanded: false; request_id: '2fd40975-4757-465e-ac47-926cf9658638'; value: DeviceFeederTareType };
//   training_mode: { justSanded: false; request_id: '2fd40975-4757-465e-ac47-926cf9658638'; value: true };
//   close_delay: { justSanded: false; request_id: '2fd40975-4757-465e-ac47-926cf9658638'; value: true };
//   led_mode: { justSanded: true; request_id: 'led_mode_0001000010'; value: 1 };
//   locking: { justSanded: false; request_id: '2fd40975-4757-465e-ac47-926cf9658638'; value: 1 };
//   tag_profiles: [{...tag_profileData, justSanded: false; request_id: '2fd40975-4757-465e-ac47-926cf9658638';}]
//   curfew: [{...curfewData, justSanded: false; request_id: '2fd40975-4757-465e-ac47-926cf9658638';}]
// }
export interface DeviceLoadingControl {
  bowls?: DeviceFeederBowlModel & { justSanded?: boolean; request_id?: string };
  lid?: DeviceFeederLidModel & { justSanded?: boolean; request_id?: string };
  tare?: { justSanded?: boolean; request_id?: string; value: DeviceFeederTareType };
  training_mode?: { justSanded?: boolean; request_id?: string; value: boolean };
  close_delay?: { justSanded?: boolean; request_id?: string; value: boolean };
  led_mode?: { justSanded?: boolean; request_id?: string; value: number };
  locking?: { justSanded?: boolean; request_id?: string; value: number };
  //[{...tagProfileData, justSanded?: boolean; request_id?: string;}]
  tag_profiles?: any[];
  //[{...curfewData, justSanded?: boolean; request_id?: string;}]
  curfew?: any[];
  fast_polling?: { value: boolean };
}
export interface DeviceFelaquaControlModel {
  tare?: boolean;
}

export interface DeviceCerberusControlModel {
  substance_type?: SubstanceType;
  food_type?: PetBowlFoodType;
}

export type DeviceControlTypeModel =
  | DeviceHubControlModel
  | DevicePetDoorControlModel
  | DeviceCatFlapControlModel
  | DeviceFeederControlModel
  | DeviceFelaquaControlModel
  | DeviceCerberusControlModel;

export interface DeviceModel {
  id?: number;
  parent_device_id?: number;
  product_id?: DeviceType;
  product_firmware_id?: number;
  household_id?: number;
  index?: number;
  name?: string;
  serial_number?: string;
  mac_address?: string;
  version?: string;
  created_at?: Date;
  updated_at?: Date;
  pairing_at?: Date;
  control?: DeviceControlTypeModel;
  status?:
    | DeviceFeederTypeUpdated
    | DevicePetDoorStatusModel
    | DeviceCatFlapStatusModel
    | DeviceHubStatusModel;
  children?: DeviceModel[];
  household?: HouseholdModel;
  parent?: DeviceModel;
  product?: ProductModel;
  tags?: DeviceTagModel[];
}

export interface AssumedPendingModel {
  deviceId: number;
  tagId: number;
  action: number;
  profile: number;
  requestId: string;
}


export interface TagAssignmentStatusModel {
  tagId: number;
  status: number | string;
  requestId: string;
}

// Example device status
// {
//   device_id: 1897
//   parent_request_id: "045041fe-7774-4f55-9102-e4313ed75578"
//   request_id: "aa99ab2a-851a-48dd-9090-cb69d54ad046"
//   requested_at: "2024-04-30T10:19:52.6356162+00:00"
//   requested_by: 4053
//   state: {
//     curfew?: [{ enabled: false, lock_time: "08:12", unlock_time: "10:23" },…]
//     locking?: 3
//   }
//   status: 5
//   status_id: 5
// }
export interface DevicePendingStatusModel {
  device_id: number;
  parent_request_id: string;
  request_id: string;
  requested_at: string;
  requested_by: number;
  state: DevicePendingStatusStateModel;
  status: number;
  status_id: number;
}

export interface DevicePendingStatusStateModel {
  curfew?: any[];
  tag_profiles?: any[];
  locking?: number;
  tare?: boolean;
  bowls?: any;
}

export interface LockUnlockRequestParamsModel {
  locking: PetDoorLockingMode;
}

export interface UpdateCurfewRequestParamsModel {
  curfew: DevicePetDoorCurfew | DeviceCatFlapCurfew[];
}

export interface ServerControlMetaModel {
  data: DeviceControlTypeModel;
  requestId: string;
  responseId: string;
  status: DeviceControlResponseStatus;
  timeToRespond: number;
  timeToSend: number;
}

export interface ServerControlResponseModel {
  data: DeviceControlTypeModel;
  results: ServerControlMetaModel[];
}

export const DeviceModelMock = (data: Partial<DeviceModel> = {}): DeviceModel => {
  return {
    id: 1,
    parent_device_id: 0,
    name: 'Mock name',
    product_id: DeviceType.FeederConnect,
    product_firmware_id: 1,
    household_id: 1,
    index: 1,
    serial_number: '',
    mac_address: '',
    version: '',
    created_at: new Date(),
    updated_at: new Date(),
    pairing_at: new Date(),
    control: {},
    status: null,
    children: [],
    household: null,
    parent: null,
    product: null,
    tags: [],
    ...data,
  };
};

export type WithFillPercent = {
  status: {
    bowl_status: {
      fill_percent: number;
      last_filled_at: string;
      last_fill_weight: number;
    }[];
  };
};

type DrinkingFeedingPrecalulatedData = {
  date: string;
  total_consumption: number;
  last_consumption: number;
  substance_type: number;
  activity: { total_consumption: number; date: string }[];
  number_of_visits: number;
  consumption_time: number;
};

export type PetStatisticsModel = {
  pet_id: number;
  movement: {
    date: string;
    time_outside: string | number;
    since: string;
    trips_outside: number;
    activity: { date: string; time_outside: string }[];
    where: PetPosition;
    last_entry: string;
    entries: number;
  };
  drinking: DrinkingFeedingPrecalulatedData;
  feeding: DrinkingFeedingPrecalulatedData;
};

export type RequestResult = 'success' | 'error' | 'none';
