import * as Notifications from 'expo-notifications';
import { useCallback, useEffect, useState } from 'react';

import { useDevicesByHousehold } from './useDevicesByHousehold';
import { usePetsByHousehold } from './usePetsByHousehold';
import { DdLogs } from '../services/SPDataDogService';
import useBoundStore from '../store/store';

export const useRefreshAllData = () => {
  const [isStaticDataRefreshing, setIsStaticDataRefreshing] = useState<boolean>(false);
  const { loadCountries, loadLanguages, loadConditions, loadBreeds, loadProducts, loadTimezones } =
    useBoundStore((s) => ({
      loadCountries: s.countryStore.loadCountries,
      loadLanguages: s.languageStore.loadLanguages,
      loadConditions: s.conditionStore.loadAll,
      loadBreeds: s.breedStore.loadBreeds,
      loadProducts: s.productStore.loadAll,
      loadTimezones: s.timezoneStore.loadTimezones,
    }));
  const { isAuthenticated, getUser } = useBoundStore((s) => s.accountStore);
  const { loadDevice } = useBoundStore((s) => s.deviceStore);
  const { getHouseholds, activeHousehold } = useBoundStore((s) => s.householdStore);
  const { getByHousehold } = useBoundStore((s) => s.timelineStore);
  const { loadPet, loadPetStatistics, loadHouseholdStatistics, ignorePetStatsRefresh } =
    useBoundStore((s) => s.petStore);

  const refreshStaticData = useCallback(async () => {
    if (isStaticDataRefreshing) {
      return;
    }
    await DdLogs.info('useRefreshAllData - refreshStaticData');

    try {
      await Promise.all([
        loadCountries(true),
        loadLanguages(true),
        loadConditions(true),
        loadBreeds(true),
        loadProducts(true),
        loadTimezones(true),
      ]);
    } catch {
    } finally {
      setIsStaticDataRefreshing(false);
    }
  }, [isStaticDataRefreshing]);

  const devices = useDevicesByHousehold();
  const pets = usePetsByHousehold();

  const refreshData = useCallback(() => {
    if (isAuthenticated && activeHousehold?.id) {
      getUser();
      loadDevice(true);
      getHouseholds();
      loadPet(true);
      if (devices.length && pets.length) {
        if (!ignorePetStatsRefresh) {
          loadPetStatistics(pets, devices, new Date(), true, 7);
        }
      } else {
        loadHouseholdStatistics(new Date(), true);
      }
      getByHousehold(activeHousehold.id, null);
    }
  }, [activeHousehold?.id, isAuthenticated, pets, devices, ignorePetStatsRefresh]);

  useEffect(() => {
    const responceReceived = Notifications.addNotificationResponseReceivedListener((response) => {
      refreshData();
      DdLogs.info('useRefreshAllData - responceReceived');
    });

    return () => {
      responceReceived.remove();
      DdLogs.info('useRefreshAllData - responceReceived - remove');
    };
  }, [activeHousehold?.id, refreshData]);

  const refreshPetsAndDevices = useCallback(() => {
    if (isAuthenticated && activeHousehold?.id) {
      loadDevice(true);
      loadPet(true);
    }
  }, [activeHousehold?.id, isAuthenticated]);

  return { refreshData, refreshPetsAndDevices, refreshStaticData };
};
