import { Selector } from '@constants/Navigation';
import { RemoteConfigKeys } from '@constants/RemoteConfig';
import { lastScreenOptions } from '@constants/Screen';
import { useAcceptedPushNotifications } from '@hooks/useAcceptedPushNotifications';
import { useHouseholdRemovedRedirect } from '@hooks/useHouseholdRemovedRedirect';
import { useIsLogoutRunning } from '@hooks/useIsLogoutRunning';
import useNetworkStatus from '@hooks/useNetwork';
import { useRefreshAllData } from '@hooks/useRefreshAllData';
import { useRefreshAnalyticsData } from '@hooks/useRefreshAnalyticsData';
import { useRemoteConfigStringValue } from '@hooks/useRemoteConfigStringValue';
import { useSendAnalyticsDataAfterLogin } from '@hooks/useSendAnalyticsDataAfterLogin';
import { useCustomToast } from '@hooks/useToast';
import CustomHeader from '@molecules/CustomHeader';
import ErrorBoundary from '@molecules/ErrorBoundary';
import OfflineWarning from '@molecules/OfflineWarning';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { getLocale } from '@utils/date';
import { setDefaultOptions } from 'date-fns';
import * as Notifications from 'expo-notifications';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppState, DeviceEventEmitter } from 'react-native';

import AboutScreen from './About';
import AccountNavigation from './Account';
import AuthTokenLogin from './AuthTokenLogin';
import DashboardNavigation from './Dashboard';
import PetsNavigation from './Dashboard/Pets';
import { DashboardHeader } from './Dashboard/molecules/DashboardHeader';
import DashboardNotifications from './Dashboard/molecules/NotificationsModal/DashboardNotifications';
import DeviceNavigation from './Device';
import ErrorScreen from './ErrorScreen';
import FlowNavigation from './Flows';
import HouseholdNavigation from './Household';
import { HouseholdRemoved } from './HouseholdRemoved';
import LoginNavigation from './Login';
import OnboardingLetsStart from './OnboardingLetsStart/OnboardingLetsStart';
import Register from './Register/Register';
import RemoveAccountNavigation from './RemoveAccount';
import ResetPassword from './ResetPassword/ResetPassword';
import { navigationRef } from './RootNavigation';
import SuccessScreen, { navObject } from './SuccessScreen';
import Tour from './Tour';
import ReconfirmTermsAndConditions from './UpdateTerms/index';
import WebviewScreen from './Webview';
import linking from './linking';
import { AnalyticsService } from '../services/AnalyticsService';
// import { DdRumReactNavigationTracking } from "@datadog/mobile-react-navigation";
import { DdLogs, DdRumReactNavigationTracking, setUser } from '../services/SPDataDogService';
import { TimeService } from '../services/TimeService';
import useBoundStore from '../store/store';

type SuccessScreenPropsType = {
  title: string;
  msg?: string;
  buttonText?: string;
  withButton?: boolean;
  buttonNavObject?: navObject;
  withHeader?: boolean;
  headerNavObject?: navObject;
};

export type RootStackParamList = {
  DashboardNavigation: {
    screen: string;
  };
  AccountNavigation: undefined;
  LoginNavigation: {
    screen: string;
  };
  FlowNavigation: {
    screen: string;
    params?: {
      id: number;
      nextPage?: {
        stack: string;
        screen: string;
      };
    };
  };
  OnboardingLetsStart: {
    screen: undefined;
  };
  OnboardingNavigation: undefined;
  HouseholdNavigation: {
    screen: string;
  };
  AboutScreen: undefined;
  WebviewScreen: {
    uri: string;
    title: string;
  };
  AboutNavigation: undefined;
  PetsNavigation: {
    screen: string;
    params: any;
  };
  DeviceNavigation: Selector;
  RegisterNavigation: undefined;
  ReconfirmTermsAndConditions: {
    daysToExpire: number | boolean;
  };
  SuccessScreen: SuccessScreenPropsType;
  SuccessScreenModal: SuccessScreenPropsType;
  RemoveAccountNavigation: undefined;
  LogoutScreen: undefined;
  DashboardNotifications: undefined;
  HouseholdRemoved: undefined;
  Tour: undefined;
  AuthTokenLogin: {
    token: string;
  };
  ResetPassword: {
    token: string;
  };
  Auth: undefined;
  ErrorScreen: undefined;
};

const Stack = createStackNavigator<RootStackParamList>();

export default function Navigation() {
  // Load translations here and now so we dont see loading spinner later
  // eslint-disable-next-line
  const { t } = useTranslation();
  const routeNameRef = React.useRef<string>('');
  const appState = useRef(AppState.currentState);

  const { checkState } = useAcceptedPushNotifications();
  const runHouseholdRemovedValidation = useHouseholdRemovedRedirect();
  const getUser = useBoundStore((s) => s.accountStore.getUser);
  const isAuthenticated = useBoundStore((s) => s.accountStore.isAuthenticated());
  const isLogoutRunning = useIsLogoutRunning();
  const { registerForPushNotifications, user, logout } = useBoundStore((s) => s.accountStore);
  const termsAndConditionsUpdated = useRemoteConfigStringValue(RemoteConfigKeys.newTermsDate);
  const { show } = useCustomToast();
  const { refreshPetsAndDevices, refreshStaticData, refreshData } = useRefreshAllData();
  const { networkState, disableNetworkCheck, runManualCheck } = useNetworkStatus();
  useRefreshAnalyticsData();
  useSendAnalyticsDataAfterLogin();
  const [nextAppState, setNextAppState] = useState(AppState.currentState);
  const skipInit =
    !navigationRef.isReady() || navigationRef.getCurrentRoute()?.name === 'AuthTokenLogin';

  useEffect(() => {
    const emitTermsAndConditions = async () => {
      await DeviceEventEmitter.emit('termsAndConditionsUpdatedDate', termsAndConditionsUpdated);
    };
    emitTermsAndConditions();
  }, [termsAndConditionsUpdated]);

  const {
    activeHousehold,
    getHouseholds,
    loadNotifications,
    languages,
    getLanguageByID,
    loadLanguages,
    loadCountries,
  } = useBoundStore((s) => ({
    activeHousehold: s.householdStore.activeHousehold,
    getHouseholds: s.householdStore.getHouseholds,
    loadNotifications: s.notificationStore.loadAll,
    languages: s.languageStore.languages,
    user: s.accountStore.user,
    getLanguageByID: s.languageStore.getLanguageByID,
    loadLanguages: s.languageStore.loadLanguages,
    loadCountries: s.countryStore.loadCountries,
  }));
  useEffect(() => {
    // set Timezone in TimeService if activeHousehold is set
    if (activeHousehold) {
      TimeService.changeDefaultTimezone(activeHousehold.timezone.timezone);
    }
  }, [activeHousehold]);

  useEffect(() => {
    // LogBox.ignoreAllLogs();
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      DdLogs.info(`AppStateUpdate ${appState.current}`, { appState: appState.current }).catch(
        (e) => {
          console.warn(e);
        },
      );

      appState.current = nextAppState;
      setNextAppState(nextAppState);
    });
    return () => {
      subscription.remove();
    };
  }, [appState]);

  useEffect(() => {
    if (AppState.currentState === 'active') {
      console.log('AppState.currentState === active');
      Notifications.setBadgeCountAsync(0);

      try {
        runManualCheck();
        refreshData();
      } catch (err) {
        console.log(err);
      }
    }
  }, [nextAppState]);

  useEffect(() => {
    if (isLogoutRunning) {
      return;
    }

    // Run on login and logoutr
    refreshStaticData();
    DdLogs.info('pages/index - useEffect - refreshStaticData');
  }, [isAuthenticated, isLogoutRunning]);

  useEffect(() => {
    if (user && user.id && isAuthenticated) {
      setUser(user?.id);
    }
  }, [user, isAuthenticated]);

  useEffect(() => {
    loadCountries(true);
    loadLanguages(true);
    if (skipInit) {
      return;
    }
    if (isAuthenticated) {
      getHouseholds();
      loadNotifications(true);
      getUser();
      // refreshStaticData();
      // check state of push notification in case user logs out directly
      checkState();
      // register if authenticated
      registerForPushNotifications();
      runHouseholdRemovedValidation(true);
      DdLogs.info('pages/index - useEffect - loaded data');
    } else {
      runHouseholdRemovedValidation(false);
      DdLogs.info('pages/index - useEffect - not authenticated');
    }
  }, [isAuthenticated, skipInit]);

  useEffect(() => {
    refreshPetsAndDevices();
    DdLogs.info('pages/index - useEffect - refreshPetsAndDevices');
  }, [activeHousehold?.id]);

  useEffect(() => {
    if (skipInit) {
      return;
    }

    if (languages.length && (user?.language_id || user?.language_id === 0)) {
      const { code } = getLanguageByID(user.language_id);
      setDefaultOptions({ locale: getLocale(code) });
      DdLogs.info('pages/index - useEffect - setUserLanguage');
    }
  }, [languages, user?.language_id, skipInit]);

  if (networkState && !disableNetworkCheck && networkState.isConnected === false) {
    // if(true ){

    return <OfflineWarning />;
  }
  return (
    <ErrorBoundary showErrorToast={show} logout={logout} isAuthenticated={isAuthenticated}>
      <NavigationContainer<RootStackParamList>
        ref={navigationRef}
        linking={linking}
        onReady={() => {
          routeNameRef.current = navigationRef.current.getCurrentRoute().name;
          if (DdRumReactNavigationTracking) {
            DdRumReactNavigationTracking.startTrackingViews(navigationRef.current);
          }
        }}
        onStateChange={async () => {
          const previousRouteName = routeNameRef.current;
          const currentRouteName = navigationRef.current.getCurrentRoute().name;

          if (previousRouteName !== currentRouteName) {
            await AnalyticsService.logScreenView(currentRouteName);
          }
          routeNameRef.current = currentRouteName;
        }}>
        <Stack.Navigator
          initialRouteName="DashboardNavigation"
          id="Stack"
          screenOptions={() => ({})}>
          {isAuthenticated && (
            <>
              <Stack.Screen
                name="DashboardNavigation"
                options={{
                  header: () => <DashboardHeader householdName={activeHousehold?.name} />,
                }}
                component={DashboardNavigation}
              />
              <Stack.Screen
                name="AccountNavigation"
                component={AccountNavigation}
                options={{
                  gestureEnabled: true,
                  headerShown: false,
                }}
              />
              <Stack.Screen
                name="WebviewScreen"
                component={WebviewScreen}
                options={{
                  headerShown: true,
                  header: () => CustomHeader({ withTitle: false, withArrowBack: false }),
                }}
              />
              <Stack.Screen
                name="AboutScreen"
                component={AboutScreen}
                options={{
                  headerShown: true,
                  header: () => CustomHeader({ withTitle: false, withArrowBack: false }),
                }}
              />
              <Stack.Screen
                name="HouseholdNavigation"
                component={HouseholdNavigation}
                options={{
                  headerShown: false,
                }}
              />
              <Stack.Screen
                name="FlowNavigation"
                component={FlowNavigation}
                options={{
                  headerShown: false,
                }}
              />
              <Stack.Screen
                name="DeviceNavigation"
                component={DeviceNavigation}
                options={{
                  headerShown: false,
                }}
              />
              <Stack.Screen
                name="PetsNavigation"
                component={PetsNavigation}
                options={{
                  headerShown: false,
                }}
              />
              <Stack.Screen
                name="ReconfirmTermsAndConditions"
                options={{
                  headerShown: false,
                }}
                component={ReconfirmTermsAndConditions}
              />
              <Stack.Screen
                name="RemoveAccountNavigation"
                component={RemoveAccountNavigation}
                options={{
                  gestureEnabled: true,
                  headerShown: false,
                }}
              />
              <Stack.Screen
                name="DashboardNotifications"
                component={DashboardNotifications}
                options={{
                  headerShown: false,
                }}
              />
              <Stack.Screen
                name="HouseholdRemoved"
                options={{ ...lastScreenOptions() }}
                component={HouseholdRemoved}
              />
              <Stack.Screen
                name="Tour"
                component={Tour}
                options={{
                  gestureEnabled: false,
                  headerShown: false,
                  presentation: 'transparentModal',
                }}
              />
            </>
          )}
          <Stack.Screen
            name="OnboardingLetsStart"
            options={{
              headerShown: false,
            }}
            component={OnboardingLetsStart}
          />

          <Stack.Screen
            name="RegisterNavigation"
            component={Register}
            options={{
              gestureEnabled: true,
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="LoginNavigation"
            component={LoginNavigation}
            options={{
              gestureEnabled: true,
              headerShown: false,
              presentation: 'transparentModal',
            }}
          />
          <Stack.Screen
            name="SuccessScreen"
            component={SuccessScreen}
            options={{
              gestureEnabled: true,
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="SuccessScreenModal"
            component={SuccessScreen}
            options={{
              gestureEnabled: true,
              headerShown: false,
              ...lastScreenOptions(),
            }}
          />
          <Stack.Screen
            name="AuthTokenLogin"
            component={AuthTokenLogin}
            options={{
              gestureEnabled: true,
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="ResetPassword"
            component={ResetPassword}
            options={{
              presentation: 'modal',

              header: () =>
                CustomHeader({
                  withTitle: false,
                  withArrowBack: false,
                  goBackFunc: () => navigationRef.navigate('OnboardingLetsStart'),
                }),
            }}
          />
          <Stack.Screen
            name="ErrorScreen"
            component={ErrorScreen}
            options={{
              gestureEnabled: true,
              headerShown: true,
            }}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </ErrorBoundary>
  );
}
