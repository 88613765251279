import { PetStatisticsModel } from '@models/Device';
import qs from 'qs';

import Http from './Http';
import { PetModel } from '../models/Pet';
import PetStatisticsService from '../services/PetStatisticsService';

class PetApi {
  static readonly httpParams = {
    with: ['photo', 'breed', 'conditions', 'tag', 'food_type', 'species', 'position', 'status'],
  };

  static getPets(): Promise<PetModel[]> {
    return Http.get(`/api/pet`, {
      params: this.httpParams,
      paramsSerializer: (params) => qs.stringify(params),
    }).then((response) => response.data.data);
  }

  static getPetStatistics(
    petIds: number[],
    from: string,
    daysInHistory?: number,
  ): Promise<PetStatisticsModel[]> {
    return Http.get(
      `/api/dashboard/pet?Pet_Id=${petIds.join('&Pet_Id=')}&From=${from}${
        daysInHistory ? `&dayshistory=${daysInHistory}` : ''
      }`,
      {
        params: this.httpParams,
      },
    ).then((response) => {
      return PetStatisticsService.convertMovementDaysToHours(response.data.data);
    });
  }

  static createPet(pet: PetModel): Promise<PetModel[]> {
    return Http.post(`/api/pet`, pet, {
      params: this.httpParams,
      paramsSerializer: (params) => qs.stringify(params),
    }).then((response) => response.data.data);
  }

  static updatePet(petId: number, pet: PetModel): Promise<PetModel[]> {
    return Http.put(`/api/pet/${petId}`, pet, {
      params: this.httpParams,
      paramsSerializer: (params) => qs.stringify(params),
    }).then((response) => response.data.data);
  }

  static deletePet(petId: number): Promise<PetModel[]> {
    return Http.delete(`/api/pet/${petId}`).then((response) => response.data.data);
  }

  static updatePetPosition(
    petId: number,
    position: { where: number; since: string },
  ): Promise<PetModel[]> {
    return Http.post(`/api/pet/${petId}/position`, position, {
      params: this.httpParams,
      paramsSerializer: (params) => qs.stringify(params),
    }).then((response) => response.data.data);
  }
}

export default PetApi;
