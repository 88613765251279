import { DeviceType } from '@constants/Device';
import { usePetDevices } from '@hooks/usePetDevices';
import { DeviceModel } from '@models/Device';
import { PetModel } from '@models/Pet';
import React, { useMemo } from 'react';
import useBoundStore from 'src/store/store';

export const usePetCardQuickActions = (
  pet: PetModel,
): {
  changeLocation: DeviceModel[];
  zeroBowl: DeviceModel[];
  indoorOnlyMode: DeviceModel[];
} => {
  const devices = usePetDevices(pet);
  const { devicesIdsRequestsTrack } = useBoundStore(s => s.deviceStore)

  return useMemo(() => {
    const { petDoorDevices, feederDevices, catFlapDevices } = devices.reduce(
      (acc, item) => {
        if (item.product_id === DeviceType.PetDoorConnect) {
          acc.petDoorDevices.push(item);
        }

        if (item.product_id === DeviceType.CatFlapConnect) {
          acc.catFlapDevices.push(item);
        }

        if (item.product_id === DeviceType.FeederConnect) {
          acc.feederDevices.push(item);
        }

        return acc;
      },
      { catFlapDevices: [], petDoorDevices: [], feederDevices: [] },
    );
    
    return {
      changeLocation: [...petDoorDevices, ...catFlapDevices],
      indoorOnlyMode: catFlapDevices.length === 1 ? catFlapDevices : [],
      zeroBowl: feederDevices.length === 1 ? feederDevices : [],
    };
  }, [devices,devicesIdsRequestsTrack, pet]);
};
