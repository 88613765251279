import { SpView } from '@atoms/SpView';
import { CloseDelays } from '@constants/Device';

import { DeviceFeederLidModel } from '@models/Device';

import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';
import SpTitle from '@molecules/SpTitle';
import { useTranslation } from 'react-i18next';
import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import colors from '@styles/colors';
import { DelayOptions } from './DelayOptions';
import { useNavigation } from '@react-navigation/native';

interface LidDelayProps {
  data: DeviceFeederLidModel;
  onChangeDelay: (data: CloseDelays) => void;
}

export const LidDelay = ({ data, onChangeDelay }: LidDelayProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const [activeDelayLid, setActiveDelayLid] = useState<CloseDelays | undefined>(
    data?.close_delay,
  );

  const handleSave = useCallback(() => {
    onChangeDelay(activeDelayLid);
    navigation.goBack();
  }, [activeDelayLid]);

  return (
    <SpView flex={1} marginTop={-25} alignItems="flex-start">
      <SpTitle text={t('lid_delay_timer')} />
      <DelayOptions active={activeDelayLid} onChange={setActiveDelayLid} />
      <SpRoundedHeaderButton
        stylesForContainer={styles.button}
        title={t('save')}
        h={56}
        backgroundColor={colors.primary.color}
        onPress={handleSave}
      />
    </SpView>
  );
};

const styles = StyleSheet.create({
  button: { marginTop: 'auto', width: '100%' },
});
