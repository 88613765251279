import { testProperties } from '@utils/testProperties';
import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { StyleSheet } from 'react-native';
import { SpView } from './SpView';

interface SpProgressBar {
  value: number;
  width: number | string;
  height?: number;
  indicatorColors?: string[];
  borderRadius?: number;
  componentName?: string;
  rotate?: boolean;
  start?: { x: number; y: number };
}

export const SpProgressBar = ({
  value,
  width = 12,
  height = 32,
  indicatorColors = ['#a3d76c', '#4bb96a'],
  borderRadius,
  componentName,
  rotate = false,
  start = { x: 0.5, y: 0 },
}: SpProgressBar) => {
  return (
    <SpView
      {...testProperties('status', 'progress bar', componentName)}
      style={[
        { ...styles.container, width, height },
        rotate ? height : null,
        rotate ? { justifyContent: 'flex-end' } : null,
        borderRadius && { borderRadius },
      ]}
    >
      <LinearGradient
        colors={indicatorColors}
        start={start}
        style={[
          {
            ...styles.indicator,
          },
          rotate ? { height: `${value || 0}%` } : { width: `${value || 0}%` },
          borderRadius && { borderRadius },
        ]}
      />
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#dfe1e2',
    borderRadius: 16,
    overflow: 'hidden',
    flex: 1,
  },
  indicator: {
    height: '100%',
    // flex: 1,
    borderRadius: 10,
  },
});
