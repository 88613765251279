import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet } from 'react-native';
import colors from '@styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { backgroundColor } from 'react-native-calendars/src/style';

type CDBHelpWifiNetworkProps = {};

const CDBHelpWifiNetwork: React.FC<CDBHelpWifiNetworkProps> = () => {
  const { t } = useTranslation();

  return (
    <SpView style={styles.container}>
      <SpText color="#fff" size="sm" style={styles.question}>
        {t('setup_cdb_wifi_help')}
      </SpText>
      <SpText color="#fff" size="sm" style={styles.paragraph}>
        {t('setup_cdb_wifi_paragraph_1')}
      </SpText>
      <SpText color="#fff" size="sm" style={styles.paragraph}>
        {t('setup_cdb_wifi_paragraph_2')}
      </SpText>
      <SpView style={styles.wrapperIconInText} color={'white'}>
        <FontAwesomeIcon icon={faCircleExclamation} size={35} color={'orange'} style={styles.iconStyle}/>
        <SpText style={styles.textIcon} color={'white'}>{t('please_remember')}</SpText>
      </SpView>
      <SpView style={styles.paragraphText}>
        <SpView><SpText style={styles.textTitle} color={'white'}>{t('allow_app_find_devices_title')}</SpText></SpView>
        <SpView><SpText style={styles.textDescription} color={'white'}>{t('allow_app_find_devices_description')}</SpText></SpView>
      </SpView>
      <SpView style={styles.paragraphText}>
        <SpView><SpText style={styles.textTitle} color={'white'}>{t('network_connection_popup_title')}</SpText></SpView>
        <SpView>
          <SpText style={styles.textDescription} color={'white'}>
            {Platform.OS === 'ios' ? t('network_connection_popup_description_ios') : t('network_connection_popup_description_android')}
          </SpText>
        </SpView>
      </SpView>
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.helpScreenColor.color,
    color: 'white',
    height: '100%',
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  paragraph: {
    color: 'white',
    fontSize: 16,
    marginTop: 15,
  },
  paragraph2: {
    color: 'white',
    fontSize: 16,
    marginTop: 55,
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 14,
    marginTop: 20,
    marginBottom: 5,
  },
  paragraphDesc: {
    color: 'white',
    fontSize: 14,
  },
  link: {
    color: 'white',
    marginTop: 25,
    fontWeight: 'bold',
    fontSize: 16,
  },
  question: {
    fontSize: 20,
  },
  wrapperIconInText: {
    flexDirection: 'row',
    marginTop: 40,
    marginBottom: 20,
  },
  iconStyle: {
    backgroundColor:colors.helpScreenColor.color,
    flex: 1
  },
  textIcon: {
    flex: 1,
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    fontSize: 22,
  },
  textTitle: {
    fontSize: 18,
    fontFamily: 'Rubik_SemiBold',
    marginBottom: 8,
  },
  textDescription: {
    fontSize: 16,
  },
  paragraphText: {
    marginBottom: 30
  }
});
export default CDBHelpWifiNetwork;
