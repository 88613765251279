import { useDetectPushNotification } from '@hooks/useDetectPushNotification';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { useRefreshPetData } from '@hooks/useRefreshPetData';
import { useIsFocused } from '@react-navigation/native';
import * as React from 'react';
import { useEffect } from 'react';

import useBoundStore from '../../../store/store';
import { PetCarousel } from '../molecules/PetCarousel/PetCarousel';

export const PetList = () => {
  const { refreshPetData, refreshStats, onceLoaded, dataRefreshing, setOnceLoaded } =
    useRefreshPetData();
  const activeHousehold = useBoundStore((state) => state.householdStore.activeHousehold);
  const { deviceData, updateDevicesIdsRequestsTrack, devicesIdsRequestsTrack, refreshPetsDevices } =
    useBoundStore((s) => s.deviceStore);
  const [notEmpty, setNotEmpty] = React.useState([]);
  const isFocused = useIsFocused();
  const pets = usePetsByHousehold();
  const { saveAction } = useDetectPushNotification();

  useEffect(() => {
    setOnceLoaded(false);
    updateDevicesIdsRequestsTrack(
      deviceData
        .filter((item) => item.household_id === activeHousehold?.id)
        .reduce((acc, elem) => {
          acc[elem.id] = [1]; // check all devices on init
          return acc;
        }, {}),
    );
  }, [activeHousehold?.id]);

  useEffect(() => {
    const res = [];
    for (const id in devicesIdsRequestsTrack) {
      if (devicesIdsRequestsTrack[id]?.length) res.push(id);
    }
    setNotEmpty(res);
  }, [devicesIdsRequestsTrack]);

  useEffect(() => {
    const fetchAndUpdate = async (ids) => {
      if (!notEmpty.length) {
        await refreshPetData();
        return;
      } else {
        console.log(notEmpty);
      }
      const result = await refreshPetsDevices(ids);
      updateDevicesIdsRequestsTrack({
        ...devicesIdsRequestsTrack,
        ...result.result,
      });
      setNotEmpty((old) => {
        if (old.length > result.newIdsArray.length) refreshStats();
        return result.newIdsArray;
      });
    };

    if (isFocused) {
      const fetchInterval = setInterval(() => {
        fetchAndUpdate(notEmpty);
      }, 10000);

      if (
        devicesIdsRequestsTrack &&
        !Object?.values(devicesIdsRequestsTrack).some((elem) => elem.length)
      ) {
        clearInterval(fetchInterval);
      }

      return () => {
        clearInterval(fetchInterval);
      };
    }
  }, [isFocused, notEmpty]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isFocused) {
      console.log('refreshing pet data');
      refreshPetData();

      saveAction(refreshPetData, 'pet carousel refresh');

      const id = setInterval(() => {
        refreshPetData();
      }, 1000 * 60);

      return () => {
        clearInterval(id);
      };
    }
    // TODO - i used to have device data here, removed it because it cased circular triggering of this effect
    // as refreshPetData was updating device data
  }, [isFocused, activeHousehold?.id, updateDevicesIdsRequestsTrack, devicesIdsRequestsTrack]);

  return <PetCarousel pets={pets} loading={!onceLoaded} dataRefreshing={dataRefreshing} />;
};
