import {
  SafeAreaView,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import colors from '@styles/colors';
import { SpInput } from '@atoms/SpInput';
import { dismissKeyboard } from '@utils/keyboardDismiss';
import useValidation from 'src/pages/validation';
import { useFormik } from 'formik';
import noop from '@utils/noop';
import { useTranslation } from 'react-i18next';

export interface PetBioSpayedProps {
  label: string;
  value: string;
  handleChange: (val: string) => void;
  setButtonDisabled?: Dispatch<SetStateAction<boolean>>;
}

const PetBioCommentWrapper = ({
  label,
  value,
  handleChange,
  setButtonDisabled,
}: PetBioSpayedProps) => {
  const [currentValue, setCurrentValue] = useState(value || '');
  const { commentsScheme } = useValidation();
  const { t } = useTranslation()
  const formik = useFormik({
    validationSchema: commentsScheme,
    initialValues: { comments: currentValue },
    onSubmit: noop,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (formik.errors?.comments) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [formik.errors?.comments, currentValue]);


  const onChangeComments = (text: string) => {
    setCurrentValue(text);
    handleChange(text);
    formik.handleChange('comments')(text);
  };

  return (
    <TouchableWithoutFeedback
      accessible={false}
      onPress={() => dismissKeyboard()}
    >
      <SafeAreaView style={styles.container}>
        <View style={styles.inner}>
          <SpInput
            value={value}
            multiline
            label={label}
            onChangeText={onChangeComments}
            error={t(formik.errors.comments)}
            multilineError
          />
        </View>
      </SafeAreaView>
    </TouchableWithoutFeedback>
  );
};

export default PetBioCommentWrapper;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  inner: {
    flex: 1,
    backgroundColor: colors.white.color,
    paddingHorizontal: 19,
    paddingTop: 48,
  },
  buttonWrapper: {
    flex: 1,
    justifyContent: 'flex-end',
    marginBottom: 34,
  },
});
