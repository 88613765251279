import { SpLoading } from '@atoms/SpLoading';
import useToastEffect from '@hooks/useToastEffect';
import useTour from '@hooks/useTour';
import { useUserHasWriteAccess } from '@hooks/useUserHasWriteAccess';
import { DeviceModel } from '@models/Device';
import { useIsFocused } from '@react-navigation/native';
import React, { useEffect, useLayoutEffect } from 'react';

import { MergedInterfaces } from '../../../store/models';
import useBoundStore from '../../../store/store';
import ProductsControlSlide from '../../Tour/components/ProductsControlSlide/ProductsControlSlide';
import ZeroScalesSlide from '../../Tour/components/ZeroScalesSlide/ZeroScalesSlide';
import { FeederCard } from '../molecules/FeederDevice/FeederCard';

interface FeederDeviceWrapperProps {
  data: DeviceModel;
  deviceId: number;
}

export const FeederDeviceWrapper = ({ data, deviceId }: FeederDeviceWrapperProps) => {
  const {
    loadingDevice,
    updateZeroScalesError,
    updateZeroScalesSuccess,
    updateProperty,
    updateZeroScalesAsync,
  } = useBoundStore((state: MergedInterfaces) => {
    const { deviceStore } = state;
    return {
      loadingDevice: deviceStore.loadingDevice,
      updateZeroScalesError: deviceStore.updateZeroScalesError,
      updateZeroScalesSuccess: deviceStore.updateZeroScalesSuccess,
      updateProperty: deviceStore.updateProperty,
      updateZeroScalesAsync: deviceStore.updateZeroScalesAsync,
    };
  });
  const userHasWriteAccess = useUserHasWriteAccess();

  const isFocused = useIsFocused();

  const initTour = useTour({
    uniqueId: 'v0-products-control-feeder',
    components: [<ProductsControlSlide />, <ZeroScalesSlide />],
    devices: ['all'],
    showSharedSlideOneTimeIndex: {
      tourGroup: 'feeder-flap-door',
      slideIndexToHide: 0,
    },
  });
  const resetter = () =>
    updateProperty({
      updateZeroScalesError: null,
      updateZeroScalesSuccess: null,
      loadingDevice: false,
      updateZeroScalesLoading: false,
    });
  useLayoutEffect(() => {
    initTour();
  }, [isFocused]);

  useEffect(() => {
    return resetter;
  }, []);

  useToastEffect({
    resetter: () => {
      updateProperty({
        loadingDevice: false,
        updateZeroScalesLoading: false,
      });
    },
    successCondition: !!updateZeroScalesSuccess,
    errorCondition: !!updateZeroScalesError,
    successToastMessage: 'success_zero',
    errorToastMessage: '',
    translate: true,
  });

  if (loadingDevice) {
    return <SpLoading />;
  }

  return <FeederCard data={data} userHasWriteAccess={userHasWriteAccess} />;
};
