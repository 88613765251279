import { useTimeTransform } from '@hooks/useTimeTransform';
import { DevicePetDoorCurfew } from '@models/Device';
import { addMinutes, format, parse } from 'date-fns';
import { cloneDeep, isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import useBoundStore from 'src/store/store';

import { getLocalTime } from '../../../services/TimeService';

export const useSingleCurfew = (
  data: DevicePetDoorCurfew,
  onChangeCurfew: (data: DevicePetDoorCurfew) => void,
) => {
  const activeHousehold = useBoundStore((s) => s.householdStore.activeHousehold);

  const { to24H } = useTimeTransform();
  const [currentCurfew, setCurrentCurfew] = useState<DevicePetDoorCurfew>(null);
  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      lock_time: '',
      unlock_time: '',
    },
  });

  const curfewIsChanged = useMemo(() => {
    if (!currentCurfew || (!currentCurfew.lock_time && !currentCurfew.unlock_time)) {
      return false;
    }

    return !isEqual(
      {
        lockTime: currentCurfew.lock_time,
        unlockTime: currentCurfew.unlock_time,
        enabled: currentCurfew.enabled,
      },
      {
        lockTime: data.lock_time,
        unlockTime: data.unlock_time,
        enabled: data.enabled,
      },
    );
  }, [currentCurfew, data]);

  useEffect(() => {
    const curfew = data
      ? cloneDeep({
          ...data,
          lock_time: getLocalTime(
            data.lock_time,
            activeHousehold.timezone.timezone.replace('Kolkata', 'Calcutta'),
          ),
          unlock_time: getLocalTime(
            data.unlock_time,
            activeHousehold.timezone.timezone.replace('Kolkata', 'Calcutta'),
          ),
        })
      : new DevicePetDoorCurfew();

    setValue('lock_time', curfew.lock_time);
    setValue('unlock_time', curfew.unlock_time);
    setCurrentCurfew(curfew);
  }, [data]);

  useEffect(() => {
    const subscription = watch((values) => setCurrentCurfew({ ...currentCurfew, ...values }));
    return () => subscription.unsubscribe();
  }, [watch, currentCurfew]);

  const saveCurfewAction = useCallback(
    (force = false) => {
      const curfewToSave = cloneDeep(currentCurfew);
      let result;
      let unlockTime = curfewToSave.unlock_time;
      if (force) {
        result = !curfewToSave.enabled;
      } else {
        result = curfewToSave.enabled;
      }
      if (curfewToSave.unlock_time === curfewToSave.lock_time) {
        const addMinute = addMinutes(
          parse(to24H(curfewToSave.unlock_time), 'HH:mm', new Date()),
          1,
        );
        unlockTime = format(addMinute, 'HH:mm');
      }
      if (curfewToSave?.lock_time && curfewToSave?.unlock_time) {
        curfewToSave.lock_time = to24H(curfewToSave?.lock_time);
        curfewToSave.unlock_time = to24H(unlockTime);
      }
      console.log('curfewToSave', curfewToSave);
      onChangeCurfew({ ...curfewToSave, closePopup: true, enabled: result });
    },
    [currentCurfew, onChangeCurfew],
  );

  return {
    currentCurfew,
    control,
    curfewIsChanged,
    handleSubmit,
    saveCurfewAction,
    setCurrentCurfew,
  };
};
