import { TimeUnits } from '@constants/DateFormat';
import { differenceInDays, format, startOfDay } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTimeAMPMTransform } from './useTimeAMPMTransform';
import { TimeService } from '../services/TimeService';
import useBoundStore from '../store/store';

interface UseDateTransform {
  transformToHumanDateAgo: (dateAsString: Date, userFormat?: TimeUnits) => string;
  householdNowTime: Date;
  transformHoursToUTC: (time: string) => string;
}

export const useDateTransform = (): UseDateTransform => {
  const { t } = useTranslation();
  const { getAMPMLabelFromDate } = useTimeAMPMTransform();
  const activeHousehold = useBoundStore((s) => s.householdStore.activeHousehold);

  function createUTCOffsetString(seconds: number) {
    // Convert milliseconds to minutes
    let totalMinutes = seconds / 60;

    // Determine the sign for the offset
    const sign = totalMinutes >= 0 ? '+' : '-';

    // Convert total minutes to absolute value for further calculation
    totalMinutes = Math.abs(totalMinutes);

    // Calculate hours and minutes
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    // Format hours and minutes to always have two digits
    const hoursFormatted = hours.toString().padStart(2, '0');
    const minutesFormatted = minutes.toString().padStart(2, '0');

    // Construct the UTC offset string
    return `${sign}${hoursFormatted}:${minutesFormatted}`;
  }

  // replace broken timezone, until using utc_offset is confirmed reliable
  const transformToHumanDateAgo = useCallback(
    (dateAsString: Date | number, userFormat: TimeUnits = TimeUnits.AMPM) => {
      const date = utcToZonedTime(
        dateAsString,
        // createUTCOffsetString(activeHousehold.timezone.utc_offset ),
        activeHousehold.timezone.timezone.replace('Kolkata', 'Calcutta'),
      );

      const currentDay = utcToZonedTime(
        new Date(),
        // createUTCOffsetString(activeHousehold.timezone.utc_offset ),
        activeHousehold.timezone.timezone.replace('Kolkata', 'Calcutta'),
      );
      const time =
        userFormat === TimeUnits.AMPM
          ? `${format(date, 'h:mm')} ${getAMPMLabelFromDate(date)}`
          : format(date, 'HH:mm');

      if (format(date, 'd M Y') === format(currentDay, 'd M Y')) {
        return t('today_at_{{time}}', {
          time,
        });
      }
      if (differenceInDays(startOfDay(currentDay), startOfDay(date)) === 1) {
        return t('yesterday_at_{{time}}', {
          time,
        });
      }
      return t('{{date}}_at_{{time}}', {
        date: format(date, 'do LLLL'),
        time,
      });
    },
    [activeHousehold],
  );

  const householdNowTime = useMemo(() => {
    if (!activeHousehold) {
      return null;
    }

    return zonedTimeToUtc(new Date(), activeHousehold.timezone.timezone);
  }, [activeHousehold]);

  //TODO move this to TimeService
  const transformHoursToUTC = useCallback((time: string) => {
    return TimeService.toLocal()
      .set({
        hour: Number(time.split(':')[0]),
        minute: Number(time.split(':')[1]),
      })
      .toUTC()
      .toFormat('HH:mm');
  }, []);

  return {
    transformToHumanDateAgo,
    householdNowTime,
    transformHoursToUTC,
  };
};
