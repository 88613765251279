import { FlatList, StyleSheet } from 'react-native';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { SpView } from '@atoms/SpView';
import { SpText } from '@atoms/SpText';
import text from '@styles/text';
import { SpRoundedHeaderButton } from '@atoms/SpRoundedHeaderButton';
import colors from '@styles/colors';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import SpModal from '@molecules/SpModal';
import { closeModal, openModal } from 'src/services/ModalBox';
import { useTranslation } from 'react-i18next';
import { PetModel } from '@models/Pet';
import IndoorModeInfo from 'src/pages/Device/molecules/PetDoorCatFlapDevice/IndoorModeInfo';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import CustomHeader from '@molecules/CustomHeader';
import PetItem from '../Molecules/PetItem';
import { FlowStackParamList } from '..';
import { testProperties } from '@utils/testProperties';
import useBoundStore from 'src/store/store';

type IndoorModePage = {
  petList: PetModel[];
  indoorModeEnabledPets: PetModel[];
  setIndoorModeEnabledPets: Dispatch<SetStateAction<PetModel[]>>;
  continueButtonAction: () => void;
  deviceId?: number;
};

const IndoorModePage = ({
  petList,
  indoorModeEnabledPets,
  setIndoorModeEnabledPets,
  continueButtonAction,
  deviceId,
}: IndoorModePage) => {
  const navigation = useNavigation<NavigationProp<FlowStackParamList>>();
  const { bottom } = useSafeAreaInsets();
  const { t } = useTranslation();

  const toggleIndoorModeEnabledPets =
    (pet: PetModel, indoorModeEnabled: boolean) => async () => {
      if (indoorModeEnabled) {
        setIndoorModeEnabledPets((pets: PetModel[]) =>
          pets.filter(item => item.id !== pet.id)
        );
      } else {
        setIndoorModeEnabledPets((pets: PetModel[]) => [pet, ...pets]);
      }
    };

  return (
    <SpView>
      <SpText
        style={[text.title, styles.titleMargin]}
        {...testProperties('indoor title', 'text')}
      >
        {t('indoor_mode_title')}
      </SpText>
      <SpText
        style={[text.subtitle, styles.subtitleMargin]}
        {...testProperties('indoor subtitle', 'text')}
      >
        {t('indoor_mode_subtitle')}
      </SpText>
      <SpText
        style={[text.subtitle, styles.subtitleMargin]}
        {...testProperties('indoor mode', 'subtitle 2')}
      >
        {t('indoor_mode_subtitle2')}
      </SpText>
      <SpView justifyContent="center" flexDirection="row">
        <SpRoundedHeaderButton
          backgroundColor={colors.greyText.color}
          stylesForContainer={styles.buttonStyles}
          textStyles={styles.buttonText}
          title={t('more_information')}
          onPress={() => openModal('IndoorModeModal')}
          {...testProperties('more info','button')}
        />
      </SpView>
      <FlatList
        showsVerticalScrollIndicator={false}
        data={petList}
        contentContainerStyle={{ paddingBottom: bottom + 300 }}
        renderItem={({ item }) => {
          const indoorModeEnabled = !!indoorModeEnabledPets.find(
            pet => pet.id === item.id
          );
          return (
            <PetItem
              imageUrl={item.photo?.location}
              name={item.name}
              indoorModeEnabled={indoorModeEnabled}
              isIndoorMode
              withAssignedIcon
              handlePress={toggleIndoorModeEnabledPets(item, indoorModeEnabled)}
            />
          );
        }}
        keyExtractor={item => `${item?.id}`}
      />

      <SpModal
        backdropColor="white"
        bottomAreaView={false}
        modalName="IndoorModeModal"
        onRequestClose={() => closeModal('IndoorModeModal')}
        disabledPress={true}
      >
        <SpView alignSelf="flex-start">
          <CustomHeader withCross withTitle={false} goBackFunc={continueButtonAction} />
        </SpView>
        <IndoorModeInfo continueButtonAction={continueButtonAction} />
      </SpModal>
    </SpView>
  );
};

export default IndoorModePage;

const styles = StyleSheet.create({
  titleMargin: {
    marginTop: 24,
    marginBottom: 24,
  },
  subtitleMargin: {
    marginBottom: 24,
  },
  buttonText: {
    fontFamily: 'Rubik_Medium',
    fontSize: 16,
    color: colors.white.color,
  },
  buttonStyles: {
    maxHeight: 180,
    marginBottom: 48,
  },
  buttonWrapper: {
    flex: 1,
  },
  modalContainer: {
    paddingHorizontal: 19,
  },
  reminder: {
    fontFamily: 'Rubik_Medium',
    fontSize: 14,
    color: colors.greyText.color,
    paddingHorizontal: 10,
  },
  buttonContinueContainer: { marginTop: 'auto', marginBottom: 68 },
});
