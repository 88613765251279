import { SpLoading } from '@atoms/SpLoading';
import { useDevicesByHousehold } from '@hooks/useDevicesByHousehold';
import { useGetAllPetReports } from '@hooks/useGetAllPetReports';
import {
  GroupReportPointModel,
  MappedConsumptionReportDataModel,
  MappedMovementReportDataModel,
  MappedPoseidonReportDataModel,
  MappedReportModel,
} from '@models/ReportModel';
import { StackScreenProps } from '@react-navigation/stack';
import colors from '@styles/colors';
import { isWithinInterval } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Linking } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { Env } from '../../../../Env';
import { TimeService } from '../../../services/TimeService';
import useBoundStore from '../../../store/store';
import { PetStackParamList } from '../Pets';
import { PDFReportActions } from '../constants/PDFReport';
import { ReportRangeType } from '../constants/ReportRangeType';
import { ReportType } from '../constants/ReportType';
import { Dashboard } from '../molecules/PetDashboardReport/Dashboard';

export interface ReportWrapperFilter {
  viewedDays: number;
  currentDate: any;
}

export const PetDashboardReportWrapper = ({
  route,
}: StackScreenProps<PetStackParamList, 'PetDashboardReport'>) => {
  const [filter, setFilter] = useState<ReportWrapperFilter>({
    currentDate: TimeService.toLocal().toJSDate(),
    viewedDays: ReportRangeType.SevenDays,
  });
  const [data, setData] = useState<MappedReportModel>(null);
  const [type, setType] = useState<ReportType>(null);
  const household = useBoundStore((s) => s.householdStore.activeHousehold);
  const token = useBoundStore((s) => s.accountStore.token);
  const pet = useBoundStore((state) => state.petStore.activePet);
  const { loadDetailsReport, loading } = useBoundStore((s) => s.reportStore);
  const devices = useDevicesByHousehold();
  const types = useGetAllPetReports(pet) as ReportType[];

  const loadReport = useCallback(
    async (days: number, date: any) => {
      const values = await loadDetailsReport(household?.id, pet.id, days, date);
      setData(values);
    },
    [household?.id, pet.id],
  );

  const onSetFilter = (payload: ReportWrapperFilter) => {
    setData({
      movement: [],
      feeding: [],
      drinking: [],
    });
    setFilter(payload);
    loadReport(payload.viewedDays, payload.currentDate);
  };

  useEffect(() => {
    loadReport(filter.viewedDays, filter.currentDate);
  }, []);

  useEffect(() => {
    if (!type && types.length) {
      setType(route.params?.type || types[0]);
    }
  }, [route.params?.type]);

  const filterData = (
    data: GroupReportPointModel<
      | MappedConsumptionReportDataModel
      | MappedMovementReportDataModel
      | MappedPoseidonReportDataModel
    >[],
    days: number,
  ) => {
    return data?.filter((item) => {
      const itemDate = TimeService.toLocal(item.date).toJSDate();
      const currentDateMinusx = TimeService.toLocal(filter.currentDate)
        .minus({
          days: days * 2 < 14 ? 14 : days * 2,
        })
        .toJSDate();
      const currentDatePlusx = TimeService.toLocal(filter.currentDate).plus({ days: 1 }).toJSDate();
      return isWithinInterval(itemDate, {
        start: currentDateMinusx,
        end: currentDatePlusx,
      });
    });
  };

  const filteredData = useMemo<MappedReportModel>(() => {
    return {
      feeding: filterData(
        data?.feeding ? data?.feeding : [],
        filter.viewedDays,
      ) as GroupReportPointModel<MappedConsumptionReportDataModel>[],
      movement: filterData(
        data?.movement ? data?.movement : [],
        filter.viewedDays,
      ) as GroupReportPointModel<MappedMovementReportDataModel>[],
      drinking: filterData(
        data?.drinking ? data?.drinking : [],
        filter.viewedDays,
      ) as GroupReportPointModel<MappedPoseidonReportDataModel>[],
    };
  }, [data, filter.viewedDays, filter.currentDate]);

  const generateReport = useCallback(() => {
    switch (Env.APP_BUNDLE_ID) {
      case 'com.sureflap.surehub.devstable':
        Linking.openURL(
          `https://reports.devstable.surehub.io/reports/${household?.id}/${pet?.id}/${PDFReportActions[type]}?token=${token}`,
        ).then();
        break;
      default:
        Linking.openURL(
          `https://master.d3cdm3x7bnatip.amplifyapp.com/reports/${household?.id}/${pet?.id}/${PDFReportActions[type]}?token=${token}`,
        ).then();
        break;
    }
  }, [household, token, pet, type]);

  if (!data && (loading || !type)) {
    return <SpLoading />;
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.white.color }}>
      <Dashboard
        pet={pet}
        types={types}
        devices={devices}
        stats={filteredData}
        type={type}
        onChangeType={setType}
        currentDate={filter.currentDate}
        setFilter={onSetFilter}
        viewedDays={filter.viewedDays}
        initialActiveTab={route.params.tab}
        onGenerateReport={generateReport}
      />
    </SafeAreaView>
  );
};
