import SpBadge from '@atoms/RoundIcons/SpBadge';
import { SpButton } from '@atoms/SpButton';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { DeviceType } from '@constants/Device';
import useDeviceStatusHook from '@hooks/useDeviceStatusHook';
import useDeviceStatusParse from '@hooks/useDeviceStatusParse';
import { DeviceModel } from '@models/Device';
import { PetModel, PetWithTagModel } from '@models/Pet';
import { NavigationProp, StackActions, useNavigation } from '@react-navigation/native';
import colors from '@styles/colors';
import text from '@styles/text';
import { getNestedProperty } from '@utils/getNestedProperty';
import { testProperties } from '@utils/testProperties';
import { t } from 'i18next';
import { cloneDeep } from 'lodash-es';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { FlatList, StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import useBoundStore from '../../../store/store';
import { DeviceStackParamList } from '../../Device';
import { RootStackParamList } from '../../index';
import PetItem from '../Molecules/PetItem';
import { FlowStackParamList } from '../index';

interface PetWithTagModelAssigned extends PetWithTagModel {
  isAssigned?: boolean;
}

type PetAssignModel = PetWithTagModelAssigned;

interface IAssignPetProps {
  data: PetAssignModel[];
  title: string;
  subtitle: string;
  saveButtonAction: (data?: any) => void;
  setAssignedPets?: any;
  incompatiblePets?: any[];
  setIncompatiblePets?: any;
  activeIncompatibility?: boolean;
  comingFromSettings?: boolean;
  shouldPassData?: boolean;
  buttonText?: string;
  isAlternativeStyle?: boolean;
  device: DeviceModel;
  isAsync?: boolean;
  isSetupFlow?: boolean;
  setAssignUpdateData?: any;
  onClickOnIncompatiblePet?: (arg1: PetModel) => void;
}

const AssignPet = ({
  data,
  title,
  subtitle,
  saveButtonAction,
  shouldPassData,
  setAssignedPets,
  incompatiblePets,
  setIncompatiblePets,
  activeIncompatibility = true,
  comingFromSettings = false,
  buttonText = t('add_new_pet'),
  isAlternativeStyle = false,
  device,
  onClickOnIncompatiblePet,
  isAsync = true,
  isSetupFlow = false,
  setAssignUpdateData,
}: IAssignPetProps) => {
  const { bottom } = useSafeAreaInsets();
  const [updatedPets, setUpdatedPets] = React.useState<PetAssignModel[]>([]);
  const [petsAmount, setPetsAmount] = React.useState<number>();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const flowNavigation = useNavigation<NavigationProp<FlowStackParamList>>();
  const deviceNavigation = useNavigation<NavigationProp<DeviceStackParamList>>();
  const [showMessage, setShowMessage] = useState(true);

  const loadingControl = useBoundStore((state) => state.deviceStore.loadingControl);
  const mutateTagProfiles = useBoundStore((state) => state.deviceStore.mutateTagProfiles);

  const deviceStatus = useDeviceStatusHook(device.id, isAsync);
  useDeviceStatusParse(isAsync ? deviceStatus : null);

  useEffect(() => {
    if (isAsync && loadingControl?.tag_profiles?.length > 0) {
      loadingControl?.tag_profiles.forEach((item) => {
        mutateTagProfiles(item, device.id, item.action);
      });
    }
  }, [loadingControl]);

  useLayoutEffect(() => {
    setUpdatedPets(cloneDeep(data));
    if (!Object.keys(loadingControl).length) {
      setPetsAmount((prev) => {
        const newAmount = cloneDeep(data).filter((i) => i.isAssigned === true).length;
        return newAmount;
      });
    }
  }, [data]);

  useEffect(() => {
    if (activeIncompatibility) {
      setIncompatiblePets(
        data
          .filter(
            (item) =>
              item.pet.tag?.supported_product_ids?.filter((id: number) => device.product_id === id)
                .length === 0,
          )
          .map((petAssign) => petAssign.pet),
      );
    }
  }, [data]);

  useEffect(() => {
    if (setAssignUpdateData) {
      setAssignUpdateData(updatedPets);
    }
  }, [updatedPets]);

  const incompatiblePet = (pet: any) => {
    flowNavigation.dispatch(
      StackActions.push('FlowNavigation', {
        screen: 'AddPets',
        params: {
          deviceType: device.product_id,
          step: 7,
          petIncompatibleAssociated: pet?.id,
          deviceId: device.id,
          existingDevice: true,
          comingFromSettings,
          comingFromIncompatibleAssignPage: !comingFromSettings,
        },
      }),
    );
    return true;
  };

  const toggleAssignPet = (petModel: PetAssignModel) => {
    const isCerberus = device.product_id === DeviceType.Cerberus;
    const isFeeder = device.product_id === DeviceType.FeederConnect;
    let assignedPetsNum = 0;
    let isAssigned = false;
    let pressedPetIndex = 0;

    updatedPets.forEach((updatedPet, index) => {
      if (updatedPet.isAssigned) {
        assignedPetsNum += 1;
      }
      if (petModel.pet?.id === updatedPet.pet?.id) {
        isAssigned = !!updatedPet.isAssigned;
        pressedPetIndex = index;
      }
    });

    if (!isAssigned && assignedPetsNum >= 1 && isCerberus) {
      return;
    }

    if (!isAssigned && assignedPetsNum >= 1 && isFeeder && showMessage && isAsync) {
      deviceNavigation.navigate('MultiplePet');
      setShowMessage(false);
      return;
    }

    // show dialog with warning message

    setUpdatedPets((oldPets) => {
      oldPets[pressedPetIndex].isAssigned = !isAssigned;
      setPetsAmount(oldPets.filter((i) => i.isAssigned).length);
      if (setAssignedPets) {
        setAssignedPets(oldPets.filter((item) => item.isAssigned));
      }
      return [...oldPets];
    });
  };

  const transformText = useCallback(() => {
    return t('pet_assigned', {
      num_of_pets: petsAmount,
      pets: petsAmount === 1 ? t('pet') : t('pets'),
    });
  }, [petsAmount]);

  const checkIsLoading = useCallback(
    (itemTagId: number) => {
      if (!getNestedProperty(loadingControl, 'tag_profiles')) {
        return false;
      }
      const result =
        loadingControl?.tag_profiles?.length > 0
          ? loadingControl.tag_profiles.find((item) => {
              return item.tag_id === itemTagId;
            })
          : false;
      return !!result;
    },
    [loadingControl],
  );
  return (
    <SpView style={{ height: '100%' }}>
      <SpText
        style={[
          isAlternativeStyle
            ? { ...styles.alternativeText, marginTop: 20 }
            : { ...text.title, ...styles.marginTitle },
        ]}
        align="center">
        {title}
      </SpText>
      <SpText
        style={[
          isAlternativeStyle
            ? styles.alternativeText
            : { ...styles.marginTitle, ...styles.descriptionText },
        ]}
        align="center">
        {subtitle}
      </SpText>
      <SpView style={styles.container}>
        <SpBadge
          textAlign="center"
          width={175}
          color={petsAmount === 0 ? colors.errorRed.color : colors.secondary.color}
          text={transformText()}
        />
      </SpView>
      <ScrollView showsVerticalScrollIndicator={false}>
        <FlatList
          data={updatedPets}
          scroll
          style={{ paddingHorizontal: 10 }}
          contentContainerStyle={isSetupFlow ? { paddingBottom: 20 } : { paddingBottom: 86 }}
          renderItem={({ item }) => {
            const isLoading = isAsync ? checkIsLoading(item.pet.tag_id) : false;
            const isIncompatible = incompatiblePets?.find(
              (incompaPet: any) => incompaPet.id === item.pet.id,
            );

            const { pet } = item;
            return (
              <PetItem
                isDisabled={isAsync ? getNestedProperty(loadingControl, 'tag_profiles') : false}
                isIncompatible={isIncompatible}
                isLoading={isLoading}
                imageUrl={pet?.photo?.location || ''}
                assigned={item.isAssigned}
                name={pet?.name || ''}
                isAlternativeStyle={isAlternativeStyle}
                withAssignedIcon
                handlePress={() =>
                  isIncompatible ? onClickOnIncompatiblePet(pet) : toggleAssignPet(item)
                }
              />
            );
          }}
          keyExtractor={(item) => `${item?.pet?.id}`}
        />
      </ScrollView>
      <SpButton
        title={buttonText}
        style={[
          styles.saveButton,
          styles.primaryButton,
          isSetupFlow
            ? { marginTop: 10, bottom, marginBottom: 150 }
            : { position: 'absolute', marginBottom: 35, bottom, width: '100%' },
        ]}
        color={colors.darkGrey.color}
        onPress={shouldPassData ? () => saveButtonAction(updatedPets) : saveButtonAction}
        {...testProperties('add new pet', 'button')}
      />
    </SpView>
  );
};

export default AssignPet;

const styles = StyleSheet.create({
  primaryButton: {
    borderRadius: 10,
    backgroundColor: colors.darkGrey.color,
    paddingHorizontal: 19,
    paddingVertical: 2,
  },
  marginSubtitle: {
    marginTop: 24,
    marginBottom: 24,
  },
  marginTitle: {
    marginTop: 24,
  },
  alternativeText: {
    fontFamily: 'Rubik',
    fontSize: 16,
    color: colors.black.color,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 24,
    marginTop: 40,
  },
  descriptionText: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: 'normal',
  },
  saveButton: {
    marginTop: 20,
  },
});
