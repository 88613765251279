import colors from '@styles/colors';
import i18n from '@utils/i18n';
import { max } from 'lodash-es';
import { useMemo } from 'react';
import { Dimensions } from 'react-native';

import { TimeService } from '../../../services/TimeService';
import { ReportRangeType } from '../constants/ReportRangeType';

const ReportRangeXTickType = {
  [ReportRangeType.OneDay]: i18n.t('HH'),
  [ReportRangeType.SevenDays]: i18n.t('EEE'),
  [ReportRangeType.TwentyEightDays]: i18n.t('d'),
  [ReportRangeType.SixMonths]: i18n.t('MMM'),
  [ReportRangeType.OneYear]: i18n.t('MMMMM'),
};

const ReportRangeBarGap = {
  [ReportRangeType.OneDay]: 40,
  [ReportRangeType.SevenDays]: 70,
  [ReportRangeType.TwentyEightDays]: 30,
  [ReportRangeType.SixMonths]: 66,
  [ReportRangeType.OneYear]: 40,
};

const height = Dimensions.get('window').height;

export function useSummaryReportGraphData(
  width: number,
  rangeType: ReportRangeType,
  x: number[],
  y: number[],
  heightInPercent: number,
  margin = { top: 108, right: 12, bottom: 28, left: 12 },
) {
  const currentHeight = height * heightInPercent;
  const chartWidth = width - margin.left - margin.right;
  const chartHeight = currentHeight - margin.top - margin.bottom;
  const maxYValue = max(y);
  const barGap = Math.round((chartWidth / x.length / 100) * (ReportRangeBarGap[rangeType] || 0));

  const barWidth = (chartWidth - (x.length - 1) * barGap) / x.length;
  const yAxisLabels = [Math.round(0.5 * maxYValue), Math.round(maxYValue)].filter(Boolean);

  const xAxisLabels = useMemo(() => {
    const formattedDates = x.map(
      (item) => TimeService.toLocal(item).toFormat(ReportRangeXTickType[rangeType]),
      // format(item, ReportRangeXTickType[rangeType]),
    );

    if (rangeType === ReportRangeType.OneDay) {
      const range = [
        formattedDates[0],
        formattedDates[6],
        formattedDates[12],
        formattedDates[18],
        formattedDates[23],
      ];

      return formattedDates.map((item) => {
        return range.includes(item) ? item : '';
      });
    }

    if (rangeType === ReportRangeType.TwentyEightDays) {
      const days = formattedDates.map(Number);
      const maxDay = days.length - 1;
      const range = [
        days[0],
        days[Math.round(0.25 * maxDay)],
        days[Math.round(0.5 * maxDay)],
        days[Math.round(0.75 * maxDay)],
        days[maxDay],
      ];

      return formattedDates.map((item) => {
        return range.includes(Number(item)) ? item.toString() : '';
      });
    }

    return formattedDates;
  }, [rangeType, x]);

  return {
    xAxisLabels,
    yAxisLabels,
    chartWidth,
    chartHeight,
    margin,
    height: currentHeight,
    barWidth,
    barGap,
    barRadius: (barWidth / 100) * 46,
    xAxisLabelStyle: {
      offsetTop: 24,
      fontSize: 14,
      color: colors.greySmallTitle.color,
    },
    yAxisLabelStyle: {
      offsetLeft: -8,
      fontSize: 14,
      color: colors.greyText.color,
    },
  };
}
