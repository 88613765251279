import { useDeviceById } from '@hooks/useDeviceById';
import { usePetsByHousehold } from '@hooks/usePetsByHousehold';
import { usePetsWithTag } from '@hooks/usePetsWithTag';
import { PetModel } from '@models/Pet';
import { StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import useBoundStore from 'src/store/store';

import { DeviceStackParamList } from './index';
import { NavigationList } from './molecules/DeviceSetting/NavigationList';

export const DeviceSetting = ({
  route,
}: StackScreenProps<DeviceStackParamList, 'DeviceSetting'>) => {
  const { loadingControl, loadDevice } = useBoundStore((s) => s.deviceStore);
  const { getPetByTagId } = useBoundStore((s) => s.petStore);
  const { id } = route.params;
  const device = useDeviceById(id);
  const pets = usePetsByHousehold();
  const petsWithTag = usePetsWithTag(device, pets);
  const getAssignedPets = useCallback(() => {
    return petsWithTag.filter((item) => item.isAssigned).map((item) => item.pet);
  }, [petsWithTag]);
  const [petForRender, setPetsForRender] = useState<PetModel[]>(getAssignedPets);

  useLayoutEffect(() => {
    console.log('Refresh pets to Render')
    const assignedPetsTagIDs = getAssignedPets().map((pet) => pet.tag_id);
    const assumedPendingTags = (loadingControl['tag_profiles'] ?? []).filter(
      (tag) => !assignedPetsTagIDs.includes(tag.tag_id),
    );
    const assumedPets = getAssignedPets();
    assumedPendingTags.forEach((tag) => {
      if (!tag.tag_id) return;
      assumedPets.push(getPetByTagId(tag.tag_id));
    });
    setPetsForRender(assumedPets);
  }, [loadingControl['tag_profiles'], getAssignedPets]);

  return <NavigationList device={device} pets={petForRender} />;
};
