import { SpRoundIcon } from '@atoms/RoundIcons/SpRoundIcon';
import { SpProfileImage } from '@atoms/SpProfileImage';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import { DefaultHitSlop } from '@constants/DefaultHitSlop';
import {
  faAngleLeft,
  faCalendarDays,
  faEllipsis,
  faExchange,
  faExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { PetModel } from '@models/Pet';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import {
  Alert,
  ImageBackground,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { openModal } from 'src/services/ModalBox';

import { ReportName, ReportType } from '../constants/ReportType';

interface PetsHeaderProps {
  activePet: PetModel;
  name: string;
  navigation: any;
  label?: ReportType;
  children: React.ReactNode;
  openPetsBottomSheet?: () => void;
}

export const PetsHeader = ({
  name,
  activePet,
  navigation,
  label = null,
  children,
  openPetsBottomSheet,
}: PetsHeaderProps) => {
  const data = (
    <>
      <LinearGradient
        style={styles.gradientBlur}
        colors={['rgba(255,255,255,0.2)', 'rgba(255,255,255,1)']}
      />

      {name === 'PetDashboardReport' && label && (
        <Text style={styles.headerLabel}>{ReportName[label]}</Text>
      )}

      <SpView style={{ height: Platform.OS === 'ios' ? 44 : 20 }} />

      <View style={styles.headerIconsWrapper}>
        <TouchableOpacity
          {...testProperties('icon', 'BackButton')}
          hitSlop={DefaultHitSlop}
          onPress={() => {
            navigation.goBack();
          }}>
          <FontAwesomeIcon color={colors.greyText.color} size={34} icon={faAngleLeft} />
        </TouchableOpacity>

        {name === 'PetDashboardSummary' && (
          <SpView style={styles.rightIcons}>
            <TouchableOpacity
              hitSlop={DefaultHitSlop}
              onPress={() => openModal('PetDashboardSummaryCalendar')}>
              <FontAwesomeIcon color={colors.greyText.color} size={24} icon={faCalendarDays} />
            </TouchableOpacity>
            <Pressable
              {...testProperties('icon', 'EllipsisButton')}
              hitSlop={DefaultHitSlop}
              onPress={() => openModal('PetOptions')}>
              <FontAwesomeIcon color={colors.greyText.color} size={28} icon={faEllipsis} />
            </Pressable>
          </SpView>
        )}
        {name === 'PetDashboardReport' && (
          <View style={styles.rightIcons}>
            <SpRoundIcon
              size={9}
              componentName="LineGraph"
              icon={<FontAwesomeIcon color="white" size={20} icon={faExclamation} />}
              action={() => {
                openModal('LineGraph');
              }}
            />
            <SpRoundIcon
              size={9}
              componentName="exchange"
              icon={<FontAwesomeIcon color="white" size={20} icon={faExchange} />}
              action={() => {
                Alert.alert('exchange');
              }}
            />
          </View>
        )}
      </View>
      <TouchableOpacity onPress={openPetsBottomSheet} style={styles.petInfo}>
        <SpProfileImage
          width={68}
          height={68}
          imageUrl={activePet && activePet.photo?.location}
          withBorder
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={openPetsBottomSheet}>
        <SpText size="xl" style={{ marginTop: 2 }} fontFamily="Rubik_Medium" numberOfLines={1}>
          {activePet && activePet.name}
        </SpText>
      </TouchableOpacity>
      <SpView style={styles.content}>{children}</SpView>
    </>
  );

  return activePet?.photo?.location ? (
    <ImageBackground
      style={styles.headerContainer}
      testID="PetDashboardHeader"
      source={{ uri: activePet?.photo?.location || '' }}>
      {data}
    </ImageBackground>
  ) : (
    <SpView style={styles.headerContainer} testID="PetDashboardHeader">
      {data}
    </SpView>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    width: '100%',
    height: 254, // 180 + SpView height from Dashboard
    alignItems: 'center',
  },
  headerLabel: {
    marginLeft: 'auto',
    fontWeight: 'bold',
    color: colors.placeholderGrey.color,
    fontSize: 18,
    textAlign: 'center',
    width: '100%',
  },
  headerIconsWrapper: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 19,
    marginTop: 16,
  },
  backButton: {
    flex: 1,
    height: '100%',
  },
  rightIcons: {
    flexDirection: 'row',
    gap: 27,
  },
  activePetText: {
    fontSize: 16,
    paddingBottom: 5,
    textAlign: 'center',
    marginTop: 5,
    paddingHorizontal: 10,
    gap: 19,
  },
  center: {
    alignItems: 'center',
  },
  gradientBlur: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  petInfo: {
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: -32,
  },
  content: {
    width: '100%',
    backgroundColor: 'transparent',
    position: 'absolute',
    bottom: 0,
  },
});
