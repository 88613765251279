import CustomBottomSheetModal from '@atoms/CustomBottomSheetModal';
import { SpView } from '@atoms/SpView';
import { DeviceFeederBowlType } from '@constants/Device';
import { BottomSheetBackdrop } from '@gorhom/bottom-sheet';
import { DeviceFeederControlModel, DeviceFeederTypeUpdated, DeviceModel } from '@models/Device';
import React, { useMemo, useState } from 'react';
import { Dimensions, Platform, StyleSheet } from 'react-native';

import { BowlDual } from './BowlDual';
import { BowlSingle } from './BowlSingle';
import { FeederFooterActions } from './FeederFooterActions';
import { FeederInfo } from './FeederInfo';
import { ZeroScales } from './ZeroScales';
import { FeederCardModal } from '../../constants/FeederCard';
import { getFillPercentage } from '../../utils/getFillingPercentage';
import { DeviceBottomSheetContainer } from '../DeviceBottomSheetContainer';
import { DeviceSettingMenuItem } from '../DeviceSettingMenuItem';
import { DeviceStatusHeader } from '../DeviceStatusHeader';

interface FeederCardProps {
  data: DeviceModel;
  userHasWriteAccess: boolean;
}

export const FeederCard = ({ data, userHasWriteAccess }: FeederCardProps) => {
  const [modal, setModal] = useState<FeederCardModal>(null);
  const snapPoints = useMemo(() => ['40%'], []);
  const control = useMemo(() => data?.control as DeviceFeederControlModel, [data]);
  const online = data?.status.online;

  const percentages = getFillPercentage(data as any, true);
  const screenHeight = Dimensions.get('window').height;

  return (
    <>
      <SpView flex={1}>
        <DeviceStatusHeader data={data} />

        {online && (
          <>
            {userHasWriteAccess && <DeviceSettingMenuItem data={data?.id} />}
            <SpView marginTop={screenHeight < 810 ? 24 : 36}>
              {control.bowls?.type === DeviceFeederBowlType.Single ? (
                <BowlSingle data={control.bowls} percentage={percentages[0]} />
              ) : (
                <BowlDual data={control.bowls} percentages={percentages} />
              )}
            </SpView>
            <SpView style={styles.divider} marginTop={screenHeight < 810 ? 4 : 24} />
            <FeederInfo
              events={data.status as unknown as DeviceFeederTypeUpdated}
              trainingMode={control?.training_mode}
            />
            <SpView style={styles.divider} marginTop={screenHeight < 810 ? 4 : 24} />
            {userHasWriteAccess && online && (
              <SpView
                style={[styles.footerActions, Platform.OS === 'android' && { marginTop: -40 }]}>
                <FeederFooterActions onAction={setModal} />
              </SpView>
            )}
          </>
        )}
      </SpView>
      {modal === FeederCardModal.BowlType && (
        <CustomBottomSheetModal
          opened
          backdropComponent={BottomSheetBackdrop}
          index={0}
          snapPoints={snapPoints}
          onDismiss={() => setModal(null)}
          inScrollView>
          <DeviceBottomSheetContainer>
            <ZeroScales
              deviceId={data.id}
              type={control?.bowls?.type}
              events={data.status as unknown as DeviceFeederTypeUpdated}
            />
          </DeviceBottomSheetContainer>
        </CustomBottomSheetModal>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  divider: {
    height: 1,
    backgroundColor: 'rgba(38, 58, 67, 0.15)',
  },
  footerActions: {
    flexGrow: 1,
    justifyContent: 'center',
  },
});
