import { FeederFoodType } from '@constants/Device';
import { DeviceModel } from '@models/Device';
import { PetModel } from '@models/Pet';
import {
  ConsumptionReportDataModel,
  GroupReportPointModel,
  MappedConsumptionReportDataModel,
  MappedConsumptionStats,
  MappedPoseidonReportDataModel,
  PoseidonReportDataModel,
} from '@models/ReportModel';
import { getConsumptionStatsByRange } from '@utils/report';
import { round } from 'lodash-es';
import { useMemo } from 'react';

import { useGroupedRangeReportStats } from './useGroupedRangeReportStats';
import { TimeService } from '../../../services/TimeService';
import { ReportRangeType } from '../constants/ReportRangeType';
import { ReportType } from '../constants/ReportType';

interface GroupEventModel {
  duration: number;
  wetWeight: number;
  dryWeight: number;
  bothWeight?: number;
  weight: number;
  count: number;
}

interface GroupedMovementReportStatsResult {
  consumptionData: any[];
  consumptionAverage: number;
}

const groupEventsByHours = (
  points: ConsumptionReportDataModel[] | PoseidonReportDataModel[],
): Record<number, GroupEventModel[]> => {
  const countEventPerHour: Record<number, number> = {};

  const group = (
    start: number,
    end: number,
    sharedData: Record<number, unknown[]>,
    eventId: number,
    event: ConsumptionReportDataModel | PoseidonReportDataModel,
  ): Record<number, unknown[]> => {
    const diff = TimeService.toLocal(end).diff(TimeService.toLocal(start)).as('seconds');
    const uniqDate = TimeService.toLocal(start).startOf('hour').toMillis();
    // @ts-ignore
    const { bothWeight, wetWeight, dryWeight, weight } = event.weights.reduce(
      // @ts-ignore
      (acc, item: any) => {
        if (item.food_type_id === FeederFoodType.wet) {
          acc.wetWeight += Math.abs(item.change) || 0;
        }

        if (item.food_type_id === FeederFoodType.dry) {
          acc.dryWeight += Math.abs(item.change) || 0;
        }

        if (item.food_type_id === FeederFoodType.both) {
          acc.bothWeight += Math.abs(item.change) || 0;
        }

        acc.weight += Math.abs(item.change) || 0;

        return acc;
      },
      { bothWeight: 0, wetWeight: 0, dryWeight: 0, weight: 0 },
    );

    countEventPerHour[eventId] = (countEventPerHour[eventId] || 0) + 1;

    if (diff > 3599) {
      return group(
        TimeService.toLocal(start).plus({ hours: 1 }).startOf('hour').toMillis(),
        end,
        {
          ...sharedData,
          [uniqDate]: [
            {
              duration: TimeService.toLocal(start)
                .endOf('hour')
                .diff(TimeService.toLocal(start))
                .as('seconds'),
              bothWeight,
              wetWeight,
              dryWeight,
              weight,
              eventId,
            },
          ],
        },
        eventId,
        event,
      );
    }

    const newPart = {
      duration: diff,
      bothWeight,
      wetWeight,
      dryWeight,
      weight,
      eventId,
    };

    if (sharedData[uniqDate]) {
      sharedData[uniqDate] = [...sharedData[uniqDate], newPart];
    } else {
      sharedData[uniqDate] = [newPart];
    }

    return sharedData;
  };

  // @ts-ignore
  const groupedEvents = points.reduce((acc, item, index) => {
    return group(
      TimeService.toLocal(item.from).toMillis(),
      TimeService.toLocal(item.to).toMillis(),
      acc,
      index,
      item,
    );
  }, {});

  return Object.keys(groupedEvents).reduce((acc, hour) => {
    // @ts-ignore
    acc[hour] = groupedEvents[hour].map((item) => ({
      duration: item.duration,
      bothWeight: item.bothWeight,
      wetWeight: item.wetWeight,
      dryWeight: item.dryWeight,
      weight: item.weight,
      count: countEventPerHour[item.eventId],
    }));
    return acc;
  }, []);
};

const mapResultToAverageConsumptionReport = (data): GroupedMovementReportStatsResult => {
  const res = Array.isArray(data) ? data : data?.consumptionData || [];

  if (!res || !Array.isArray(res)) {
    return {
      consumptionAverage: 0,
      consumptionData: [],
    };
  }
  const movementDataSum = (res || []).reduce((acc, curr) => {
    acc += curr?.totalWeight || 0;
    return acc;
  }, 0);

  return {
    consumptionData: res,
    consumptionAverage:
      movementDataSum / (res || []).filter((item) => !!item.totalWeight).length || 0,
  };
};

export function useGroupedConsumptionReportStats(
  data: GroupReportPointModel<MappedConsumptionReportDataModel | MappedPoseidonReportDataModel>[],
  pet: PetModel,
  devices: DeviceModel[],
  date: number,
  range: ReportRangeType,
  type: ReportType,
): GroupedMovementReportStatsResult {
  const calculatedRange = useGroupedRangeReportStats(date, range);

  const calculateGroupedConsumptionReportStats = () => {
    if (range === ReportRangeType.OneDay) {
      const points = data.map((item) => item.points).flat();
      const startOfDate = TimeService.toLocal(calculatedRange.start).startOf('day');
      // @ts-ignore
      const groupedEvents = groupEventsByHours(points);
      const oneDayEvents = Object.keys(groupedEvents).reduce((acc, item) => {
        if (
          TimeService.toLocal(Number(item)).startOf('day').toMillis() === startOfDate.toMillis()
        ) {
          // @ts-ignore
          acc[Number(item)] = groupedEvents[item];
        }

        return acc;
      }, {});

      const res = new Array(24).fill(true).map((_, index) => {
        const currentDate = startOfDate.plus({ hours: index }).toMillis();

        let totalDuration = 0;
        let totalWeight = 0;
        let totalBothWeight = 0;
        let totalDryWeight = 0;
        let totalWetWeight = 0;

        // @ts-ignore
        const events: GroupEventModel[] = oneDayEvents[currentDate] || [];
        events.forEach((item: GroupEventModel) => {
          totalDuration += item.duration;
          totalWeight += item.weight / item.count || 0;
          totalDryWeight += item.dryWeight / item.count || 0;
          totalWetWeight += item.wetWeight / item.count || 0;
          totalBothWeight += item.bothWeight / item.count || 0;
        });
        return {
          device: null,
          bowls: null,
          lastTopupAt: null,
          lastConsumptionAt: null,
          averageActivity: events.length,
          averageDuration: round(totalDuration),
          totalActivity: events.length,
          totalDuration: round(totalDuration),
          totalWeight: round(totalWeight),
          date: currentDate,
          totalDryWeight: round(totalDryWeight),
          totalWetWeight: round(totalWetWeight),
          totalBothWeight: round(totalBothWeight),
        };
      });

      return mapResultToAverageConsumptionReport(res);
    }
    const res = getConsumptionStatsByRange(
      data,
      pet?.tag_id,
      devices,
      calculatedRange.start,
      calculatedRange.finish,
      true,
    );

    if (range === ReportRangeType.OneYear || range === ReportRangeType.SixMonths) {
      const values = Object.values(
        res.reduce((acc: Record<number, MappedConsumptionStats>, item) => {
          const month = TimeService.toLocal(item.date).endOf('month').toMillis();

          if (!acc[month]) {
            acc[month] = {
              ...item,
              date: month,
            };
          }

          acc[month].totalWeight += item.totalWeight;
          acc[month].totalActivity += item.totalActivity;
          acc[month].totalDuration += item.totalDuration;
          acc[month].totalBothWeight += item.totalBothWeight;
          acc[month].totalWetWeight += item.totalWetWeight;
          acc[month].totalDryWeight += item.totalDryWeight;

          return acc;
        }, {}),
      );

      return mapResultToAverageConsumptionReport(values);
    }

    return res;
  };

  return useMemo(() => {
    return mapResultToAverageConsumptionReport(calculateGroupedConsumptionReportStats());
  }, [pet, devices, data, calculatedRange]);
}
