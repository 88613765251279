import { SpView } from '@atoms/SpView';
import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { DeviceModel } from '@models/Device';
import { PetModel } from '@models/Pet';
import { MappedReportModel } from '@models/ReportModel';
import colors from '@styles/colors';
import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import DropShadow from 'react-native-drop-shadow';
import AveragePetStatusService from 'src/services/AveragePetStatusService';

import { PetDashboardDrinking } from './PetDashboardDrinking';
import { PetDashboardFeeding } from './PetDashboardFeeding';
import { PetDashboardMovement } from './PetDashboardMovement';
import { ReportHeader } from './ReportHeader';
import { SummaryTab } from './SummaryTab';
import { TabsWrap } from './TabsWrap';
import { TimeService } from '../../../../services/TimeService';
import { ReportWrapperFilter } from '../../components/PetDashboardReportWrapper';
import { ReportRangeType } from '../../constants/ReportRangeType';
import { ReportType } from '../../constants/ReportType';
import { ReportTabs } from '../../hooks/useReportTabs';

interface PetDashboardSummaryProps {
  stats: MappedReportModel;
  devices: DeviceModel[];
  pet: PetModel;
  type: ReportType;
  types: ReportType[];
  onChangeType: (data: ReportType) => void;
  currentDate: Date;
  viewedDays: ReportRangeType;
  initialActiveTab: ReportTabs;
  setFilter: (payload: ReportWrapperFilter) => void;
  onGenerateReport: () => void;
}

const averagePetStatusService = new AveragePetStatusService();

export const Dashboard = ({
  stats,
  devices,
  pet,
  type,
  types,
  onChangeType,
  currentDate,
  setFilter,
  viewedDays,
  initialActiveTab,
  onGenerateReport,
}: PetDashboardSummaryProps) => {
  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { getShortUnit } = useWeightConversion(userWeightUnits, userCountryCode);
  const onSetActiveTab = useCallback((tab: ReportTabs) => {
    changeViewedDate(TimeService.toLocal().endOf('day').toMillis());
    setActiveTab(tab);
  }, []);

  const isMovement = type === ReportType.Movement;
  const changeViewedDate = (date: number) => {
    setFilter({ currentDate: +date, viewedDays });
  };

  const changeViewedDaysValue = useCallback((value: ReportRangeType) => {
    setFilter({ currentDate: TimeService.toLocal().toMillis(), viewedDays: value });
  }, []);

  const intervals = useMemo(
    () =>
      averagePetStatusService.getDateIntervals(
        viewedDays,
        TimeService.toLocal(currentDate).toJSDate(),
      ),
    [viewedDays, currentDate],
  );

  const dataType = useMemo(() => {
    if (type === ReportType.Drinking) {
      return getShortUnit(false);
    }
    if (type === ReportType.Feeding) {
      return getShortUnit(true);
    }
    return '';
  }, [type, getShortUnit]);

  return (
    <SpView testID="PetDashboardSummary" style={styles.container}>
      <ReportHeader
        types={types}
        activePet={pet}
        type={type}
        onChangeType={onChangeType}
        onGenerateReport={onGenerateReport}
      />
      <DropShadow>
        <TabsWrap activeTab={activeTab} onChange={onSetActiveTab} type={type} />
      </DropShadow>

      {activeTab === ReportTabs.Summary && (
        // Bar Chart
        <SummaryTab
          devices={devices}
          stats={stats}
          type={type}
          pet={pet}
          intervals={intervals}
          dataType={dataType}
          setCurrentDate={changeViewedDate}
          rangeType={viewedDays}
          currentDate={currentDate}
          setRangeType={changeViewedDaysValue}
        />
      )}
      {/* TODO Vitalii Bazyvoliak Reuse it or remove */}
      {activeTab === ReportTabs.Detail && type === ReportType.Drinking && (
        <PetDashboardDrinking
          pet={pet}
          devices={devices}
          data={stats?.drinking || []}
          onGeneratePDFReport={null}
          setViewedActiveDate={changeViewedDate}
          setViewedReportDays={changeViewedDaysValue}
          rangeType={viewedDays}
        />
      )}
      {activeTab === ReportTabs.Detail && type === ReportType.Feeding && (
        <PetDashboardFeeding
          pet={pet}
          devices={devices}
          data={stats?.feeding || []}
          setViewedActiveDate={changeViewedDate}
          setViewedReportDays={changeViewedDaysValue}
          rangeType={viewedDays}
        />
      )}

      {activeTab === ReportTabs.Detail && isMovement && (
        <PetDashboardMovement
          data={stats?.movement || []}
          onGeneratePDFReport={null}
          setViewedActiveDate={changeViewedDate}
          setViewedReportDays={changeViewedDaysValue}
          rangeType={viewedDays}
        />
      )}
      {/* TODO Vitalii Bazyvoliak Reuse it or remove */}
    </SpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    backgroundColor: colors.white.color,
  },
  content: {
    flex: 1,
    backgroundColor: colors.white.color,
  },
  cardShadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: -20,
    },
    shadowOpacity: 0.05,
    shadowRadius: 8,
  },
});
