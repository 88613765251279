import { SpProfileImage } from '@atoms/SpProfileImage';
import { SpText } from '@atoms/SpText';
import { SpView } from '@atoms/SpView';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import useBoundStore from 'src/store/store';

interface PetsPreviewProps {
  data: { id: number; image: string; name: string }[];
}

export const PetsPreview = ({ data }: PetsPreviewProps) => {
  const pets = useMemo(() => {
    return [...data].splice(0, 4);
  }, [data]);
  // Device store: getDeviceById ===> get pets to render
  // Device store:  const deviceData = await DeviceApi.getDevices(get().householdStore.activeHousehold?.id);

  // TODO in the future we should not mutate the device tags, we should maintain a separate list of pending tags and modify the array
  //  at the place its displayed then reload the device when the pending tags length decreases

  const loadingControl = useBoundStore((state) => state.deviceStore.loadingControl);

  const morePetsBlock = useMemo(() => {
    if (data.length > 4) {
      return (
        <SpView marginLeft={10}>
          <SpText style={styles.text}>+{data.length - 4}</SpText>
        </SpView>
      );
    }

    return null;
  }, [data]);

  return (
    <SpView style={styles.card}>
      {pets.map((item, index: number) => {
        return (
          <SpView
            {...testProperties(item.name, 'PetImage')}
            style={{
              ...styles.image,
              marginLeft: index === 0 ? 0 : 8,
            }}
            key={item.id}>
            {loadingControl.tag_profiles ? (
              <SpView
                style={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  borderRadius: 24,
                  backgroundColor: 'grey',
                  zIndex: 10,
                  opacity: 0.8,
                }}
              />
            ) : null}
            <SpProfileImage imageUrl={item.image} width={48} height={48} />
          </SpView>
        );
      })}
      {morePetsBlock}
    </SpView>
  );
};

const styles = StyleSheet.create({
  card: {
    width: '100%',
    minWidth: '100%',
    overflow: 'hidden',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Rubik_Medium',
    fontSize: 16,
    color: colors.greyText.color,
  },
  image: {
    minWidth: 48,
    minHeight: 48,
    borderRadius: 24,
  },
});
