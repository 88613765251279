import {
  GroupReportPointModel,
  MappedDoorStats,
  MappedMovementReportDataModel,
  PetGroupedStatistic,
} from '@models/ReportModel';
import { getDateRangeOfMonth, getDateRangeOfWeek } from '@utils/date';
import { getDoorStats, getDoorStatsByRange } from '@utils/report';
import { endOfDay, subDays, subMonths } from 'date-fns';
import { useMemo } from 'react';

export function useGroupedDoorStats(
  data: GroupReportPointModel<MappedMovementReportDataModel>[],
  date: number,
): PetGroupedStatistic<MappedDoorStats> {
  return useMemo(() => {
    if (!date) {
      return null;
    }

    const current = getDateRangeOfWeek(date);
    const previous = getDateRangeOfWeek(subDays(current.start, 1).getTime());
    const currentMonth = getDateRangeOfMonth(date);
    const previousMonth = getDateRangeOfWeek(subMonths(currentMonth.start, 1).getTime());
    const events = data.find((item) => item.date === endOfDay(date).getTime());

    return {
      currentDay: getDoorStats(events ? [events] : [], date),
      currentWeek: getDoorStatsByRange(data, current.start, current.end),
      currentWeekRange: current,
      previousWeek: getDoorStatsByRange(data, previous.start, previous.end),
      previousWeekRange: previous,
      currentMonth: getDoorStatsByRange(data, currentMonth.start, currentMonth.end),
      currentMonthRange: currentMonth,
      previousMonth: getDoorStatsByRange(data, previousMonth.start, previousMonth.end),
      previousMonthRange: previousMonth,
    };
  }, [data, date]);
}
