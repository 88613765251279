import { DeviceStatusIDs } from '@constants/Device';
import { DeviceLoadingControl, DevicePendingStatusModel } from '@models/Device';
import { cloneDeep } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

import useBoundStore from '../store/store';


const useDeviceStatusParse = (deviceStatus: DevicePendingStatusModel[]) => {
  const { setLoadingControl, loadingControl } = useBoundStore((state) => state.deviceStore);
  const [prevState, setPrevState] = useState<DeviceLoadingControl>(cloneDeep(loadingControl));
  const isEmpty = useCallback((obj) => !!Object.keys(obj).length, []);
  useEffect(() => {
    if (!deviceStatus || !Array.isArray(deviceStatus)) {
      return;
    }
    if (isEmpty(loadingControl) && !isEmpty(prevState)) setLoadingControl(prevState);
    setPrevState(cloneDeep(loadingControl));
    // Get ids of requests that already handled by server
    const requestsID = deviceStatus.map((res) => res.request_id);
    console.log('[DEBUG][useDeviceStatusParse]: dataStatus requestsIds', requestsID);
    const stateObject = {};

    console.log('loadingControl',loadingControl);

    //Parse loaderControl for Items that needs to wait until server handles them;
    const justSandedPending: DeviceLoadingControl = {};
    Object.keys(loadingControl).forEach((key) => {
      if (!loadingControl[key]) return;
      // by default there should be an object or array, hope no one will pass anything else
      //interface DeviceLoadingControl {
      //   bowls?: DeviceFeederBowlModel & { justSanded?: boolean; request_id?: string };
      //   lid?: DeviceFeederLidModel & { justSanded?: boolean; request_id?: string };
      //   tare?: { justSanded?: boolean; request_id?: string; value: DeviceFeederTareType };
      //   training_mode?: { justSanded?: boolean; request_id?: string; value: boolean };
      //   close_delay?: { justSanded?: boolean; request_id?: string; value: boolean };
      //   led_mode?: { justSanded?: boolean; request_id?: string; value: number };
      //   locking?: { justSanded?: boolean; request_id?: string; value: number };
      //   tag_profiles?: any[]; //[{...itemData, justSanded?: boolean; request_id?: string;}]
      //   curfew?: any[]; //[{...itemData, justSanded?: boolean; request_id?: string;}]
      // }
      if (Array.isArray(loadingControl[key])) {
        //There we need to check if some of the items just requested from server, or it's waiting while server handle them
        const unhandledItems = loadingControl[key].filter((item) => item.justSanded || (item.request_id && !requestsID.includes(item.request_id)));
        if (!unhandledItems.length) return;
        justSandedPending[key] = unhandledItems;
      } else {
        //There we need to check if item just requested from server, or it's waiting while server handle him
        const isUnhandled = loadingControl[key].justSanded || (!!loadingControl[key].request_id && !requestsID.includes(loadingControl[key].request_id));
        if (!isUnhandled) return;
        justSandedPending[key] = { ...loadingControl[key] };
      }
    });
    console.log('[DEBUG][useDeviceStatusParse]: justSandedPending', justSandedPending);
    // We got justSandedPending object with unhandled items there, latter we need to pass it to loadingControl;
    // Assume that unhandled items have higher priority then handled

    // Parsing status response from server for Pending statuses
    // There seems to be a problem with server response since sometimes we got empty or undefined state object,
    // so we don't know which data should be passed to loadingControl, for now skipping
    // TODO check why backend responds with incorrect state for item
    const pendingDeviceStatus = deviceStatus.filter(
      (item) => item.status === DeviceStatusIDs.Pending && !!item.state,
    );
    console.log('[DEBUG][useDeviceStatusParse]: pendingDeviceStatus', pendingDeviceStatus);
    // Case in which we should clear loaderControl seems to be working fine for now
    if (pendingDeviceStatus.length === 0 && !Object.keys(justSandedPending).length) {
      console.log('[RESET] loadingControl');
      setLoadingControl({});
      return;
    }

    //Start of magic and root of all problems
    try {
      //Firstly fill loaderControll with data received within status check for device;
      //Also ensure that there state in loadingControl can be an Array for tag_profiles
      // and curfew for bowls and lid it's extended model for other primitives values replaces with object with field value
      // for some reason curfew status from backend differs it can be an object for one curfew or array for multiple
      // to simplify logic and remove potential bugs within loadingControl curfew always an array (array with one item or multiple)
      pendingDeviceStatus.forEach((item) => {
        Object.keys(item.state).forEach((key : keyof DeviceLoadingControl) => {
          switch (key) {
            case 'tag_profiles':
              stateObject[key] = [...(stateObject[key] ?? []), ...item.state[key]];
              break;
            case 'curfew':
              stateObject[key] = [...(stateObject[key] ?? []), ...(Array.isArray(item.state[key]) ? item.state[key] : [item.state[key]])];
              break;
            case 'bowls':
            case 'lid':
              stateObject[key] = { ...(stateObject[key] ?? {}), ...item.state[key] };
              break;
            default:
              stateObject[key] = { value: item.state[key] };
          }
        });
      });
      console.log('[DEBUG][useDeviceStatusParse]: stateObject[pendingDeviceStatus]', stateObject);
      // Next we need to add to loadingControl data for items that unhandled by server in time
      // For now these items has higher priority (not sure if this can lead to unexpected behaviour)
      // TODO check if higher priority of unhandled items can lead to unexpected behaviour
      Object.keys(justSandedPending).forEach((key) => {
        switch (key) {
          case 'tag_profiles':
          case 'curfew':
            stateObject[key] = [...(stateObject[key] ?? []), ...justSandedPending[key]];
            break;
          case 'bowls':
          case 'lid':
            stateObject[key] = { ...(stateObject[key] ?? {}), ...justSandedPending[key] };
            break;
          default:
            stateObject[key] = { ...justSandedPending[key] };
        }
      });
      console.log('[DEBUG][useDeviceStatusParse]: stateObject[pendingDeviceStatus+justSandedPending]', stateObject);
      setLoadingControl(stateObject);

      return () => {
        console.log('[RESET] loadingControl');
        setLoadingControl({});
      };
    } catch (e) {
      console.log('[ERROR][useDeviceStatusParse]: Device status undefined', e);
    }
    return () => {
      console.log('[RESET] loadingControl');
      setLoadingControl({});
    };
  }, [deviceStatus]);
};

export default useDeviceStatusParse;
