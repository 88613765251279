import { useTimeTransform } from '@hooks/useTimeTransform';
import { PetStatisticsModel } from '@models/Device';
import { getSingleDuration } from '@utils/date';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import React, { useMemo } from 'react';
import useBoundStore from 'src/store/store';

import PetInfoSection from './PetInfoSection';

type PetLastActionTime = {
  icon: any;
  data: PetStatisticsModel['drinking'] | PetStatisticsModel['feeding'];
  title: string;
  withTimeMarker?: boolean;
  disablePadding?: boolean;
  rightSideFlexEnd?: boolean;
};

const PetLastActionTime = ({
  icon,
  data,
  title,
  withTimeMarker = true,
  disablePadding,
  rightSideFlexEnd = false,
}: PetLastActionTime) => {
  const user = useBoundStore((state) => state.accountStore.user);
  const { timeToFormat } = useTimeTransform();

  const activeHousehold = useBoundStore((s) => s.householdStore.activeHousehold);

  const [lastDrunkTime, timeMarker] = useMemo(() => {
    if (!data || !data?.last_consumption || +data?.last_consumption > Date.now()) return ['-', '-'];
    const duration = getSingleDuration(data?.last_consumption, true);

    const timeMarkerValue = timeToFormat(
      format(
        utcToZonedTime(
          data?.last_consumption,
          activeHousehold?.timezone.timezone.replace('Kolkata', 'Calcutta'),
        ),
        'HH:mm',
      ),
      user.time_format,
    );

    return [duration || '-', timeMarkerValue || '-'];
  }, [data, user.time_format]);

  return (
    <PetInfoSection
      icon={icon}
      fontSizeForNumbers={24}
      fontSizeForText={16}
      title={title}
      timeMarker={withTimeMarker ? timeMarker : null}
      data={lastDrunkTime}
      disablePadding={disablePadding}
      type="time"
      rightSideFlexEnd={rightSideFlexEnd}
    />
  );
};

export default PetLastActionTime;
