/* eslint-disable class-methods-use-this */
import { WeightContext } from '@constants/Device';
import { MappedReportModel } from '@models/ReportModel';
import { differenceInSeconds } from 'date-fns';
import { ReportRangeType } from 'src/pages/Dashboard/constants/ReportRangeType';

import { TimeService } from './TimeService';

export type Drinking = MappedReportModel['drinking'];
export type Movement = MappedReportModel['movement'];
export type Feeding = MappedReportModel['feeding'];
export type PetData = Drinking | Movement | Feeding;

type ConditionsMap = {
  type: ReportRangeType;
  toDate: Date;
  difference: number;
}[];

class AveragePetStatusService {
  daysInMiliseconds = 1000 * 60 * 60 * 24;

  countAvarageAmount(
    data: PetData,
    type: ReportRangeType,
    isMovement: boolean,
    currentDate: Date,
  ): [number, number] {
    const results = this.getFilteredDays(data as Drinking, type, currentDate);
    const countWeight = (array: Drinking): number => {
      const sum = array.reduce((acc, next) => {
        return (
          acc +
          next.points.reduce((pointsAcc, pointsNext) => {
            return (
              pointsAcc +
              pointsNext.weights.reduce((weightsAcc, weightsNext) => {
                return weightsAcc + Math.abs(weightsNext.change);
              }, 0)
            );
          }, 0)
        );
      }, 0);

      const avg = sum / (array.length || 1);

      return +avg.toFixed(2);
    };

    const countMovement = (array: Movement): number => {
      const secondsSum = array.reduce((acc, next) => {
        return (
          acc +
          next.points.reduce((pointsAcc, pointsNext) => {
            return (
              pointsAcc +
              Math.abs(differenceInSeconds(new Date(pointsNext.from), new Date(pointsNext.to)))
            );
          }, 0)
        );
      }, 0);
      const avg = secondsSum / (array.length || 1);

      return +avg.toFixed(2);
    };

    if (isMovement) {
      return [
        countMovement(results[0] as unknown as Movement),
        countMovement(results[1] as unknown as Movement),
      ];
    }

    return [countWeight(results[0]), countWeight(results[1])];
  }

  private getFilteredDays(
    status: PetData,
    type: ReportRangeType,
    currentDate: Date,
  ): [Drinking, Drinking] {
    const currentFilteredResults: Drinking = [];
    const previousFilteredResults: Drinking = [];

    const { difference, toDate } = this.getMapByType(type, currentDate);
    for (let i = 0; i < status.length; i += 1) {
      const { date } = status[i];

      const diffInDays = TimeService.parseDate(date).diff(
        TimeService.parseDate(currentDate),
        'days',
      ).days;

      const diffInDaysPrev = TimeService.parseDate(toDate).diff(
        TimeService.parseDate(currentDate),
        'days',
      ).days;

      const currentResCondition =
        difference === 1
          ? diffInDays <= difference && diffInDays >= 0
          : Math.abs(diffInDays) <= difference;
      const prevResCondition =
        difference === 1
          ? diffInDaysPrev <= difference && diffInDaysPrev > 0
          : Math.abs(diffInDaysPrev) <= difference;

      if (currentResCondition) {
        currentFilteredResults.push(status[i] as unknown as Drinking[0]);
      } else if (prevResCondition) {
        previousFilteredResults.push(status[i] as unknown as Drinking[0]);
      }
    }

    return [currentFilteredResults, previousFilteredResults];
  }

  getDateIntervals(type: ReportRangeType, currentDate: Date): [string, string] {
    const today = currentDate;
    const { toDate, difference } = this.getMapByType(type, currentDate);
    const prevDate = new Date(toDate);

    const formatDate = (currDate: Date, nextDate: Date): string => {
      const includeYear = nextDate.getFullYear() !== currDate.getFullYear();

      const miniFormat = (date: Date) => {
        return new Intl.DateTimeFormat('en-GB', {
          month: 'short',
          day: 'numeric',
          year: includeYear ? 'numeric' : undefined,
        }).format(date);
      };

      return `${miniFormat(nextDate)} - ${miniFormat(currDate)}`;
    };

    const decrementedDay = +prevDate - this.daysInMiliseconds;

    return [
      formatDate(today, prevDate),
      formatDate(
        new Date(decrementedDay),
        new Date(decrementedDay - difference * this.daysInMiliseconds),
      ),
    ];
  }

  private getMapByType(type: ReportRangeType, currentDate: Date): ConditionsMap[0] {
    const today = +currentDate;
    const { daysInMiliseconds } = this;
    const sixMounthsAgo = TimeService.toLocal(today).minus({ months: 6 }).toJSDate();
    const oneYearAgo = TimeService.toLocal(today).minus({ months: 12 }).toJSDate();
    const map: ConditionsMap = [
      {
        type: ReportRangeType.OneDay,
        toDate: TimeService.toLocal(today - daysInMiliseconds).toJSDate(),
        difference: 1,
      },
      {
        type: ReportRangeType.SevenDays,
        toDate: TimeService.toLocal(today - daysInMiliseconds * 7).toJSDate(),
        difference: 7,
      },
      {
        type: ReportRangeType.TwentyEightDays,
        toDate: TimeService.toLocal(today - daysInMiliseconds * 28).toJSDate(),
        difference: 28,
      },
      {
        type: ReportRangeType.SixMonths,
        toDate: sixMounthsAgo,
        difference: (today - +sixMounthsAgo) / daysInMiliseconds,
      },
      {
        type: ReportRangeType.OneYear,
        toDate: oneYearAgo,
        difference: (today - +oneYearAgo) / daysInMiliseconds,
      },
    ];

    return map.find((item) => item.type === type);
  }

  countAvgVisits(
    data: PetData,
    type: ReportRangeType,
    currentDate: Date,
    isMovement: boolean,
  ): number {
    const visits = this.getFilteredVisits(data, type, currentDate, isMovement);
    const sum = (visits as Drinking).reduce((acc, next) => {
      return acc + next.points.length;
    }, 0);

    return Math.round(sum / (visits.length || 1));
  }

  private getFilteredVisits(
    data: PetData,
    type: ReportRangeType,
    currentDate: Date,
    isMovement: boolean,
  ): PetData {
    const currResults = this.getFilteredDays(data as Drinking, type, currentDate)[0];

    if (isMovement) {
      return currResults;
    }

    const output = currResults.filter(({ points }) => {
      const events = points.filter(({ context }) => {
        const typedContext = context as unknown as WeightContext;
        return typedContext === WeightContext.PetClosed;
      });
      return !!events.length;
    });

    return output;
  }

  countAvgTime(
    data: PetData,
    type: ReportRangeType,
    currentDate: Date,
    isMovement: boolean,
  ): string {
    const currResults = this.getFilteredVisits(data as Drinking, type, currentDate, isMovement);

    let iterations = 0;

    const sum = (currResults as Drinking).reduce((acc, { points }) => {
      iterations += points.length;
      return (
        acc +
        points.reduce((pointAcc, pointsNext) => {
          return (
            pointAcc +
            Math.abs(differenceInSeconds(new Date(pointsNext.from), new Date(pointsNext.to)))
          );
        }, 0)
      );
    }, 0);
    const secondsSum = Math.floor(sum / (iterations || 1));

    return this.convertToTimeReadableString(secondsSum);
  }

  private convertToTimeReadableString(secondsSum: number): string {
    const convert = (num: number): string => {
      return `${num}`.length === 1 ? `0${num}` : `${num}`;
    };

    const days = Math.floor(secondsSum / (3600 * 24));
    const hours = Math.floor((secondsSum % (3600 * 24)) / 3600);
    const minutes = Math.floor((secondsSum % 3600) / 60);
    const seconds = Math.floor(secondsSum % 60);

    let result = '';

    if (days) {
      result += `${days}:${convert(hours)}:${convert(minutes)}:${convert(seconds)}`;
    } else if (hours) {
      result += `${convert(hours)}:${convert(minutes)}:${convert(seconds)}`;
    } else if (minutes) {
      result += `${convert(minutes)}:${convert(seconds)}`;
    } else {
      result += `${convert(seconds)}`;
    }

    return result;
  }
}

export default AveragePetStatusService;
