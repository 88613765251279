import { DateTime, Interval, Settings } from 'luxon';
import { DateTimeMaybeValid } from 'luxon/src/datetime';
import { IntervalMaybeValid } from 'luxon/src/interval';
import storeCreator from 'src/store/store';

type DateTypes = Date | string | number;

export class TimeService {
  private static householdTimezoneOffset: number = null;
  static changeDefaultTimezone = (timezone: string) => {
    console.log('TimeService.changeDefaultTimezone', timezone);
    Settings.defaultZone = timezone;
  };
  static DateTime = DateTime;
  static parseDate = (date: string | number | Date | DateTime) => {
    if (typeof date === 'number') {
      return DateTime.fromMillis(date);
    }
    if (typeof date === 'string') {
      return DateTime.fromISO(date);
    }
    if (date instanceof Date) {
      return DateTime.fromJSDate(date);
    }
    return date;
  };

  static getDatesRange(from: DateTimeMaybeValid, to: DateTimeMaybeValid): string[] {
    return Interval.fromDateTimes(from.startOf('day'), to.endOf('day'))
      .splitBy({ day: 1 })
      .map((date: Interval) => date.start.toISODate());
  }

  // start of given date or current date
  static startOfDay(date: string | number | Date | DateTime = DateTime.local()) {
    return TimeService.parseDate(date).startOf('day');
  }

  // end of given date or current date
  static endOfDay(date: string | number | Date | DateTime = DateTime.local()) {
    return TimeService.parseDate(date).endOf('day');
  }

  // To Household Time
  static toLocal(date: string | number | Date | DateTime = DateTime.utc()) {
    return TimeService.parseDate(date).toLocal();
  }

  static toUTC(date: string | number | Date | DateTime = DateTime.local()) {
    return TimeService.parseDate(date).toUTC();
  }

  // not used now but might be usefull in a future
  static toLocalJSDate(date: string | number | DateTime = DateTime.utc()) {
    const local = DateTime.local();
    const hhZone = local.setZone(Settings.defaultZone);
    const hhLocal = hhZone.setZone('local', { keepLocalTime: true });
    return hhLocal.toJSDate();
  }

  static refreshTimezone() {
    const { activeHousehold } = storeCreator.getState().householdStore;
    const offset = activeHousehold?.timezone?.utc_offset || 0;
    this.householdTimezoneOffset = offset;
    if (this.householdTimezoneOffset !== 0) {
      this.householdTimezoneOffset *= 1000;
    }
  }

  static convertDateWithoutLocalTimezone(date: Date, milliseconds = 0): Date {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const tzOffset = date.getTimezoneOffset();
    return new Date(date.valueOf() + (minutes + tzOffset) * 60 * 1000);
  }

  static convertOrUnconvertDateByUTC<T extends DateTypes>(
    date: T,
    convertUtcToZonedTime: boolean,
  ): number {
    this.refreshTimezone();

    if (!date && date === 0) {
      return null;
    }

    const calculatedOffset = this.householdTimezoneOffset
      ? this.householdTimezoneOffset * (convertUtcToZonedTime ? 1 : -1)
      : 0;

    if (typeof date === 'string') {
      return TimeService.convertDateWithoutLocalTimezone(
        new Date(date),
        calculatedOffset,
      ).getTime();
    }

    if (typeof date === 'number') {
      const newDate = new Date(date + calculatedOffset);
      return +newDate;
    }

    const newDate = new Date(+date + calculatedOffset);

    return +newDate;
  }

  static convertOrUnconvertDateByUTCOld<T extends DateTypes>(
    date: T,
    convertUtcToZonedTime: boolean,
  ): T {
    this.refreshTimezone();

    const calculatedOffset = this.householdTimezoneOffset
      ? this.householdTimezoneOffset * (convertUtcToZonedTime ? 1 : -1)
      : 0;

    if (typeof date === 'string') {
      const newDate = new Date(+new Date(date) + calculatedOffset);
      return newDate.toISOString() as T;
    }

    if (typeof date === 'number') {
      const newDate = new Date(date + calculatedOffset);
      return +newDate as T;
    }

    const newDate = new Date(+date + calculatedOffset);

    return newDate as T;
  }

  static getTimezonnedCurrentDate(): number {
    this.refreshTimezone();

    if (this.householdTimezoneOffset === null) {
      return Date.now();
    }
    return this.convertOrUnconvertDateByUTC<Date>(new Date(), true);
  }
}

// TODO move this to Time Service
export const getLocalTime = (time: string, timezone?: string) => {
  return TimeService.toUTC()
    .set({
      hour: Number(time.split(':')[0]),
      minute: Number(time.split(':')[1]),
    })
    .toLocal()
    .toFormat('HH:mm');
};

export const getUTCTime = (time: string) => {
  return TimeService.toLocal()
    .set({
      hour: Number(time.split(':')[0]),
      minute: Number(time.split(':')[1]),
    })
    .toUTC()
    .toFormat('HH:mm');
};
