import DeviceApi from '@api/DeviceApi';
import { SpLoading } from '@atoms/SpLoading';
import { SpView } from '@atoms/SpView';
import { DeviceType } from '@constants/Device';
import { useDeviceById } from '@hooks/useDeviceById';
import useDeviceStatusHook from '@hooks/useDeviceStatusHook';
import useDeviceStatusParse from '@hooks/useDeviceStatusParse';
import { DeviceModel } from '@models/Device';
import { StackScreenProps } from '@react-navigation/stack';
import colors from '@styles/colors';
import React, { useEffect, useMemo, useState } from 'react';
import { RefreshControl, SafeAreaView, ScrollView, StyleSheet } from 'react-native';

import { CerberusWrapper } from './components/CerberusWrapper';
import { DrinkingDeviceWrapper } from './components/DrinkingDeviceWrapper';
import { FeederDeviceWrapper } from './components/FeederDeviceWrapper';
import { HubDeviceWrapper } from './components/HubDeviceWrapper';
import { PetDoorCatFlapDeviceWrapper } from './components/PetDoorCatFlapDeviceWrapper';
import { DeviceStackParamList } from './index';
import useBoundStore from '../../store/store';

export const DeviceDetails = ({
  route,
}: StackScreenProps<DeviceStackParamList, 'DeviceDetails'>) => {
  const { id } = route.params;
  const [refreshControlLoading, setRefreshControlLoading] = useState(false);
  const device: DeviceModel = useDeviceById(id);
  const deviceStoreData = useBoundStore((state) => ({
    loading: state.deviceStore.loadingDevice,
    load: state.deviceStore.loadDevice,
    updateDevice: state.deviceStore.updateDevice,
    getHousehold: state.householdStore.getHouseholds,
    loadingControl: state.deviceStore.loadingControl,
    mutateTagProfiles: state.deviceStore.mutateTagProfiles,
  }));
  const productId = device?.product_id;

  const deviceStatus = useDeviceStatusHook(id, true);
  useDeviceStatusParse(deviceStatus);

  // TODO in the future we should not mutate the device tags, we should maintain a separate list of pending tags and modify the array
  //  at the place its displayed then reload the device when the pending tags length decreases

  useEffect(() => {
    if (!deviceStoreData.loadingControl?.tag_profiles) {
      return;
    }
    if (deviceStoreData.loadingControl?.tag_profiles?.length > 0) {
      deviceStoreData.loadingControl?.tag_profiles.forEach((item) => {
        deviceStoreData.mutateTagProfiles(item, device.id, item.action);
      });
    }
  }, [deviceStoreData.loadingControl, deviceStoreData.loading]);
  useEffect(() => {
    DeviceApi.getDeviceTags(device.id).then((res) => {
      const newDevice = { ...device, tags: res };
      deviceStoreData.updateDevice(newDevice);
    });
  }, []);
  const onRefresh = () => {
    setRefreshControlLoading(true);
    deviceStoreData.getHousehold();
    deviceStoreData.load(true);
  };

  const deviceSection = useMemo(() => {
    if (refreshControlLoading) {
      return null;
    }

    if (productId === DeviceType.PetDoorConnect || productId === DeviceType.CatFlapConnect) {
      return <PetDoorCatFlapDeviceWrapper deviceId={id} data={device} />;
    }

    if (productId === DeviceType.FeederConnect) {
      return <FeederDeviceWrapper deviceId={id} data={device} />;
    }

    if (productId === DeviceType.Cerberus) {
      return <CerberusWrapper deviceId={id} data={device} />;
    }

    if (productId === DeviceType.FelaquaConnect) {
      return <DrinkingDeviceWrapper deviceId={id} data={device} />;
    }

    if (productId === DeviceType.Hub) {
      return <HubDeviceWrapper deviceId={id} data={device} />;
    }

    return <SpLoading />;
  }, [device, refreshControlLoading, productId]);

  useEffect(() => {
    if (!deviceStoreData.loading) {
      setRefreshControlLoading(false);
    }
  }, [deviceStoreData.loading]);

  return (
    <SafeAreaView style={styles.fullHeight}>
      <ScrollView
        contentContainerStyle={styles.fullHeight}
        refreshControl={<RefreshControl refreshing={false} onRefresh={onRefresh} />}>
        <SpView paddingHorizontal={18} flex={1}>
          {deviceSection}
        </SpView>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  fullHeight: {
    flexGrow: 1,
    backgroundColor: colors.white.color,
  },
});
