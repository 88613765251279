import { SpText } from '@atoms/SpText';
import { useClickOutside } from '@hooks/useClickOutside';
import { useTimeTransform } from '@hooks/useTimeTransform';
import useTour from '@hooks/useTour';
import { MappedReportModel } from '@models/ReportModel';
import { useIsFocused } from '@react-navigation/native';
import colors from '@styles/colors';
import * as d3 from 'd3';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Dimensions, StyleSheet, TouchableOpacity } from 'react-native';
import { G, Rect, Svg, Text as SvgText } from 'react-native-svg';

import { GraphTooltip } from './GraphTooltip';
import PetReportsSlide from '../../../Tour/components/PetReportsSlide/PetReportsSlide';
import TimeOutsideGraphsSlide from '../../../Tour/components/TimeOutsideGraphsSlide/TimeOutsideGraphsSlide';
import { ReportRangeType } from '../../constants/ReportRangeType';
import { useGroupedMovementReportStats } from '../../hooks/useGroupedMovementReportStats';
import { useSummaryReportGraphData } from '../../hooks/useSummaryReportGraphData';
import useTickFormatting from '../../hooks/useTickFormatting';
import { TimeService } from '../../../../services/TimeService';

const { width } = Dimensions.get('window');

interface MovementGraphProps {
  rangeType: ReportRangeType;
  currentDate: number;
  stats: MappedReportModel;
  onSetAverage: (value: number) => void;
}

export const MovementGraph = ({
  rangeType,
  currentDate,
  stats,
  onSetAverage,
}: MovementGraphProps) => {
  const [activeBar, setActiveBar] = useState<number>(null);
  const clickOutsideRef = useClickOutside(() => setActiveBar(null));
  const { convertSecondToFormat } = useTimeTransform();
  const isFocused = useIsFocused();
  const { calculateBarOpacity, convertTicksToDuration } = useTickFormatting(rangeType);

  const initTour = useTour({
    uniqueId: 'v0-graph-movements',
    components: [<TimeOutsideGraphsSlide />, <PetReportsSlide />],
    devices: ['all'],
  });

  useLayoutEffect(() => {
    initTour();
  }, [isFocused]);

  const { movementData, movementAverage } = useGroupedMovementReportStats(
    stats?.movement || [],
    currentDate,
    rangeType,
  );

  useEffect(() => {
    onSetAverage(movementAverage);
  }, [movementAverage]);

  const checkTotalDuration = (value: number) => {
    return value < 0 ? 0 : value;
  };
  const yValues = useMemo(
    () => movementData.map((item) => checkTotalDuration(item.totalDuration)),
    [movementData],
  );
  const xValues = useMemo(() => movementData.map((item) => item.date), [movementData]);
  const {
    xAxisLabels,
    yAxisLabels,
    barWidth,
    barGap,
    chartHeight,
    margin,
    height,
    xAxisLabelStyle,
    yAxisLabelStyle,
    barRadius,
    chartWidth,
  } = useSummaryReportGraphData(width, rangeType, xValues, yValues, 0.45);

  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max(yValues)])
    .range([chartHeight, 0]);

  const tooltipInfo = useMemo(() => {
    if (activeBar !== null) {
      const eventByIndex = movementData[activeBar];
      const maximumDay = TimeService.toLocal(eventByIndex.date).endOf('month').day;
      let duration = convertSecondToFormat(
        checkTotalDuration(eventByIndex.totalDuration),
        rangeType,
      );
      // hotfix, refactor logic in a new service
      if ([ReportRangeType.SixMonths, ReportRangeType.OneYear].includes(rangeType)) {
        duration.time = String(Math.min(+maximumDay, +duration.time));
      }

      return {
        y: yScale(checkTotalDuration(eventByIndex.totalDuration)) + 70,
        text: `${duration.time} ${duration.unit}`,
      };
    }

    return null;
  }, [activeBar, rangeType]);
  const report = useMemo(() => {
    return (
      <TouchableOpacity
        activeOpacity={1}
        ref={clickOutsideRef}
        style={styles.graphContainer}
        onPress={() => setActiveBar(null)}>
        {tooltipInfo ? (
          <GraphTooltip
            top={tooltipInfo.y}
            calcLeftProps={{
              index: activeBar,
              barWidth,
              barGap,
              marginLeft: margin.left,
              marginRight: margin.right,
              chartWidth,
              rangeType,
            }}>
            <SpText align="center" size="sm" color={colors.white.color}>
              {tooltipInfo.text}
            </SpText>
          </GraphTooltip>
        ) : null}

        <Svg width={width} height={height}>
          <G transform={`translate(${margin.left},${margin.top})`}>
            {/* Draw bars */}
            {movementData.map((item, index) => {
              return (
                <Rect
                  rx={barRadius}
                  key={`${item.date + Math.random()}Movement`}
                  x={index * (barWidth + barGap)}
                  y={yScale(checkTotalDuration(item.totalDuration))}
                  width={barWidth}
                  height={
                    item.totalDuration
                      ? chartHeight - yScale(checkTotalDuration(item.totalDuration)) + barRadius
                      : 0
                  }
                  fill={activeBar === index ? colors.lightBlack.color : colors.green.color}
                  opacity={calculateBarOpacity(index)}
                  onPress={() => setActiveBar(index)}
                />
              );
            })}
            <Rect
              x={-margin.left}
              y={chartHeight}
              width={width}
              height={margin.bottom}
              fill="white"
            />

            {/* Draw x-axis labels */}
            {(xAxisLabels || []).map((item, index) => {
              return (
                <SvgText
                  key={`${item + index + Math.random()}MovementXLabel`}
                  x={index * (barWidth + barGap) + barWidth / 2}
                  y={chartHeight + xAxisLabelStyle.offsetTop}
                  fontSize={xAxisLabelStyle.fontSize}
                  textAnchor="middle"
                  fill={xAxisLabelStyle.color}>
                  {item}
                </SvgText>
              );
            })}

            {/* Draw y-axis labels */}
            {yAxisLabels.map((tick, index) => (
              <React.Fragment key={`${tick + index}MovementXLabel`}>
                <Rect
                  x={yAxisLabelStyle.offsetLeft - 5}
                  y={yScale(tick) - yAxisLabelStyle.fontSize / 2}
                  width={60}
                  height={yAxisLabelStyle.fontSize + 6}
                  fill="white"
                  opacity={0.5}
                  rx={6}
                  ry={6}
                />
                <SvgText
                  key={`${tick + index}MovementXLabel`}
                  x={yAxisLabelStyle.offsetLeft}
                  y={yScale(tick)}
                  fontSize={yAxisLabelStyle.fontSize}
                  fill={yAxisLabelStyle.color}
                  textAnchor="start"
                  alignmentBaseline="center">
                  {convertTicksToDuration(tick)}
                </SvgText>
              </React.Fragment>
            ))}
          </G>
        </Svg>
      </TouchableOpacity>
    );
  }, [movementData, clickOutsideRef, setActiveBar, tooltipInfo, activeBar, rangeType]);

  return report;
};

const styles = StyleSheet.create({
  graphContainer: {
    position: 'relative',
    backgroundColor: 'rgba(45, 181, 142, 0.1)',
  },
});
