import { useUserCountryCode } from '@hooks/useUserCountryCode';
import { useUserWeightUnits } from '@hooks/useUserWeightUnits';
import { useWeightConversion } from '@hooks/useWeightConversion';
import { PetStatisticsModel } from '@models/Device';
import SpGraphicSkeleton from '@molecules/SpGraphicSkeleton';
import { addMinutes } from 'date-fns';
import React, { useMemo } from 'react';

import { WeeklyGraph } from './WeeklyGraph';
import useBoundStore from '../../../../store/store';
import { ReportType } from '../../constants/ReportType';
import { fastTimeConvertor } from '../../hooks/useTotalConsumption';

export interface WeeklyTrendProps {
  activeTab: ReportType;
  petStatistic: PetStatisticsModel;
  selectedDate: number;
  skeletonAfterLocking?: boolean;
}

export const WeeklyTrend = ({ activeTab, petStatistic, selectedDate, skeletonAfterLocking = false }: WeeklyTrendProps) => {
  const userWeightUnits = useUserWeightUnits();
  const userCountryCode = useUserCountryCode();
  const { convertWithUnits } = useWeightConversion(userWeightUnits, userCountryCode);
  const activeHousehold = useBoundStore((s) => s.householdStore.activeHousehold);

  const [trend, isAvailiable] = useMemo(() => {
    const getGraphics = () => {
      if (activeTab === ReportType?.Drinking) {
        return petStatistic?.drinking?.activity?.map((item, i) => {
          return {
            x: i + 1,
            y: item.total_consumption || 0,
            label: convertWithUnits(item.total_consumption),
            date: +new Date(
              addMinutes(
                new Date(item.date),
                activeHousehold.timezone.utc_offset / 60,
              ).toISOString(),
            ),
            warning: i === 0, // Mock for test
          };
        }, {});
      }

      if (activeTab === ReportType?.Feeding) {
        return petStatistic?.feeding?.activity?.map((item, i) => {
          return {
            x: i + 1,
            y: item.total_consumption || 0,
            label: convertWithUnits(item.total_consumption, true),
            date: +new Date(
              addMinutes(
                new Date(item.date),
                activeHousehold.timezone.utc_offset / 60,
              ).toISOString(),
            ),
            warning: i === 3, // Mock for test
          };
        }, {});
      }

      if (activeTab === ReportType?.Movement) {
        return petStatistic?.movement?.activity?.map((item, i) => {
          const days = Number(
            item.time_outside.includes('.') ? item.time_outside.split('.')[0] : 0,
          );
          const timeArray = item.time_outside.split(':');
          let value = 0;

          if (timeArray.length) {
            value =
              (+timeArray[2] || 0) * 1000 +
              (+timeArray[1] || 0) * 60000 +
              (+timeArray[0] || 0) * 3600000 +
              days * 86400000;
          }

          return {
            x: i + 1,
            y: value,
            label: fastTimeConvertor(+timeArray[0] || 0, +timeArray[1] || 0),
            date: +new Date(
              addMinutes(
                new Date(item.date),
                activeHousehold.timezone.utc_offset / 60,
              ).toISOString(),
            ),
            warning: i === 5, // Mock for test
          };
        }, {});
      }

      return [];
    };
    const graphics = getGraphics()?.slice(0, 8);
    return [graphics, graphics?.some((item) => item.y !== 0)];
  }, [petStatistic, activeTab]);

  if (isAvailiable) {
    return <WeeklyGraph events={trend} />;
  }

  if (skeletonAfterLocking) {
    return null;
  }

  return <SpGraphicSkeleton showText selectedDate={selectedDate} />;
};
