import { SpText } from '@atoms/SpText';
import colors from '@styles/colors';
import React, { useCallback, useMemo, useState } from 'react';
import { Dimensions, Pressable, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Carousel } from 'react-native-snap-carousel';

import { LineChartProps } from './DurationLineChart';
import { ITEM_WIDTH, SLIDER_WIDTH } from '../../pages/Dashboard/molecules/PetCarousel/PetCard';

interface ConvertedPoints<T = string> {
  index: number;
  left: number;
  multi?: boolean;
}

export const VisitsLineChart = ({
  withLabels = false,
  viewButton = false,
  isActive = false,
  setActive,
  points,
  pointDetailsRenderItem,
  rightTitle,
  leftTitle,
  lowState,
}: LineChartProps) => {
  const isCarousel = React.useRef(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const windowWidth = Dimensions.get('window').width;
  const fullDaySeconds = 86400;

  const lineChartsRealWidth = useMemo(() => {
    return windowWidth - 80 - (lowState && !isActive ? 16 : 24);
  }, []);

  const convertPoints2 = useCallback(() => {
    const converted: ConvertedPoints[] = [];
    points.forEach((item, index) => {
      const targetDate = new Date(item.from);
      const startOfDay = new Date(
        targetDate.getFullYear(),
        targetDate.getMonth(),
        targetDate.getDate(),
      );
      const elapsedSeconds = Math.floor((targetDate - startOfDay) / 1000);
      const left = Number((elapsedSeconds * lineChartsRealWidth) / fullDaySeconds).toFixed(4);
      converted.push({
        index,
        left: Number(left),
        multi: item.weights[0].multi ?? false,
      });
    });

    return converted;
  }, [points]);

  const handleMarker = useCallback((index: number) => {
    isCarousel?.current?.snapToItem(index);
  }, []);

  const dotComponent = () => {
    return convertPoints2().map((item) => {
      const active = isActive && item.index === activeIndex;
      const color = () => {
        return isActive ? colors.pastelGreen.color : colors.toxicGreen.color;
      };

      const zIndex = () => {
        if (item.index === activeIndex) {
          return points.length;
        }
        if (item.index) {
          return points.length - item.index;
        }
      };

      return (
        <TouchableOpacity
          key={item.index}
          onPress={() => {
            setActiveIndex(item.index);
            handleMarker(item.index);
          }}
          disabled={!isActive}
          style={{
            position: 'absolute',
            alignContent: 'center',
            justifyContent: 'center',
            width: lowState && !isActive ? 16 : 24,
            backgroundColor: active ? colors.toxicGreen.color : color(),
            height: lowState && !isActive ? 16 : 24,
            borderRadius: 50,
            left: item.left || 0,
            zIndex: zIndex() || 0,
          }}>
          <SpText
            style={{
              color: 'white',
              width: '100%',
              textAlign: 'center',
            }}>
            {item.multi ? '?' : ''}
          </SpText>
        </TouchableOpacity>
      );
    });
  };

  return (
    <View
      style={{
        marginTop: 12,
      }}>
      <View style={[styles.labelWrapper, (leftTitle || isActive) && { marginTop: 12 }]}>
        <View>{(leftTitle || isActive) && <SpText size="sm">{leftTitle}</SpText>}</View>
        <View>
          {rightTitle && (!lowState || isActive) && <SpText size="sm">{rightTitle}</SpText>}
        </View>
      </View>
      <Pressable
        onPress={() => {
          if (setActive) setActive();
        }}
        style={[
          styles.line,
          {
            backgroundColor: isActive ? colors.greyText.color : colors.pastelGreen.color,
          },
        ]}>
        <View style={[styles.inner, { height: lowState && !isActive ? 24 : 36 }]}>
          {dotComponent()}
          <Pressable style={{ width: '100%' }} />
        </View>
      </Pressable>
      {isActive && (
        <View style={[styles.mt, styles.center]}>
          <Carousel
            layout="default"
            vertical={false}
            onSnapToItem={setActiveIndex}
            ref={isCarousel}
            firstItem={0}
            data={points as any}
            renderItem={pointDetailsRenderItem}
            sliderWidth={SLIDER_WIDTH}
            itemWidth={ITEM_WIDTH}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  renderItemWrapper: {
    flexDirection: 'row',
    width: '100%',
    paddingHorizontal: 24,
    justifyContent: 'space-between',
    paddingVertical: 15,
    borderRadius: 10,
    backgroundColor: colors.placeholderGrey.color,
  },
  fs: {
    fontSize: 18,
  },
  flexStart: {
    alignItems: 'flex-start',
  },
  center: {
    alignItems: 'center',
  },
  flexEnd: {
    alignItems: 'flex-end',
  },
  thumbnailTitle: {
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold',
  },
  thumbnailValue: {
    color: 'white',
    fontSize: 19,
  },
  mt: {
    marginTop: 16,
  },
  line: {
    paddingHorizontal: 16,
    borderRadius: 18,
    zIndex: -1,
  },
  inner: {
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
  },
  warningWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderWidth: 2,
    borderRadius: 10,
    borderColor: colors.placeholderGrey.color,
    marginHorizontal: -10,
    marginBottom: 15,
  },
  labelWrapper: {
    marginBottom: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  viewButton: {
    flexDirection: 'row',
    paddingHorizontal: 40,
    paddingVertical: 10,
    borderWidth: 1,
    borderRadius: 10,
    alignItems: 'center',
  },
  warningText: { marginLeft: 20, color: colors.placeholderGrey.color },
  thumbnailWrapper: { borderWidth: 2, borderRadius: 15 },
});
