import { SpLoading } from '@atoms/SpLoading';
import { DeviceHubLedMode } from '@constants/Device';
import { useCustomToast } from '@hooks/useToast';
import { useUserHasWriteAccess } from '@hooks/useUserHasWriteAccess';
import { DeviceModel } from '@models/Device';
import i18n from '@utils/i18n';
import React, { useCallback, useEffect } from 'react';

import { MergedInterfaces } from '../../../store/models';
import useBoundStore from '../../../store/store';
import { HubCard } from '../molecules/HubDevice/HubCard';

interface HubDeviceWrapperProps {
  data: DeviceModel;
  deviceId: number;
}

export const HubDeviceWrapper = ({ data, deviceId }: HubDeviceWrapperProps) => {
  const toast = useCustomToast();
  const { loadingDevice, error, success, resetStatusFields, updateLedMode } = useBoundStore(
    (state: MergedInterfaces) => {
      const { deviceStore } = state;
      return {
        loadingDevice: deviceStore.loadingDevice,
        error: deviceStore.error,
        success: deviceStore.success,
        resetStatusFields: deviceStore.resetStatusFields,
        updateLedMode: deviceStore.updateLedMode,
      };
    },
  );
  const userHasWriteAccess = useUserHasWriteAccess();

  const changeLedModeAction = useCallback(
    ($event: DeviceHubLedMode) => {
      updateLedMode(data.id, $event);
    },
    [data, updateLedMode],
  );

  useEffect(() => {
    if (error) {
      toast.show({
        description: i18n.t('error_hub_lights'),
        isError: true,
      });
    }
    if (success) {
      toast.show({
        description: i18n.t('success_hub_lights'),
        isError: false,
      });
    }

    return () => resetStatusFields();
  }, [error, success]);

  useEffect(() => {
    return () => resetStatusFields();
  }, []);

  if (loadingDevice) {
    return <SpLoading />;
  }

  return (
    <HubCard
      data={data}
      userHasWriteAccess={userHasWriteAccess}
      onChangeLedMode={changeLedModeAction}
    />
  );
};
