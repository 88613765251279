import { BackHandler, Dimensions, View } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';

import useBoundStore from 'src/store/store';
import { useDeviceId } from '@hooks/useDeviceId';
import AuthApi from '@api/AuthApi';
import { checkEmailValidity } from '@utils/checkEmailValidity';
import SpTitle from '@molecules/SpTitle';
import { FormikValidInterceptorProps } from '@models/FormikTypes';
import { useTranslation } from 'react-i18next';
import EssenceCreated from '@atoms/EssenceCreated';
import { AnalyticsService } from 'src/services/AnalyticsService';
import { testProperties } from '@utils/testProperties';
import { useTermsAndConditions } from '../../hooks/useTermsAndConditions';
import useValidation from '../validation';
import { RootStackParamList } from '..';
import PasswordForm from './forms/PasswordForm';
import TermsAndConditionsForm from './forms/TermsAndConditionsForm';
import NameForm from './forms/NameForm';
import EmailForm from './forms/EmailForm';
import CountryForm from './forms/CountryForm';
import StepFormCreator from '../../components/molecules/StepFormCreator';
import { navigateToPets } from '@utils/navigationShortcuts';

const Register = () => {
  const [step, setStep] = useState(1);
  const [submitValues, setSubmitValues] = useState<any>();
  const [countryButtonDisabled, setCountryButtonDisabled] = useState(true);
  const [validStep, setValidStep] = useState<{ [key: number]: boolean }>({});
  const termsProps = useTermsAndConditions();
  const deviceId = useDeviceId();
  const { loadCountries, getCountryByID } = useBoundStore(
    state => state.countryStore,
  );
  const {
    commonNameSchema,
    countryStepSchema,
    emailSchema,
    passwordStepSchema,
  } = useValidation();
  const { loadTimezones } = useBoundStore(state => state.timezoneStore);
  const { loadLanguages, getLanguageByID } = useBoundStore(
    state => state.languageStore,
  );

  const { t } = useTranslation();

  const { setUser } = useBoundStore(s => s.accountStore);

  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  useEffect(() => {
    loadCountries(true);
    loadTimezones(true);
    loadLanguages(true);
  }, []);
  useEffect(() => {
    if (step === 4) {
      setSubmitValues({
        ...submitValues,
        password: '',
        retypePassword: '',
      });
    }
  }, [step]);
  const onSubmit = useCallback(
    async ({ formik }: FormikValidInterceptorProps) => {
      const submitObj = {
        first_name: submitValues.firstName,
        last_name: submitValues.surName,
        email_address: submitValues.email,
        password: submitValues.password,
        language_id: 37,
        country_id: Number(formik.values?.country),
        marketing_opt_in: termsProps.confirmSendSelect,
        time_format: 0,
        weight_units: 0,
      };
      try {
        AnalyticsService.logEvent(`register`);

        const { user, token } = await AuthApi.register({
          ...submitObj,
          device_id: deviceId,
        } as any);
        if (user && token) {
          setUser({ user, token });

          await AnalyticsService.setUserProperties({
            userLanguage: getLanguageByID(user.language_id).code,
            userCountry: getCountryByID(user.country_id).code,
          });

          return true;
        }
        return false;
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    [submitValues, termsProps, deviceId],
  );

  const PasswordFormWrap = useCallback(
    (children: JSX.Element) => (
      <View>
        <SpTitle text={t('password_title')} />
        {children}
      </View>
    ),
    [],
  );

  const DefaultWrapper = useCallback(
    (children: JSX.Element) => <View>{children}</View>,
    [],
  );

  const navigateToHousehold = () => {
    navigation.navigate('HouseholdNavigation', {
      screen: 'ChooseSetup',
    });
  };

  const handleStepValidity = useCallback((stepNumber: number) => {
    return (validity: boolean) => {
      setValidStep(prev => ({ ...prev, [stepNumber]: validity }));
    };
  }, []);

  return (
    <StepFormCreator
      step={step}
      setStep={setStep}
      headerTitle={t('create_account')}
      submitValues={submitValues}
      setSubmitValues={setSubmitValues}
      disableKeyboardAwoiding={step === 1}
      fromRegister
      scrollViewTestProps={testProperties(
        'scroll higher',
        'container',
        '',
        true,
      )}
      safeAreaView={!(step === 1)}
      steps={{
        1: {
          ui: (
            <TermsAndConditionsForm
              {...termsProps}
              onPress={() => {
                setStep(curr => curr + 1);
              }}
            />
          ),
          hideButton: false,
          buttonDisabled: !(
            termsProps.terms.endUserLicenceAgreement &&
            termsProps.terms.privacyPolicy &&
            termsProps.confirmSendSelect !== null
          ),
          additionalButton: true,
          additionalButtonName: t('decline'),
          additionalButtonAction: () => navigation.goBack(),
          showBottomBackground: true,
          keyboardAwoidingProps: {
            disabled: true,
          },
        },
        2: {
          ui: NameForm,
          props: { handleValidity: handleStepValidity(2), fromRegister: true},

          formik: {
            schema: commonNameSchema('First name', 'firstName').concat(
              commonNameSchema('Surname', 'surName'),
            ),
            names: ['firstName', 'surName'],
          },
          wrap: DefaultWrapper,
          keyboardAwoidingProps:
            Dimensions.get('window').height > 750
              ? {
                  scrollEnabled: false,
                }
              : undefined,
        },
        3: {
          ui: EmailForm,
          props: { fromRegister: true },
          formik: { schema: emailSchema, names: ['email'] },
          formikValidInterceptor: args =>
            checkEmailValidity(deviceId, args as any),
          wrap: DefaultWrapper,
        },
        4: {
          ui: PasswordForm,
          props: { label: 'new_password', placeholder: 'type_password', fromRegister: true },
          wrap: PasswordFormWrap,
          formik: {
            schema: passwordStepSchema,
            names: ['password', 'retypePassword'],
          },
          keyboardAwoidingProps: {
            extraScrollHeight: 180,
          },
        },
        5: {
          ui: CountryForm,
          formik: { schema: countryStepSchema, names: ['country'] },
          buttonDisabled: countryButtonDisabled,
          effect: ({ formik }) => {
            setCountryButtonDisabled(Boolean(!formik.values?.country));
          },
          formikValidInterceptor: onSubmit,
          wrap: DefaultWrapper,
        },
        6: {
          ui: (
            <EssenceCreated
              msg={t('account_created')}
              initialValue
              containerStyle={{ marginTop: '30%' }}
            />
          ),
          hideCustomHeader: true,
          forcePressHandler: navigateToHousehold,
          handleAndroidBackPress: () => {
            navigateToPets()
            return true
          }

        },
      }}
    />
  );
};

export default Register;
