import {
  GroupReportPointModel,
  MappedDoorStats,
  MappedMovementReportDataModel
} from '@models/ReportModel';
import { getDoorStatsByRange } from '@utils/report';
import { differenceInSeconds, isSameHour } from 'date-fns';
import { useMemo } from 'react';

import { useGroupedRangeReportStats } from './useGroupedRangeReportStats';
import { TimeService } from '../../../services/TimeService';
import { ReportRangeType } from '../constants/ReportRangeType';

interface GroupedMovementReportStatsResult {
  movementData: MappedDoorStats[];
  movementAverage: number;
}

const mapResultToAverageMovementReport = (data): GroupedMovementReportStatsResult => {
  const res = Array.isArray(data) ? data : data?.movementData || [];

  if (!res || !Array.isArray(res)) {
    return {
      movementAverage: 0,
      movementData: []
    };
  }
  const movementDataSum = res.reduce((acc, curr) => {
    acc += curr.totalDuration;
    return acc;
  }, 0);

  return {
    movementData: res,
    movementAverage:
      movementDataSum / (res || []).filter((item) => !!item.totalDuration).length || 0
  };
};

const groupEventsByHours = (
  start: number,
  end: number,
  sharedData: Record<number, { activity: number; duration: number }>
): Record<number, { activity: number; duration: number }> => {
  const diff = differenceInSeconds(end, start);
  const isSameHours = isSameHour(end, start);
  const uniqDate = TimeService.toLocal(start).startOf('hour').toMillis();
  const uniqueNextDate = TimeService.toLocal(end).startOf('hour').toMillis();

  let toStartHour: number = null;
  let fromEndHour: number = null;
  // startOfHour(addHours(start, 1)).getTime()
  if (diff > 3599) {
    return groupEventsByHours(
      TimeService.toLocal(start).plus({ hour: 1 }).startOf('hour').toMillis(),
      end,
      {
        ...sharedData,
        [uniqDate]: {
          duration: differenceInSeconds(TimeService.toLocal(start).endOf('hour').toMillis(), start),
          activity: 1
        }
      }
    );
  }

  if (!isSameHours) {
    toStartHour = Math.abs(
      differenceInSeconds(start, TimeService.toLocal(start).endOf('hour').toJSDate())
    );
    fromEndHour = Math.abs(
      differenceInSeconds(TimeService.toLocal(end).startOf('hour').toJSDate(), end)
    );
  }

  let newPart = {
    [uniqDate]: {
      duration: (sharedData[uniqDate]?.duration || 0) + (toStartHour || diff),
      activity: 1
    }
  };

  if (!isSameHours && fromEndHour) {
    newPart = {
      ...newPart,
      [uniqueNextDate]: {
        duration: (sharedData[uniqueNextDate]?.duration || 0) + fromEndHour,
        activity: 1
      }
    };
  }

  return {
    ...sharedData,
    ...Object.keys(newPart).reduce((acc, item) => {
      // @ts-ignore
      if (sharedData[item]) {
        // @ts-ignore
        acc[item] = {
          // @ts-ignore
          duration: newPart[item].duration,
          // @ts-ignore
          activity: sharedData[item].totalActivity + 1
        };
      } else {
        // @ts-ignore
        acc[item] = newPart[item];
      }

      return acc;
    }, {})
  };
};

export function useGroupedMovementReportStats(
  data: GroupReportPointModel<MappedMovementReportDataModel>[],
  date: number,
  range: ReportRangeType
): GroupedMovementReportStatsResult {
  const calculatedRange = useGroupedRangeReportStats(
    TimeService.toLocal(date).startOf('day').toMillis(),
    range
  );

  return useMemo(() => {
    if (range === ReportRangeType.OneDay) {
      const startOfDate = TimeService.toLocal(calculatedRange.start).startOf('day');
      const points = data.map((item) => item.points).flat();
      const groupedEvents = points.reduce((acc, event) => {
        return groupEventsByHours(
          TimeService.toLocal(event.from).toMillis(),
          TimeService.toLocal(event.to).toMillis(),
          acc
        );
      }, {});
      const oneDayEvents = Object.keys(groupedEvents).reduce((acc, item) => {
        if (
          TimeService.toLocal(Number(item)).startOf('day').toMillis() === startOfDate.toMillis()
        ) {
          // @ts-ignore
          acc[Number(item)] = groupedEvents[item];
        }

        return acc;
      }, {});

      const oneDayRes = new Array(24).fill(true).map((_, index) => {
        const currentDate = startOfDate.plus({ hour: index }).toMillis();
        // @ts-ignore
        const event = oneDayEvents[currentDate];
        return {
          date: currentDate,
          totalDuration: event?.duration || 0,
          totalActivity: event?.activity || 0,
          lastLeft: null,
          lastEnter: null
        };
      });

      return mapResultToAverageMovementReport(oneDayRes);
    }

    const res = getDoorStatsByRange(data, calculatedRange.start, calculatedRange.finish);

    // TODO: refactor this
    if (range === ReportRangeType.OneYear || range === ReportRangeType.SixMonths) {
      const group = res.reduce((acc: Record<number, MappedDoorStats>, item) => {
        const month = TimeService.toLocal(item.date).endOf('month').toMillis();
        if (!acc[month]) {
          acc[month] = {
            ...item,
            date: month
          };
        }

        acc[month].totalDuration += item.totalDuration;
        acc[month].totalActivity += item.totalActivity;

        return acc;
      }, {});

      return mapResultToAverageMovementReport(Object.values(group));
    }

    return mapResultToAverageMovementReport(res);
  }, [data, calculatedRange]);
}
