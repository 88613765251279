import { SpDivider } from '@atoms/SpDivider';
import { SpView } from '@atoms/SpView';
import colors from '@styles/colors';
import { testProperties } from '@utils/testProperties';
import React, { useCallback, useMemo } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import LoadingSpinner from '@atoms/Loader';
import { EditMenuItem } from '@models/Menu';
import { SpMenuIcon } from '../atoms/RoundIcons/SpMenuIcon';
import { SpSelectArrow } from '../atoms/SpSelectArrow';
import { SpEditMenuItemValue } from './SpEditMenuItemValue';
import useBoundStore from 'src/store/store';
import { openModal } from 'src/services/ModalBox';

export const SpEditMenuItem = ({
  label,
  value,
  actionIcon,
  icon,
  iconSVG,
  action,
  disabled,
  viewOnly = false,
  stackType = '',
  isLoading,
}: EditMenuItem) => {
  const loadingControl = useBoundStore(
    state => state.deviceStore.loadingControl,
  );

  const checkIsLoading = () => {
    if (loadingControl.tag_profiles?.length || isLoading) {
      return true;
    }
    return false;
  };

  const onAction = () => {
    if (checkIsLoading()) {
      return openModal('PetsPreview');
    } else if (viewOnly) {
      return;
    } else action();
  };
  const valueRenderItem = useMemo(() => {
    if (value) {
      return typeof value === 'string' ? (
        <SpView style={styles.value}>
          <SpEditMenuItemValue
            ellipsizeMode={
              stackType === 'createPetDetails' ? 'tail' : undefined
            }
            numberOfLines={stackType === 'createPetDetails' ? 1 : undefined}
            value={value}
          />
        </SpView>
      ) : (
        <SpView>{value}</SpView>
      );
    }

    return null;
  }, [value]);

  const actionIconRender = useMemo(() => {
    if (viewOnly) {
      return null;
    }

    return checkIsLoading() && typeof value !== 'string' ? (
      <LoadingSpinner size="large" />
    ) : (
      <SpSelectArrow componentName={label} color={colors.greyText.color} />
    );
  }, [actionIcon, viewOnly, loadingControl]);

  return (
    <Pressable
      {...testProperties(label, 'setting button')}
      accessible={false}
      onPress={onAction}
    >
      <View style={[styles.inner, disabled && { opacity: 0.5 }]}>
        {icon ? (
          <SpView marginRight={10}>
            <SpMenuIcon icon={icon} action={action} />
          </SpView>
        ) : null}
        {iconSVG ?? <SpView>{iconSVG}</SpView>}
        <SpView style={styles.contentWrap}>
          <>
            <SpView style={styles.content}>
              <Text
                {...testProperties(label, 'setting button text')}
                style={[
                  styles.label,
                  iconSVG ? styles.svgMargin : null,
                  {
                    opacity:
                      checkIsLoading() && typeof value !== 'string' ? 0.3 : 1,
                  },
                ]}
              >
                {label}
              </Text>
              {valueRenderItem}
            </SpView>
            {<Pressable onPress={onAction}>{actionIconRender}</Pressable>}
          </>
        </SpView>
      </View>
      <SpDivider />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  inner: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 17,
    paddingLeft: 8,
  },
  content: {
    flexShrink: 1,
  },
  contentWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  label: {
    color: colors.greyText.color,
    fontSize: 14,
    opacity: 0.6,
    lineHeight: 20,
    fontFamily: 'Rubik',
  },
  svgMargin: {
    marginLeft: 16,
  },
  value: {
    // marginTop: 6,
  },
});
